import React from 'react';
import { Link } from 'react-router-dom';
import icon from 'asset/kaleida_icon.png';

const styles = {
    jobCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '12px',
        overflow: 'hidden',
        backgroundColor: '#1a1a1a',
        width: '100%',
        maxWidth: '400px',
        boxSizing: 'border-box',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3)', // Fancy shadow effect
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    },
    jobCardHover: {
        transform: 'scale(1.05)',
        boxShadow: '0 15px 40px rgba(0, 0, 0, 0.4)', // Enhanced shadow on hover
    },
    jobImage: {
        width: '100%',
        height: 'auto',
        maxHeight: '300px',
        objectFit: 'cover',
        cursor: 'pointer',
        transition: 'transform 0.3s ease-in-out',
    },
    jobTitle: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#fff',
        margin: '15px 0',
        textAlign: 'center',
        fontFamily: '"Poppins", sans-serif',
    },
    placeholder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '200px',
        backgroundColor: '#333',
    },
    placeholderImage: {
        width: '40%',
        height: 'auto',
    },
};

const Card = ({ job }) => {
    return (
        <div 
            key={job._id} 
            style={styles.jobCard} 
            onMouseEnter={(e) => {
                e.currentTarget.style.transform = styles.jobCardHover.transform;
                e.currentTarget.style.boxShadow = styles.jobCardHover.boxShadow;
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'none';
                e.currentTarget.style.boxShadow = styles.jobCard.boxShadow;
            }}
        >
            <Link to={`/models/${job.model.id}`}>
                {job.model.cameraList && job.model.cameraList.length !== 0 && job.model.cameraList[0].thumbnail ? (
                    <img 
                        src={job.model.cameraList[0].thumbnail} 
                        alt="Model Thumbnail" 
                        style={styles.jobImage} 
                    />
                ) : (
                    <div style={styles.placeholder}>
                        <img src={icon} alt="Placeholder" style={styles.placeholderImage} />
                    </div>
                )}
            </Link>
            <p style={styles.jobTitle}>{job.modelName || ''}</p>
        </div>
    );
};


export default Card;