import React, { useState, useEffect, useRef } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { Splat } from 'SplatShader/Splat';
import * as THREE from 'three';
import { captureScreenshot } from 'Utils/Screenshot';

import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const CameraController = ({ previewCamera, cameraHeight }) => {
    const { camera } = useThree();

    useEffect(() => {
        if (previewCamera) {
            if (previewCamera.position.y === 0) previewCamera.position.y = 1.5;
            if (previewCamera.lookAt.y === 0) previewCamera.lookAt.y = previewCamera.position.y;

            camera.position.set(previewCamera.position.x, cameraHeight, previewCamera.position.z);
            const lookAtHeightAdjustment = previewCamera.lookAt.y + (cameraHeight - previewCamera.position.y);
            camera.lookAt(previewCamera.lookAt.x, lookAtHeightAdjustment, previewCamera.lookAt.z);
        }
    }, [previewCamera, cameraHeight, camera]);

    return null;
};

const ViewPoint = ({
    handleSetAxis,
    cameras, setCameras, addCamera, toggleAddCamera,
    splatUrl, calibration,
}) => {
    const rate = 0.01;
    const [previewCamera, setPreviewCamera] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newViewPointName, setNewViewPointName] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);

    const rendererRef = useRef(null);
    const sceneRef = useRef(null);
    const cameraRef = useRef(null);

    const [cameraHeight, setCameraHeight] = useState(1.5);

    const [showViewPointDescription, setShowViewPointDescription] = useState(true);
    const handleShowViewPointDescription = () => {
        setShowViewPointDescription(!showViewPointDescription);
    };

    const [showCameraHeightDescription, setShowCameraHeightDescription] = useState(false);
    const handleShowCameraHeightDescription = () => {
        setShowCameraHeightDescription(!showCameraHeightDescription);
    };

    const handleSetIsModalOpen = (value) => {
        setIsModalOpen(value);
    };

    // console.log(cameras);

    // useEffect(() => {
    //     return () => {
            
    //     };
    // }, []);

    useEffect(() => {
        if (addCamera) {
            setPreviewCamera(cameras[cameras.length - 1]);
            toggleAddCamera();
            handleSetIsModalOpen(true);
        } else {
            handleSetAxis('N');
            handleSetIsModalOpen(false);
        }
    }, [cameras]);

    const handleFinishViewPoints = async () => {
        if (!previewCamera) return;

        const thumbnail = await captureScreenshot(rendererRef.current, sceneRef.current, cameraRef.current);

        let updatedCameras = [...cameras];

        if (editingIndex !== null) {
            updatedCameras[editingIndex] = {
                ...updatedCameras[editingIndex],
                name: newViewPointName || `View Point ${editingIndex + 1}`,
                position: { ...previewCamera.position, y: cameraHeight },
                lookAt: previewCamera.lookAt,
                thumbnail,
            };
        } else {
            if (cameras.length > 0) {
                updatedCameras.pop();
            }
            updatedCameras.push({
                name: newViewPointName || `View Point ${updatedCameras.length + 1}`,
                position: { ...previewCamera.position, y: cameraHeight },
                lookAt: previewCamera.lookAt,
                thumbnail,
            });
        }

        setCameras(updatedCameras);
        setNewViewPointName('');
        handleSetIsModalOpen(false);
        handleSetAxis('N');
        setPreviewCamera(null);
        setEditingIndex(null);
    };

    const deleteCamera = (index = null) => {
        let updatedCameras = [...cameras];

        if (editingIndex === null && index === null) {
            // If there's no editing index and no specific index is passed, delete the last camera
            updatedCameras.pop();
        } else if (editingIndex !== null && index !== null) {
            // If editing, delete the camera at the specific index
            updatedCameras = updatedCameras.filter((_, i) => i !== index);
        } else if (editingIndex === null && index !== null) {
            // If not editing, delete the camera at the specific index
            updatedCameras = updatedCameras.filter((_, i) => i !== index);
        }

        setCameras(updatedCameras);

        if (editingIndex !== null && editingIndex === index) {
            // Reset states if the camera being deleted is the one currently being edited
            setNewViewPointName('');
            handleSetIsModalOpen(false);
            handleSetAxis('N');
            setPreviewCamera(null);
            setEditingIndex(null);
        } else if (editingIndex !== null && editingIndex > index) {
            // Adjust the editing index if a camera before the edited one is deleted
            setEditingIndex(editingIndex - 1);
        }

        if (updatedCameras.length === 0) {
            // Reset all states if there are no cameras left
            setNewViewPointName('');
            handleSetIsModalOpen(false);
            handleSetAxis('N');
            setPreviewCamera(null);
            setEditingIndex(null);
        }
    };


    const rotateCameraLookAt = (deltaTheta, deltaPhi) => {
        if (cameraRef.current && previewCamera) {
            const camera = cameraRef.current;
            const lookAt = new THREE.Vector3(previewCamera.lookAt.x, previewCamera.lookAt.y, previewCamera.lookAt.z);
            const position = new THREE.Vector3(previewCamera.position.x, previewCamera.position.y, previewCamera.position.z);
            const direction = new THREE.Vector3().subVectors(lookAt, position).normalize();
            const spherical = new THREE.Spherical();
            spherical.setFromVector3(direction);

            spherical.theta += THREE.MathUtils.degToRad(deltaTheta);
            spherical.phi += THREE.MathUtils.degToRad(deltaPhi);
            spherical.phi = THREE.MathUtils.clamp(spherical.phi, 0.01, Math.PI - 0.01);
            direction.setFromSpherical(spherical);
            const newLookAt = new THREE.Vector3().addVectors(position, direction);

            setPreviewCamera({
                ...previewCamera,
                lookAt: { x: newLookAt.x, y: newLookAt.y, z: newLookAt.z },
            });
            camera.lookAt(newLookAt);
        }
    };

    const handleHeightChange = (e) => {
        const newHeight = parseFloat(e.target.value);
        setCameraHeight(newHeight);
        setPreviewCamera((prev) => ({
            ...prev,
            position: { ...prev.position, y: newHeight },
            lookAt: { ...prev.lookAt, y: prev.lookAt.y + (newHeight - prev.position.y) },
        }));
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedCameras = Array.from(cameras);
        const [removed] = reorderedCameras.splice(result.source.index, 1);
        reorderedCameras.splice(result.destination.index, 0, removed);
        setCameras(reorderedCameras);
    };

    const handleAddCamera = () => {
        if (addCamera) {
            handleSetAxis('N');
            handleSetIsModalOpen(false);
            toggleAddCamera();
        } else {
            handleSetAxis('Y');
            toggleAddCamera();
        }
    };

    const handleClose = () => {
        handleSetIsModalOpen(false);
        setPreviewCamera(null);

        if (editingIndex === null) {
            deleteCamera(null);
        } else {
            setEditingIndex(null);
        }
    };

    const styles = {
        description: {
            fontFamily: '"Poppins", sans-serif',
            fontSize: '16px',
            color: '#FFFFFFB2',
        },
        addViewPointButton: {
            width: '100%',
            background: '#3366FF',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            padding: '10px 0',
            fontSize: '14px',
            cursor: 'pointer',
            fontFamily: '"Poppins", sans-serif'
        },
        viewPointList: {
            listStyleType: 'none',
            flexGrow: 1,
            padding: 0,
            margin: '20px 0',
        },
        hr: {
            width: '100%',
            border: '0',
            borderTop: '1px solid #444',
            margin: '10px 0',
        },
        itemRow: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '10px',
            padding: '10px',
            backgroundColor: '#333',
            borderRadius: '5px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.3s ease',
        },
        itemText: {
            flex: 1,
            marginRight: '10px',
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            padding: '10px',
            color: 'white',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
            fontFamily: '"Poppins", sans-serif'
        },
        modal: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.8)',
            padding: '20px',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            zIndex: 1000,
            width: '80%',
            maxWidth: '400px',
        },
        modalContent: {
            position: 'relative',
            width: '100%',
        },
        canvasWrapper: {
            position: 'relative',
            width: '100%',
            paddingBottom: '62.5%',
            height: 0,
        },
        canvas: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'white'
        },
        modalBody: {
            width: '85%',
        },
        sliderContainer: {
            position: 'relative',
            background: 'rgba(255, 255, 255, 0.2)',
            padding: '5px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        slider: {
            width: '100%',
            accentColor: '#3366FF',
        },
        modalFooter: {
            marginTop: '20px',
            width: '85%',
        },
        inputContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            marginBottom: '20px',
        },
        label: {
            color: 'white',
            fontSize: '16px',
            marginBottom: '10px',
        },
        inputField: {
            alignSelf: 'stretch',
            flex: 1,
            borderRadius: '6px',
            background: 'white',
            border: '1px solid rgba(33, 37, 41, 0.20)',
            padding: '12px',
            color: '#212529',
            fontSize: '16px',
            fontFamily: '"Poppins", sans-serif',
            fontWeight: '400',
        },
        saveDeleteButtonContainer: {
            alignSelf: 'stretch',
            height: '54px',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            display: 'flex',
        },
        saveDeleteButton: {
            width: '100px',
            padding: '10px 20px',
            borderRadius: '5px',
            background: '#3366FF',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            fontFamily: '"Poppins", sans-serif'
        },
        overlayControls: {
            position: 'absolute',
            zIndex: 1100,
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
        },
        topControl: {
            top: '10px',
            left: '50%',
            transform: 'translateX(-50%)',
        },
        bottomControl: {
            bottom: '10px',
            left: '50%',
            transform: 'translateX(-50%)',
        },
        leftControl: {
            left: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
        },
        rightControl: {
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
        },
        helpIcon: {
            fontSize: '20px',
            cursor: 'pointer',
        },
    };

    return (
        <div>
            <label
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: '#fff',
                    margin: '15px 0',
                }}
            >
                <h2
                    style={{
                        fontFamily: '"Poppins", sans-serif',
                        userSelect: 'none',
                        transition: 'color 0.3s',
                        margin: 0,
                    }}
                >
                    Set View Point
                </h2>
                <HelpOutlineIcon
                    style={styles.helpIcon}
                    onClick={handleShowViewPointDescription}
                />
            </label>

            {showViewPointDescription && <p
                style={{
                    ...styles.description,
                    margin: '10px 0',
                }}
            >
                This step allows you to choose various starting positions for your users. The first view point will be set as the cover of the model. <br /><br />
            </p>}

            {(cameras.length < 10) &&
                <button
                    style={styles.addViewPointButton}
                    onClick={() => {
                        handleAddCamera();
                    }}
                    disabled={isModalOpen}
                >
                    {addCamera ? 'Stop' : 'Add View Point'}
                </button>
            }

            {cameras.length > 0 && (
                <>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="viewpoints">
                            {(provided) => (
                                <ul ref={provided.innerRef} {...provided.droppableProps} style={styles.viewPointList}>
                                    {cameras.map((camera, index) => (
                                        <Draggable key={index} draggableId={index.toString()} index={index}>
                                            {(provided) => (
                                                <li
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...styles.itemRow,
                                                        ...provided.draggableProps.style,
                                                    }}
                                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#444'}
                                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#333'}
                                                >
                                                    <div
                                                        style={styles.itemText}
                                                        onClick={() => {
                                                            setPreviewCamera(camera);
                                                            setCameraHeight(camera.position.y);
                                                            setNewViewPointName(camera.name);
                                                            setEditingIndex(index);
                                                            handleSetIsModalOpen(true);
                                                        }}
                                                    >
                                                        {camera.name ? camera.name : `Default View Point ${index + 1}`}
                                                    </div>
                                                    <DeleteOutlineRoundedIcon
                                                        style={{
                                                            fontSize: '24px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => deleteCamera(index)}
                                                    />
                                                </li>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                </>
            )}

            {isModalOpen && (
                <div style={styles.modal}>
                    <IconButton
                        color="primary"
                        style={{
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            width: '48px',
                            height: '48px',
                            color: 'white'
                        }}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <br /><br />
                    <div style={styles.modalContent}>
                        <div style={styles.canvasWrapper}>
                            <Canvas
                                style={styles.canvas}
                                camera={{
                                    fov: 75,
                                    position: previewCamera ? [previewCamera.position.x, previewCamera.position.y, previewCamera.position.z] : [0, 1.5, 5],
                                }}
                                onCreated={({ gl, scene, camera }) => {
                                    rendererRef.current = gl;
                                    sceneRef.current = scene;
                                    cameraRef.current = camera;
                                }}
                            >
                                <CameraController previewCamera={previewCamera} cameraHeight={cameraHeight} />
                                <Splat
                                    src={splatUrl}
                                    scale={calibration.scale}
                                    rotation={calibration.rotation}
                                    position={calibration.translation}
                                />
                            </Canvas>
                        </div>
                        {/* Overlay Controls */}
                        <div style={{ ...styles.overlayControls, ...styles.topControl }}>
                            <IconButton
                                color="primary"
                                style={{ color: 'black', width: '48px', height: '48px' }}
                                onClick={() => rotateCameraLookAt(0, -10)} // Rotate vertically (up)
                            >
                                <ArrowUpwardIcon />
                            </IconButton>
                        </div>
                        <div style={{ ...styles.overlayControls, ...styles.bottomControl }}>
                            <IconButton
                                color="primary"
                                style={{ color: 'black', width: '48px', height: '48px' }}
                                onClick={() => rotateCameraLookAt(0, 10)} // Rotate vertically (down)
                            >
                                <ArrowDownwardIcon />
                            </IconButton>
                        </div>
                        <div style={{ ...styles.overlayControls, ...styles.leftControl }}>
                            <IconButton
                                color="primary"
                                style={{ color: 'black', width: '48px', height: '48px' }}
                                onClick={() => rotateCameraLookAt(10, 0)} // Rotate horizontally (left)
                            >
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div style={{ ...styles.overlayControls, ...styles.rightControl }}>
                            <IconButton
                                color="primary"
                                style={{ color: 'black', width: '48px', height: '48px' }}
                                onClick={() => rotateCameraLookAt(-10, 0)} // Rotate horizontally (right)
                            >
                                <ArrowForwardIcon />
                            </IconButton>
                        </div>
                    </div>
                    <br />
                    <div style={styles.modalBody}>
                        <label
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                color: '#fff',
                                margin: '10px 0',
                            }}
                        >
                            <p
                                style={{
                                    fontFamily: '"Poppins", sans-serif',
                                    userSelect: 'none',
                                    transition: 'color 0.3s',
                                    margin: 0,
                                }}
                            >
                                Camera Height: {cameraHeight.toFixed(2)}
                            </p>
                            <HelpOutlineIcon
                                style={styles.helpIcon}
                                onClick={handleShowCameraHeightDescription}
                            />
                        </label>
                        {showCameraHeightDescription && <p
                            style={{
                                ...styles.description,
                                margin: '0',
                            }}
                        >
                            Drag the slide bar to adjust camera height. This determines the height from which you will view the model in first-person perspective. <br /><br />
                        </p>}
                        <input
                            type="range"
                            min="0.1"
                            max="10"
                            step={rate}
                            value={cameraHeight}
                            onChange={handleHeightChange}
                            style={styles.slider}
                        />
                    </div>
                    <div style={styles.modalFooter}>
                        <div style={styles.inputContainer}>
                            <label style={styles.label}>View Point Name</label>
                            <input
                                type="text"
                                value={newViewPointName}
                                onChange={(e) => setNewViewPointName(e.target.value)}
                                style={styles.inputField}
                                placeholder={previewCamera ? previewCamera.name : 'Enter view point name'}
                                maxLength={40}
                            />
                        </div>
                        <div style={styles.saveDeleteButtonContainer}>
                            <button style={styles.saveDeleteButton} onClick={() => { deleteCamera(editingIndex); }}>Delete</button>
                            <button style={styles.saveDeleteButton} onClick={handleFinishViewPoints}>Save</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ViewPoint;
