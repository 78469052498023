import { inputAdornmentClasses } from '@mui/material';
import ModelModel from '../models/ModelModel';

class ModelController {
    constructor(modelModel) {
        this.modelModel = modelModel;
    }

    async getModelById(_id) {
        try {
            const model = await this.modelModel.getModelById(_id);
            if (model.parameters.cameraList.length === 0) {
                model.parameters.cameraList.push({
                    name: 'Default',
                    position: {x:0, y:1.5, z:0},
                    lookAt: {x:0, y:1.5, z:0},
                    thumbnail: '',
                });
            }
            const response = {
                id: _id,
                name: model.name,
                description: model.description,
                ownerId: model.userId,
                jobId: model.jobId,
                url: model.URL,
                center: model.parameters.center,
                scale: model.parameters.scale,
                rotation: [
                    model.parameters.rotation.x * (Math.PI / 180),
                    model.parameters.rotation.y * (Math.PI / 180),
                    model.parameters.rotation.z * (Math.PI / 180)
                ],
                cameraList: model.parameters.cameraList,
                heightRange: model.parameters.heightRange,
                wall: model.parameters.wall,
                moveSpeed: model.parameters.speed.moveSpeed,
                cameraRotateSpeed: model.parameters.speed.rotateSpeed,
                jumpSpeed: 0.7,
                gravity: -0.08,
                maxRadius: 10,
                fov: 60,
            };
            return response;
        } catch (error) {
            console.error('Failed to get model by id:', error);
            throw error;
        }
    }

    async createModel(data) {
        try {
            const newModel = await this.modelModel.createModel(data);
            return newModel;
        } catch (error) {
            console.error('Failed to create model:', error);
            throw error;
        }
    }

    async updateModel(_id, data) {
        try {
            const updatedModel = await this.modelModel.updateModel(_id, data);
            return updatedModel;
        } catch (error) {
            console.error('Failed to update model:', error);
            throw error;
        }
    }

    async calibrateModel(_id, data) {
        try {
            const calibratedModel = await this.modelModel.calibrateModel(data);
            return calibratedModel;
        } catch (error) {
            console.error('Failed to calibrate model:', error);
            throw error;
        }
    }

    async generateModel(userId, jobId, modelName, inputType, config) {
        try {
            const data = {
                userId: userId,
                jobId: jobId,
                modelName: modelName,
                inputType: inputType,
                config: config,
            };
            const response = await this.modelModel.generateModel(data);
            return response;
        } catch (error) {
            console.error('Failed to generate model:', error);
            throw error;
        }
        
    }
}

const ModelControllerInstance = new ModelController(ModelModel);
export default ModelControllerInstance;
