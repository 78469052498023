import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import UserController from '../controllers/UserController';
import icon from 'asset/kaleida_icon.png';
import JobController from 'controllers/JobController';
import UploadModal from '../components/dashboard/UploadModal';
import ShareIcon from '@mui/icons-material/Share';
import ShareModal from '../components/dashboard/ShareModal';
import DeleteConfirmationModal from '../components/dashboard/DeleteConfirmationModal';
import RefreshIcon from '@mui/icons-material/Refresh';
import HelperModal from '../components/dashboard/HelperModal';

import Card from '../components/dashboard/Card';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
    },
    sectionTitleContainer: {
        width: '90%',
        padding: '0 20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // backgroundColor: 'gray',
    },
    sectionTitle: {
        fontFamily: '"Poppins", sans-serif',
        fontSize: '24px',
        fontWeight: '600',
        color: '#fff',
        margin: '20px 0',
        paddingRight: '10px',
        textAlign: 'left',
    },
    cardContainer: {
        display: 'grid',
        boxSizing: 'border-box',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '30px',
        padding: '30px 5%',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        bottom: 0,
        height: 'calc(100vh - 90px)', // Adjust height for header height
        width: '100%',
        justifyItems: 'start',
        alignContent: 'flex-start',
    },
    cardContainerMobile: {
        display: 'grid',
        boxSizing: 'border-box',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '20px',
        padding: '20px 0',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        bottom: 0,
        height: 'calc(100vh - 74px)', // Adjust height for header height
        width: '100%',
        justifyItems: 'center',
        alignContent: 'flex-start',
    },
    buttonPrimary: {
        background: '#3366FF',
        color: '#fff',
        border: 'none',
        marginRight: '10px',
        padding: '8px 20px',
        borderRadius: '10px',
        cursor: 'pointer',
        fontFamily: '"Poppins", sans-serif',
        fontSize: '14px',
    },
    refreshIcon: {
        cursor: 'pointer',
        color: '#fff',
        fontSize: '30px',
    },
};

const UserDashboard = ({ user }) => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [jobToDelete, setJobToDelete] = useState(null);
    const [shareJob, setShareJob] = useState(null);
    const [shareUrl, setShareUrl] = useState('');
    const [showHelpModal, setShowHelpModal] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state && location.state.showModal) {
            setShowModal(true);
    
            // Remove showModal from location.state
            navigate(location.pathname, {
                replace: true,
                state: {
                    ...location.state,
                    showModal: false,
                },
            });
        }
    }, [location.state, navigate]);

    const cardContainerRef = useRef(null);

    useEffect(() => {
        const scrollPosition = sessionStorage.getItem('cardContainerScroll') || 0;
        if (cardContainerRef.current) {
            cardContainerRef.current.scrollTop = scrollPosition;
        }

        const handleScroll = () => {
            if (cardContainerRef.current) {
                sessionStorage.setItem(
                    'cardContainerScroll',
                    cardContainerRef.current.scrollTop
                );
            }
        };

        const cardContainerNode = cardContainerRef.current;
        if (cardContainerNode) {
            cardContainerNode.addEventListener('scroll', handleScroll);
        }

        // Cleanup on unmount
        return () => {
            if (cardContainerNode) {
                cardContainerNode.removeEventListener('scroll', handleScroll);
            }
        };
    }, [loading]);

    const fetchJobs = async (user) => {
        if (!user) return;
        setLoading(true);
        try {
            const userData = await UserController.getUserByEmail(user.email);
            setJobs(userData.jobs);
        } catch (error) {
            console.error('Error fetching jobs:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchJobs(user);
    }, [user]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDelete = (jobId) => {
        setDeleteModalOpen(true);
        setJobToDelete(jobId);
    };

    const confirmDelete = async () => {
        try {
            await JobController.deleteJob(jobToDelete);
            JobController.deleteS3Folder(`jobs/${user.uid}/${jobToDelete}`);
            setJobs(jobs.filter((job) => job._id !== jobToDelete));
            setDeleteModalOpen(false);
            setJobToDelete(null);
        } catch (error) {
            console.error('Error deleting job:', error);
            alert('Failed to delete job.');
        }
    };

    const handleContinue = (job) => {
        setSelectedJob(job);
        setShowModal(true);
    };

    const handleRefresh = async () => {
        setShowModal(false);
        fetchJobs(user);
    };

    const handleShare = (job, modelId) => {
        const url = `https://kaleida.space/models/${modelId}`;
        setShareUrl(url);
        setShareJob(job);
        setShareModalOpen(true);
    };

    const handleHelp = () => {
        setShowHelpModal(true);
    };

    return (
        <div style={styles.container}>
            <Header user={user} />
            <div
                style={isMobile ? styles.cardContainerMobile : styles.cardContainer }
                ref={cardContainerRef}
            >
                {loading ? (
                    <p style={{ fontFamily: '"Poppins", sans-serif', }}>Loading...</p>
                ) : jobs.length > 0 ? (
                    jobs.map((job) => (
                        <Card
                            key={job._id}
                            job={job}
                            isMobile={isMobile}
                            handleDelete={handleDelete}
                            handleContinue={handleContinue}
                            handleShare={handleShare}
                        />
                    ))
                ) : (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '20px',
                            position: 'absolute',
                            top: '40%',
                        }}>
                            <button
                                style={styles.buttonPrimary}
                                onClick={() => setShowModal(true)}
                            >
                                Create your first virtual tour.
                            </button>
                            <button
                                style={{
                                    background: 'none',
                                    color: '#fff',
                                    border: 'none',
                                    cursor: 'pointer',
                                    fontFamily: '"Poppins", sans-serif',
                                    fontSize: '18px',
                                    textDecoration: 'underline',
                                    marginBottom: '10px',
                                    padding: '0',
                                }}
                                onClick={handleHelp}
                            >
                                Not sure where to begin?
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {showModal && (
                <UploadModal
                    user={user}
                    onClose={() => {
                        setShowModal(false);
                        handleRefresh();
                    }}
                    job={selectedJob}
                />
            )}
            <ShareModal
                open={shareModalOpen}
                onClose={() => {
                    setShareModalOpen(false);
                    handleRefresh();
                }}
                job={shareJob}
                url={shareUrl}
            ></ShareModal>
            <DeleteConfirmationModal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onConfirm={confirmDelete}
            />
            {showHelpModal && <HelperModal onClose={() => setShowHelpModal(false)} />}
        </div>
    );
};

export default UserDashboard;