// src/utils/SplatMath.js

const reducePoints = (points, percentage) => {

    // console.log("Reduce Points");

    percentage = percentage / 100;
    const totalPointsNumber = points.position.length / 3;
    const targetPointsNumber = Math.round(totalPointsNumber * percentage);

    // Generate an array of indices with the same length as the total number of points
    const indices = Array.from({ length: totalPointsNumber }, (_, i) => i);

    // Shuffle the indices array
    for (let i = indices.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [indices[i], indices[j]] = [indices[j], indices[i]];
    }

    // Select the first `targetPointCount` indices
    const selectedIndices = indices.slice(0, targetPointsNumber);

    // Create new arrays for the selected points
    const position = new Float32Array(targetPointsNumber * 3);
    const scale = new Float32Array(targetPointsNumber * 3);
    const color = new Float32Array(targetPointsNumber * 4);
    const rotation = new Float32Array(targetPointsNumber * 4);

    // Copy the selected points to the new arrays
    for (let i = 0; i < targetPointsNumber; i++) {
        const index = selectedIndices[i];

        position[i * 3 + 0] = points.position[index * 3 + 0];
        position[i * 3 + 1] = points.position[index * 3 + 1];
        position[i * 3 + 2] = points.position[index * 3 + 2];

        scale[i * 3 + 0] = points.scale[index * 3 + 0];
        scale[i * 3 + 1] = points.scale[index * 3 + 1];
        scale[i * 3 + 2] = points.scale[index * 3 + 2];

        color[i * 4 + 0] = points.color[index * 4 + 0];
        color[i * 4 + 1] = points.color[index * 4 + 1];
        color[i * 4 + 2] = points.color[index * 4 + 2];
        color[i * 4 + 3] = points.color[index * 4 + 3];

        rotation[i * 4 + 0] = points.rotation[index * 4 + 0];
        rotation[i * 4 + 1] = points.rotation[index * 4 + 1];
        rotation[i * 4 + 2] = points.rotation[index * 4 + 2];
        rotation[i * 4 + 3] = points.rotation[index * 4 + 3];
    }

    for (let i = 0; i < position.length; i++) {
        if (isNaN(position[i]) || isNaN(scale[i]) || isNaN(color[i]) || isNaN(rotation[i])) {
            console.error('Invalid point data');
            return null;
        }
    }

    // Return the new points
    return { position, scale, color, rotation };
};

export {
    reducePoints,
};