import React, { useState } from 'react';
import { googleProvider, appleProvider } from './firebase';
import { signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, fetchSignInMethodsForEmail, sendEmailVerification} from 'firebase/auth';
import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import UserController from '../../controllers/UserController';
import GoogleLoginSVG from 'asset/google.svg';
import AppleLoginSVG from 'asset/apple.svg';

const auth = getAuth();

const providers = {
  google: {
    provider: googleProvider,
    img: GoogleLoginSVG,
    alt: 'Google login'
  },
  apple: {
    provider: appleProvider,
    img: AppleLoginSVG,
    alt: 'Apple login'
  }
};

const Auth = ({ provider, closeModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignup, setIsSignup] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'error' or 'success'
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const navigate = useNavigate();

  const handleAuth = () => {
    const providerData = providers[provider];
    if (!providerData) {
      console.error('Unsupported provider');
      return;
    }

    signInWithPopup(auth, providerData.provider)
      .then(result => {
        UserController.handleAuthentication(result, provider, navigate, closeModal);
      })
      .catch(error => {
        console.error('Error during authentication:', error.message);
      });
  };

  const handleEmailPasswordAuth = (e) => {
    e.preventDefault();
    setEmailError(false);
    setPasswordError(false);
    setMessage('');
  
    const authMethod = isSignup ? createUserWithEmailAndPassword : signInWithEmailAndPassword;
  
    authMethod(auth, email, password)
      .then(result => {
        const user = result.user;
  
        if (isSignup) {
          // Send email verification
          sendEmailVerification(user)
            .then(() => {
              setMessageType('success');
              setMessage('Please check your email for verification.');
              setIsSignup(false); // Switch to login after signup
              auth.signOut(); // Sign the user out until they verify their email
            })
            .catch(error => {
              setMessageType('error');
              setMessage(`Failed to send verification email: ${error.message}`);
            });
        } else {
          // Check if the email is verified before logging in
          if (!user.emailVerified) {
            auth.signOut(); // Sign out the user until they verify the email
            setMessageType('error');
            setMessage('Please verify your email before logging in.');
          } else {
            // If email is verified, proceed with the normal authentication flow
            UserController.handleAuthentication(result, 'email', navigate, closeModal);
          }
        }
      })
      .catch(error => {
        if (error.code === 'auth/email-already-in-use' && isSignup) {
          // Switch to login mode if the email is already in use
          setIsSignup(false);
          setMessageType('error');
          setMessage('Email is already in use. Please log in.');
        } else if (error.code === 'auth/wrong-password' && !isSignup) {
          // Highlight the email and password fields in red and display an error message
          setEmailError(true);
          setPasswordError(true);
          setMessageType('error');
          setMessage('Invalid email or password. Please try again.');
        } else if (error.code === 'auth/too-many-requests' && !isSignup) {
          setMessageType('error');
          setMessage('Too many failed login attempts. Please try again later.');
        } else if (error.code === 'auth/user-not-found' && !isSignup) {
          setEmailError(true);
          setMessageType('error');
          setMessage('No account found with this email address.');
        } else if (error.code === 'auth/weak-password' && isSignup) {
          setPasswordError(true);
          setMessageType('error');
          setMessage('Password should be at least 6 characters.');
        }
        else {
          setMessageType('error');
          setMessage(`Error: ${error.message}`);
        }
      });
  };

  const toggleSignup = () => {
    setIsSignup(prev => !prev);
    setMessage(''); // Clear any existing messages when toggling between login and signup
  };

  const handlePasswordReset = () => {
    if (!email) {
      setMessageType('error');
      setMessage('Please enter your email address to reset your password.');
      setEmailError(true);
      return;
    }

    fetchSignInMethodsForEmail(auth, email)
      .then(signInMethods => {
        if (signInMethods.length === 0) {
          setMessageType('error');
          setMessage('No account found with this email address.');
          setEmailError(true);
        } else {
          // Account exists, proceed with sending the password reset email
          sendPasswordResetEmail(auth, email)
            .then(() => {
              setMessageType('success');
              setMessage('Password reset email sent. Please check your inbox.');
            })
            .catch(error => {
              setMessageType('error');
              setMessage('Error sending password reset email. Please try again.');
            });
        }
      })
      .catch(error => {
        setMessageType('error');
        setMessage(`Error: ${error.message}`);
      });
  };

  const buttonImg = providers[provider]?.img;
  const altText = providers[provider]?.alt;

  if (provider === 'email') {
    return (
      <form onSubmit={handleEmailPasswordAuth}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{
            width: '100%',
            padding: '10px',
            margin: '10px 0',
            borderRadius: '5px',
            border: `1px solid ${emailError ? 'red' : '#ccc'}`,
            backgroundColor: emailError ? '#ffe6e6' : '#fff',
          }}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{
            width: '100%',
            padding: '10px',
            margin: '10px 0',
            borderRadius: '5px',
            border: `1px solid ${passwordError ? 'red' : '#ccc'}`,
            backgroundColor: passwordError ? '#ffe6e6' : '#fff',
          }}
          required
        />
        {message && (
          <p style={{ color: messageType === 'error' ? 'red' : 'green', fontFamily: '"Poppins", sans-serif' }}>
            {message}
          </p>
        )}
        <button type="submit" style={{ width: '100%', padding: '10px', marginTop: '20px', borderRadius: '5px', backgroundColor: '#007BFF', color: '#fff', border: 'none', cursor: 'pointer' }}>
          {isSignup ? 'Sign Up' : 'Login'}
        </button>
        {!isSignup && (
          <p
            onClick={handlePasswordReset}
            style={{ marginTop: '10px', color: '#007BFF', cursor: 'pointer', textAlign: 'center', fontFamily: '"Poppins", sans-serif' }}
          >
            Forgot password?
          </p>
        )}
        <p onClick={toggleSignup} style={{ marginTop: '20px', color: '#007BFF', cursor: 'pointer', textAlign: 'center', fontFamily: '"Poppins", sans-serif' }}>
          {isSignup ? 'Already have an account? Login here.' : "Don't have an account with us? Sign up here."}
        </p>
      </form>
    );
  }

  if (!buttonImg) {
    return <p style={{ fontFamily: '"Poppins", sans-serif' }}>Please login first</p>;
  }

  return (
    <button onClick={handleAuth} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
      <img src={buttonImg} alt={altText} style={{ width: '100%', height: 'auto', paddingBottom: '16px' }} />
    </button>
  );
};

export default Auth;
