import React from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';

const styles = {
  modalStyle: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#222222',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    width: '400px',
    maxWidth: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
    fontFamily: '"Poppins", sans-serif',
  },
  overlayStyle: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
  titleStyle: {
    fontSize: '18px',
    margin: 0,
    textAlign: 'center',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center', // Center the buttons
    gap: '30px', // Add spacing between the buttons
    marginTop: '20px',
    width: '100%',
  },
  buttonConfirm: {
    background: '#3366FF',
    color: '#fff',
    border: 'none',
    padding: '8px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontFamily: '"Poppins", sans-serif',
    fontSize: '14px',
    transition: 'background-color 0.3s', 
    '&:hover': {
      backgroundColor: '#1a4dcc', 
    },
  },
  buttonCancel: {
    background: 'transparent',
    color: '#fff',
    border: '1px solid #CBD0DC',
    padding: '8px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontFamily: '"Poppins", sans-serif',
    fontSize: '14px',
    transition: 'background-color 0.3s',
    '&:hover': {
      borderColor: '#ffffff'
    },
  },
};

const DeleteConfirmationModal = ({ open, onClose, onConfirm }) => {
  return (
    <>
      {open && <div style={styles.overlayStyle}></div>}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box sx={styles.modalStyle}>
          <Typography id="delete-modal-title" variant="h6" component="h2" sx={styles.titleStyle}>
            Are you sure you want to delete this model?
          </Typography>
          <div style={styles.buttonGroup}>
            <Button sx={styles.buttonCancel} onClick={onClose}>
              Cancel
            </Button>
            <Button sx={styles.buttonConfirm} onClick={onConfirm}>
              Confirm
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteConfirmationModal;
