import React, { useState } from 'react';
import Auth from './AuthComponent';
import { useLocation, useNavigate } from 'react-router-dom';

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensure it's above everything else
  },
  modal: {
    position: 'relative',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '500px',  // Set a specific width for the modal
    minHeight: '300px',  // Set a minimum height for the modal
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#aaa',
    fontSize: '24px',
    fontFamily: '"Poppins", sans-serif',
    padding: '10px',  // Add padding to increase the clickable area
  },
  input: {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '16px',
    fontFamily: '"Poppins", sans-serif',
  },
  loginButton: {
    width: '100%',
    padding: '10px',
    marginTop: '20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007BFF',
    color: '#fff',
    fontSize: '16px',
    fontFamily: '"Poppins", sans-serif',
    cursor: 'pointer',
  },
  switchButton: {
    marginTop: '20px',
    cursor: 'pointer',
    color: '#007BFF',
    background: 'transparent',
    border: 'none',
    fontSize: '16px',
    fontFamily: '"Poppins", sans-serif',
  }
};

const LoginPage = ({ onClose }) => {
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/"; // Default to "/" if no intended page

  const handleAuthSuccess = () => {
    onClose();
    navigate(from, { replace: true });
  };

  const toggleLoginMethod = () => {
    setIsEmailLogin(prev => !prev);
  };

  return (
    <div style={styles.overlay} onClick={onClose}>
      <div style={styles.modal} onClick={e => e.stopPropagation()}>
        <button onClick={onClose} style={styles.closeButton}>×</button>
        <h1>{'          '}</h1>
        {isEmailLogin ? (
          <Auth provider="email" closeModal={handleAuthSuccess} />
        ) : (
          <>
            <Auth provider="google" closeModal={handleAuthSuccess} />
            <Auth provider="apple" closeModal={handleAuthSuccess} />
          </>
        )}
        <button style={styles.switchButton} onClick={toggleLoginMethod}>
          {isEmailLogin ? 'Use Google or Apple Account' : 'Use Email & Password'}
        </button>
      </div>
    </div>
  );
};

export default LoginPage;