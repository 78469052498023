const isPointTouchingLines = (point, lines, buffer = 0.2) => {
    const [px, py] = point;
    const bufferSquared = buffer * buffer; // Use squared distances to avoid unnecessary square root calculations

    // Function to calculate the squared distance from a point to a line segment
    function distanceSquaredToLineSegment(px, py, x1, y1, x2, y2) {
        const A = px - x1;
        const B = py - y1;
        const C = x2 - x1;
        const D = y2 - y1;

        const dot = A * C + B * D;
        const len_sq = C * C + D * D;
        let param = -1;
        if (len_sq !== 0) param = dot / len_sq;

        let xx, yy;

        if (param < 0) {
            xx = x1;
            yy = y1;
        } else if (param > 0 && param < 1) {
            xx = x1 + param * C;
            yy = y1 + param * D;
        } else {
            xx = x2;
            yy = y2;
        }

        const dx = px - xx;
        const dy = py - yy;
        return dx * dx + dy * dy;
    }

    for (let line of lines) {
        const [[x1, y1], [x2, y2]] = line;

        if (distanceSquaredToLineSegment(px, py, x1, y1, x2, y2) <= bufferSquared) {
            return line;
        }
    }

    return null;
}

const pointsEqual = (point1, point2) => {
    const areFloatsEqual = (num1, num2, epsilon = 0.000001) => {
        return Math.abs(num1 - num2) < epsilon;
    };

    return areFloatsEqual(point1[0], point2[0]) && areFloatsEqual(point1[1], point2[1]);
};

const calculateCameraPosition = (transformMatrix) => {
    // Step 1: Extract the camera position (last column)
    const cameraPosition = {
        x: transformMatrix[0][3],
        y: transformMatrix[1][3],
        z: -transformMatrix[2][3]
    };

    // Step 2: Extract the LookAt direction (third column)
    const lookAtDirection = {
        x: transformMatrix[0][2],
        y: transformMatrix[1][2],
        z: -transformMatrix[2][2]
    };

    // Step 3: Extract the Up direction (second column)
    const upDirection = {
        x: transformMatrix[0][1],
        y: transformMatrix[1][1],
        z: -transformMatrix[2][1]
    };

    // Step 4: Calculate the LookAt point by adding direction to position
    const lambda = 1.0; // distance to LookAt point
    const lookAtPoint = {
        x: cameraPosition.x + lambda * lookAtDirection.x,
        y: cameraPosition.y + lambda * lookAtDirection.y,
        z: cameraPosition.z + lambda * lookAtDirection.z
    };

    return {
        cameraPosition,
        lookAtPoint,
        upDirection
    };
};

const smoothHistogram = (bins, windowSize = 5) => {
    const smoothedBins = [];
    const halfWindow = Math.floor(windowSize / 2);

    for (let i = 0; i < bins.length; i++) {
        let sum = 0;
        let count = 0;
        for (let j = -halfWindow; j <= halfWindow; j++) {
            const idx = i + j;
            if (idx >= 0 && idx < bins.length) {
                sum += bins[idx];
                count++;
            }
        }
        smoothedBins[i] = sum / count;
    }

    return smoothedBins;
};

const findFirstSignificantPeak = (bins, minProminence = 100) => {
    let localMax = 0;
    let peakBinIndex = null;

    for (let i = 1; i < bins.length - 1; i++) {
        const current = bins[i];
        const prev = bins[i - 1];
        const next = bins[i + 1];

        // Check if the current bin is a local maximum
        if (current > prev && current >= next) {
            let leftMin = Infinity;
            for (let j = Math.max(0, i - 50); j < i; j++) {
                if (bins[j] < leftMin) leftMin = bins[j];
            }

            let rightMin = Infinity;
            for (let j = i + 1; j <= Math.min(bins.length - 1, i + 50); j++) {
                if (bins[j] < rightMin) rightMin = bins[j];
            }

            const prominence = current - Math.max(leftMin, rightMin);

            // Adapt prominence threshold based on the distribution of bin values
            const dynamicThreshold = Math.max(minProminence, (current * 0.1));

            // Only consider this peak if it meets the adaptive prominence threshold
            if (prominence >= dynamicThreshold && current > localMax) {
                localMax = current;
                peakBinIndex = i;
            }
        }
    }

    return peakBinIndex;
};

const findGroundLevel = (points) => {
    const positions = points.position;
    const numPoints = positions.length / 3;

    // Collect all Z values where Z <= 0
    const zValues = [];
    for (let i = 0; i < numPoints; i++) {
        const z = positions[i * 3 + 2];
        if (z <= 0) {
            zValues.push(z);
        }
    }

    if (zValues.length === 0) {
        throw new Error('No points found with Z <= 0');
    }

    // Compute minZ and maxZ
    let minZ = Infinity;
    for (let i = 0; i < zValues.length; i++) {
        const z = zValues[i];
        if (z < minZ) minZ = z;
    }

    // Define bin parameters
    const binSize = 0.01;
    let numBins = Math.ceil((0 - minZ) / binSize);
    if (numBins === 0) numBins = 1;

    // Initialize bins
    const bins = new Array(numBins).fill(0);
    for (const z of zValues) {
        const binIndex = Math.floor((0 - z) / binSize);
        if (binIndex >= 0 && binIndex < bins.length) {
            bins[binIndex]++;
        }
    }

    // generate a plot for first 1000 bins
    const plot = [];
    for (let i = 0; i < 1000; i++) {
        plot.push({ x: i, y: bins[i] });
    }

    // console.log('plot', plot);

    // // Apply smoothing
    // const smoothedBins = smoothHistogram(bins, 30);

    // // Find the first significant peak in the smoothed histogram
    // const groundBinIndex = findFirstSignificantPeak(smoothedBins, 500);

    // if (groundBinIndex === null) {
    //     throw new Error('No significant peak found');
    // }

    // // Compute the Z value corresponding to the bin with the first significant peak
    // const groundZ = 0 - (groundBinIndex + 0.5) * binSize;
    // return {
    //     plot, 
    //     groundZ
    // };

    const groundZ = 0;
    return {plot, groundZ};
};

export {
    isPointTouchingLines,
    pointsEqual,
    calculateCameraPosition,
    findGroundLevel
};