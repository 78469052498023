import React, { useEffect, useRef } from 'react';
import { Joystick, JoystickShape } from 'react-joystick-component';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import baseImage from '../../asset/base.png';
import stickImage from '../../asset/stick.png';
import { isPointTouchingLines } from '../../Utils/Math';

const CameraControls = ({ config, currentCamera, pointerLockRef, controllerMove, controllerRotate }) => {
    const { camera } = useThree();
    camera.rotation.order = 'YXZ';

    const cameraConfig = config.cameraList[currentCamera];
    camera.position.set(cameraConfig.position.x, cameraConfig.position.y, cameraConfig.position.z);
    camera.lookAt(cameraConfig.lookAt.x, cameraConfig.position.y, cameraConfig.lookAt.z);

    camera.fov = config.fov;
    camera.updateProjectionMatrix();

    const moveForward = useRef(false);
    const moveBackward = useRef(false);
    const moveLeft = useRef(false);
    const moveRight = useRef(false);
    const moveUp = useRef(false);
    const moveDown = useRef(false);
    const mouseMove = useRef(false);
    const run = useRef(false);

    const speed = config.moveSpeed;
    const cameraRotateSpeed = config.cameraRotateSpeed;
    const groundLevel = 0; // Define the ground level

    const clock = new THREE.Clock();

    const handleKeyDown = (event) => {
        switch (event.code) {
            case 'KeyW': moveForward.current = true; break;
            case 'KeyS': moveBackward.current = true; break;
            case 'KeyA': moveLeft.current = true; break;
            case 'KeyD': moveRight.current = true; break;
            case 'KeyQ': moveUp.current = true; break;
            case 'KeyE': moveDown.current = true; break;
            case 'ShiftLeft': run.current = true; break;
            default: break;
        }
    };

    const handleKeyUp = (event) => {
        switch (event.code) {
            case 'KeyW': moveForward.current = false; break;
            case 'KeyS': moveBackward.current = false; break;
            case 'KeyA': moveLeft.current = false; break;
            case 'KeyD': moveRight.current = false; break;
            case 'KeyQ': moveUp.current = false; break;
            case 'KeyE': moveDown.current = false; break;
            case 'ShiftLeft': run.current = false; break;
            default: break;
        }
    };

    const handleMouseMove = (event) => {
        mouseMove.current = true;
        mouseMove.x = event.movementX;
        mouseMove.y = event.movementY;
    };

    const handleMouseStop = () => {
        mouseMove.current = false;
        mouseMove.x = 0;
        mouseMove.y = 0;
    };

    const walls = config.wall;

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        document.addEventListener('mousemove', handleMouseMove);

        const animate = () => {
            const direction = new THREE.Vector3();
            const right = new THREE.Vector3();
            camera.getWorldDirection(direction);
            direction.y = 0;
            direction.normalize();
            right.crossVectors(camera.up, direction).normalize();

            const delta = clock.getDelta();
            const currentSpeed = run.current ? speed * 2 * delta : speed * delta;
            const currentCameraRotateSpeed = cameraRotateSpeed * delta;

            if (pointerLockRef.current) {
                const cameraPosition = camera.position.clone();

                if (moveForward.current) cameraPosition.addScaledVector(direction, currentSpeed);
                if (moveBackward.current) cameraPosition.addScaledVector(direction, -currentSpeed);
                if (moveLeft.current) cameraPosition.addScaledVector(right, currentSpeed);
                if (moveRight.current) cameraPosition.addScaledVector(right, -currentSpeed);
                if (moveUp.current) cameraPosition.y += currentSpeed; // Move up
                if (moveDown.current) cameraPosition.y -= currentSpeed; // Move down

                // Apply ground blocking
                if (cameraPosition.y < groundLevel) {
                    cameraPosition.y = groundLevel;
                }

                if (controllerMove.current) {
                    cameraPosition.addScaledVector(direction, currentSpeed * controllerMove.y);
                    cameraPosition.addScaledVector(right, -currentSpeed * controllerMove.x);
                }

                if (!isPointTouchingLines([cameraPosition.x, cameraPosition.z], walls)) {
                    camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
                }

                if (mouseMove.current) {
                    camera.rotation.x -= currentCameraRotateSpeed * mouseMove.y * 0.1;
                    camera.rotation.y -= currentCameraRotateSpeed * mouseMove.x * 0.1;
                    handleMouseStop();
                }

                if (controllerRotate.current) {
                    camera.rotation.x += currentCameraRotateSpeed * controllerRotate.y;
                    camera.rotation.y -= currentCameraRotateSpeed * controllerRotate.x;
                    camera.rotation.z += currentCameraRotateSpeed * controllerRotate.z;
                }
            }

            requestAnimationFrame(animate);
        };

        const requestId = requestAnimationFrame(animate);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            cancelAnimationFrame(requestId);
        };
    }, [camera]);

    return null;
};

const MoveController = ({ controllerMove }) => {
    const handleMove = (move) => {
        controllerMove.current = true;
        controllerMove.x = move.x;
        controllerMove.y = move.y;
    };

    const handleStop = () => {
        controllerMove.current = false;
    };

    return (
        <Joystick
            size={100}
            baseShape='square'
            baseColor='white'
            stickImage={stickImage}
            baseImage={baseImage}
            move={handleMove}
            stop={handleStop}
        />
    );
};

const RotateXController = ({ controllerRotate }) => {
    const handleMove = (event) => {
        controllerRotate.current = true;
        controllerRotate.x = event.x;
        controllerRotate.y = event.y;
        controllerRotate.z = 0;
    };

    const handleStop = () => {
        controllerRotate.current = false;
    };

    return (
        <Joystick
            size={100}
            baseShape='square'
            baseColor='white'
            stickImage={stickImage}
            baseImage={baseImage}
            move={handleMove}
            stop={handleStop}
        />
    );
};

const RotateZController = ({ controllerRotate }) => {
    const handleMove = (event) => {
        controllerRotate.current = true;
        controllerRotate.x = 0;
        controllerRotate.y = 0;
        controllerRotate.z = event.x;
    };

    const handleStop = () => {
        controllerRotate.current = false;
    };

    return (
        <Joystick
            size={100}
            baseColor="black"
            stickColor="white"
            controlPlaneShape={JoystickShape.AxisX}
            move={handleMove}
            stop={handleStop}
        />
    );
};

const Controller = ({ controllerMove, controllerRotate, moveUp, moveDown }) => {
    const showRotationZ = false;

    const styles = {
        moveController: {
            position: 'absolute',
            padding: '20px',
            borderRadius: '5px',
            bottom: '60px',
            left: '40px',
        },
    };

    return (
        <div>
            <div style={{
                position: 'absolute',
                padding: '20px',
                borderRadius: '5px',
                bottom: '60px',
                left: '40px',
            }}>
                <MoveController controllerMove={controllerMove} />
            </div>
            <div style={{
                position: 'absolute',
                padding: '20px',
                borderRadius: '5px',
                bottom: '60px',
                right: '40px',
            }}>
                <RotateXController controllerRotate={controllerRotate} />
            </div>
            {showRotationZ &&
                <div style={{
                    position: 'absolute',
                    background: 'rgba(255, 255, 255, 0.8)',
                    padding: '20px',
                    borderRadius: '5px',
                    bottom: '220px',
                    right: '40px',
                }}>
                    <RotateZController controllerRotate={controllerRotate} />
                </div>
            }
        </div>
    );
};

export {
    CameraControls,
    Controller
};
