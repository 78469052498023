import React, { useState } from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ArrowLeftSharpIcon from '@mui/icons-material/ArrowLeftSharp';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';

const RangeController = ({
    range, handleSetRange
}) => {

    const rate = 0.1;

    const [showDescription, setShowDescription] = useState(false);
    const handleShowDescription = () => {
        setShowDescription(!showDescription);
    };

    const styles = {
        helpIcon: {
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
            borderRadius: '50%',
            fontSize: '20px',
            cursor: 'pointer',
        }
    };

    return (
        <div style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            padding: '10px 0',
        }}>
            <label
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: '#fff',
                    margin: '5px 0',
                    marginLeft: '7px',
                }}
            >
                <p
                    style={{
                        fontFamily: '"Poppins", sans-serif',
                        fontSize: '16px',
                        userSelect: 'none',
                        transition: 'color 0.3s',
                        margin: 0,
                    }}
                >
                    View Height: {range.max.toFixed(2)}
                </p>
                <HelpOutlineIcon
                    style={{
                        fontSize: '20px',
                        cursor: 'pointer',
                    }}
                    onClick={handleShowDescription}
                />
            </label>

            {showDescription && <p
                style={{
                    fontFamily: '"Poppins", sans-serif',
                    fontSize: '16px',
                    color: '#FFFFFFB2',
                    margin: '10px 0',
                }}
            >
                Drag the slide bar to modify the model’s visible height. This will ensure the model displays at the right level to show details of the interior.
            </p>}

            <input
                type="range"
                min="0.1"
                max="20"
                step={rate}
                value={range.max}
                onChange={(e) => handleSetRange(range.min, parseFloat(e.target.value))}
                style={{
                    accentColor: '#3366FF',
                    margin: '10px 5px',
                }}
            />
        </div>
    );
};

export default RangeController;