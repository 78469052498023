import React, { useState, useEffect } from 'react';
import icon from 'asset/kaleida_icon.png';
import linkedinIcon from 'asset/LI-Logo.png';
import discordIcon from 'asset/discord_icon.svg';
import { useNavigate } from 'react-router-dom';

const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
};

const styles = (isMobile) => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: isMobile ? 'column' : 'row',
    padding: '20px 40px',
    backgroundColor: '#040507',
    color: '#fff',
    textAlign: 'left',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: isMobile ? '10px' : '0',
  },
  logo: {
    height: isMobile ? '40px' : '50px',
    width: isMobile ? '40px' : '50px',
    marginRight: isMobile ? '0' : '10px',
  },
  text: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: isMobile ? '18px' : '20px',
    fontWeight: 'bold',
    color: '#fff',
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '10px',
  },
  buttonSecondary: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: isMobile ? '12px' : '16px',
    margin: '0 0px',
    color: '#fff',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  socialIcon: {
    height: '20px',
    marginTop: '0px',
    marginLeft: '10px',
  },
});

const Footer = () => {
  const [width] = useWindowSize();
  const isMobile = width < 768;
  const appliedStyles = styles(isMobile);
  const navigate = useNavigate();

  const handlePrivacyPolicy = () => {
    navigate('/privacy-policy');
  };

  const handleTermsOfService = () => {
    navigate('/terms-of-service');
  };

  return (
    <footer className="footer" style={appliedStyles.footer}>
      <div className="logoContainer" style={appliedStyles.logoContainer}>
        <img src={icon} alt="Kaleida Logo" className="logo" style={appliedStyles.logo} />
        <div className="text" style={appliedStyles.text}>Kaleida 2024</div>
      </div>
      <div className="linkContainer" style={appliedStyles.linkContainer}>
        <button className="header-button" onClick={handlePrivacyPolicy} style={appliedStyles.buttonSecondary}>
          Privacy Policy
        </button>
        <button className="header-button" onClick={handleTermsOfService} style={appliedStyles.buttonSecondary}>
          Terms of Service
        </button>
        <a href="https://www.linkedin.com/company/kaleida-space" target="_blank" rel="noopener noreferrer">
          <img src={linkedinIcon} alt="LinkedIn" className="linkedinIcon" style={appliedStyles.socialIcon} />
        </a>
        <a href="https://discord.gg/7sEgYVfs" target="_blank" rel="noopener noreferrer">
          <img src={discordIcon} alt="Discord" className="discordIcon" style={appliedStyles.socialIcon} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
