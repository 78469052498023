import React, { useEffect } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';

const MouseHandler = ({ setPoint }) => {
    const { camera, gl } = useThree();

    const isMouseOnCanvas = (target) => {
        console.log(target.tagName);
        return target.tagName === 'CANVAS';
    };

    const handleClick = (event) => {
        const { clientX, clientY } = event;
        const rect = gl.domElement.getBoundingClientRect();

        if (event.button !== 0) return;
        if (!isMouseOnCanvas(event.target)) return;

        if (rect.left > clientX || clientX > rect.right || rect.top > clientY || clientY > rect.bottom) return;

        const x = ((clientX - rect.left) / rect.width) * 2 - 1;
        const y = -((clientY - rect.top) / rect.height) * 2 + 1;

        const vector = new THREE.Vector3(x, y, 0).unproject(camera);

        // Raycasting to find the intersection with the ground plane at y = 0
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera({ x, y }, camera);
        const plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
        const intersection = new THREE.Vector3();
        raycaster.ray.intersectPlane(plane, intersection);

        if (intersection) setPoint(intersection.toArray());
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, [camera]);

    return null;
};

export default MouseHandler;