import React, { useRef, useState } from 'react';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#1e1e1e',
        color: '#f1f1f1',
        padding: '20px',
        borderRadius: '10px',
        width: '90%',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.6)',
        fontFamily: '"Poppins", sans-serif', // Matching font
    },
    formRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: '20px',
    },
    label: {
        fontSize: '16px', // Adjusted to match CalibrationController
        color: '#FFFFFFB2', // Using the same color scheme as CalibrationController
        fontFamily: '"Poppins", sans-serif',
    },
    value: {
        fontSize: '18px',
        color: '#3366FF',
    },
    input: {
        width: '150px',
        padding: '8px',
        borderRadius: '5px',
        border: '1px solid #3366FF',
        backgroundColor: '#333',
        color: '#f1f1f1',
        fontFamily: '"Poppins", sans-serif', // Matching font
    },
    buttonRow: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        margin: '10px 30px', // Match the CalibrationController layout
    },
    applyButton: {
        backgroundColor: '#3366FF',
        border: 'none',
        color: 'white',
        padding: '5px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontFamily: '"Poppins", sans-serif', // Matching font
        marginBottom: '0',
    },
    toggleButton: {
        backgroundColor: '#444',
        border: 'none',
        color: 'white',
        padding: '10px 10px',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
        fontFamily: '"Poppins", sans-serif', // Matching font
    },
};


const ScaleForm = ({ distance, setActualDistance, scaleModel }) => {
    const inputRef = useRef(null);
    const [unit, setUnit] = useState('m');

    const handleApply = () => {
        scaleModel();
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    const toggleUnit = () => {
        setUnit((prevUnit) => (prevUnit === 'm' ? 'ft' : 'm'));
    };

    const convertDistance = (dist, toUnit) => {
        return toUnit === 'm' ? dist : dist * 3.28084; // 1 meter = 3.28084 feet
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        const number = parseFloat(value);
        if (!isNaN(number) && number > 0) {
            setActualDistance(number);
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.formRow}>
                <label style={styles.label}>
                    Model Length
                </label>
                <div style={styles.value}>
                    {convertDistance(distance, unit).toFixed(2)}
                </div>
                <button style={styles.toggleButton} onClick={toggleUnit}>
                    unit: {unit === 'm' ? 'm' : 'ft'}
                </button>
            </div>
            <div style={styles.formRow}>
                <label style={styles.label}>
                    Actual Length
                </label>
                <input
                    type="number"
                    min="0.01"
                    step="0.01"
                    ref={inputRef}
                    onChange={handleInputChange}
                    style={styles.input}
                />
            </div>
            <div style={styles.buttonRow}>
                <button style={styles.applyButton} onClick={handleApply}>
                    Apply
                </button>
            </div>
        </div>
    );
};

export default ScaleForm;
