import React from 'react';
// import { Splat } from '@react-three/drei';
import { Splat } from 'SplatShader/Splat';

const SplatRenderer = ({ config, pixelRatio }) => {
    const modelUrl = config.url;
    const modelScale = config.scale;
    const modelRotation = [config.rotation[0], config.rotation[1], config.rotation[2]];
    const modelPosition = [config.center.x, config.center.y, config.center.z];

    return (
        <Splat src={modelUrl} scale={modelScale} rotation={modelRotation} position={modelPosition} pixelRatio={pixelRatio} />
    );
};

export {
    SplatRenderer
};
