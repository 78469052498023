import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import ModelControllerInstance from '../controllers/ModelController';
import JobControllerInstance from 'controllers/JobController';

import { readFileToBuffer, splatToPoints } from '../Utils/DataLoader';
import { reducePoints } from '../Utils/SplatMath';
import Spinner from '../components/Spinner';
import Volume from '../components/calibration/Volume';
import ControlBar from '../components/calibration/ControlBar';

import { findGroundLevel } from '../Utils/Math';
import { uploadScreenshotToS3 } from 'Utils/Screenshot';

import NotFound from './NotFound';


const Calibration = ({ user }) => {
    const { modelId } = useParams();
    const navigate = useNavigate();
    const [modelConfig, setModelConfig] = useState(null);

    const [points, setPoints] = useState(null);
    const [showBlob, setShowBlob] = useState(false);
    const [loading, setLoading] = useState(false);

    // Info
    const [info, setInfo] = useState({
        name: 'name',
        description: 'description'
    });
    // const [jobStatus, setJobStatus] = useState(null);
    const [plot, setPlot] = useState([]);

    // process control
    const [step, setStep] = useState(0);
    const [disableSave, setDisableSave] = useState(false);

    // axis
    const [axis, setAxis] = useState('N'); // State for selected axis in window view

    // calibration [scale, rotation, translation]
    const [scale, setScale] = useState(1);
    const [rotation, setRotation] = useState({ x: 0, y: 0, z: 0 });
    const [translation, setTranslation] = useState({ x: 0, y: 0, z: 0 });

    // preformance
    const [range, setRange] = useState({ min: -10, max: 10 });
    const [percentage, setPercentage] = useState(100);

    // scale method
    const [showScale, setShowScale] = useState(false);
    const [startScale, setStartScale] = useState(false);
    const [line, setLine] = useState({ start: null, end: null });
    const [distance, setDistance] = useState(0);
    const [actualDistance, setActualDistance] = useState(0);

    // wall
    const [showWalls, setShowWalls] = useState(false);
    const [walls, setWalls] = useState([]);
    const [addWall, setAddWall] = useState(false);
    const [editWall, setEditWall] = useState(false);
    const [selectedWall, setSelectedWall] = useState(null);
    const [wallHeight, setWallHeight] = useState(1.4);

    // camera
    const [showCameras, setShowCameras] = useState(false);
    const [cameras, setCameras] = useState([]);
    const [addCamera, setAddCamera] = useState(false);
    const [cameraHeight, setCameraHeight] = useState(1.4);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // calibration
    const calibration = useMemo(() => {
        return {
            scale: scale,
            rotation: [rotation.x / 180 * Math.PI, rotation.y / 180 * Math.PI, rotation.z / 180 * Math.PI],
            translation: [translation.x, translation.y, translation.z]
        };
    }, [scale, rotation, translation]);

    // load data useEffect
    const fetchModelConfig = async () => {
        try {
            const config = await ModelControllerInstance.getModelById(modelId);
            // const job = await JobControllerInstance.getJobById(config.jobId);

            // setJobStatus(job.statusMessage);

            //if user is not admin and model is not play ground, disable save button
            if (user.role !== 'admin' && config.id === "21f93a66-5a2d-433e-9084-5b2e7fd7a6ff") {
                setDisableSave(true);
            } else {
                if (config.ownerId !== user.uid) {
                    navigate('/not-found'); // Redirect to NotFound if user is not the owner
                }
            }

            setModelConfig(config);

            // console.log(config);

            // info
            setInfo({
                name: config.name,
                description: config.description
            });

            // scale, rotation, translation
            setTranslation(config.center);
            setRotation({
                x: config.rotation[0] * (180 / Math.PI),
                y: config.rotation[1] * (180 / Math.PI),
                z: config.rotation[2] * (180 / Math.PI)
            });
            setScale(config.scale);

            // range
            setRange(config.heightRange);

            // wall and camera
            setWalls(config.wall);
            setCameras(config.cameraList);

            const buffer = await readFileToBuffer(config.url);
            const originPoints = splatToPoints(buffer);
            setPoints(originPoints);

            // const { plot, offsetY } = findGroundLevel(originPoints);
            // // console.log("groundDetect plot: ", plot);
            // setPlot(plot);
            // const maxY = Math.max(...plot.map((point) => point.y));
            // const maxYHeight = plot.find((point) => point.y === maxY).x / 100;
            // console.log("groundDetect offsetY: ", maxYHeight);
        } catch (error) {
            console.error(`Failed to fetch model configuration or load data: ${error}`);
        }
    };

    useEffect(() => {
        fetchModelConfig();
    }, [modelId]);

    const reducedPoints = useMemo(() => {
        return points ? reducePoints(points, percentage) : null;
    }, [points, percentage]);

    // Effect for set scale, wall, camera
    useEffect(() => {
        if (line.start && line.end) {
            if (showScale && startScale) {
                const distance = Math.sqrt(
                    Math.pow(line.end[0] - line.start[0], 2) +
                    Math.pow(line.end[1] - line.start[1], 2) +
                    Math.pow(line.end[2] - line.start[2], 2)
                );
                setDistance(distance);
            }
            if (showWalls && addWall) {
                setWalls([...walls, [[line.start[0], line.start[2]], [line.end[0], line.end[2]]]]);
            }
            if (showCameras && addCamera) {
                setCameras([...cameras, {
                    name: "new camera",
                    position: { x: line.start[0], y: line.start[1], z: line.start[2] },
                    lookAt: { x: line.end[0], y: line.end[1], z: line.end[2] },
                    thumbnail: ""
                }]);
            }
        }
    }, [line]);

    // handle range
    const handleSetRange = (min, max) => {
        if (min < -100 || max > 100 || min > max) return;
        setRange({ min, max });
        setWallHeight(max);
    };

    // handle blob or point cloud
    const toggleShowBlob = () => {
        setShowBlob(!showBlob);
    };

    // handle percentage
    const handleSetPercentage = (percentage) => {
        if (percentage <= 0 || percentage > 100) return;
        setPercentage(percentage);
    };

    // handle axis
    const handleSetAxis = (axis) => {
        if (axis !== 'Y') {
            if (addCamera) toggleAddCamera();
            if (startScale) toggleStartScale();
            if (addWall) toggleAddWall();
            if (editWall) toggleEditWall();
        }
        setAxis(axis);
    };

    // handle set step
    const handleSetStep = (step) => {
        switch (step) {
            case 0:
                if (axis !== 'N') setAxis('N');
                if (showBlob) toggleShowBlob();
                if (showScale) toggleShowScale();
                if (showCameras) toggleShowCameras();
                if (showWalls) toggleShowWalls();
                break;
            case 1:
                if (axis !== 'N') setAxis('N');
                if (showBlob) toggleShowBlob();
                if (!showScale) toggleShowScale();
                if (showCameras) toggleShowCameras();
                if (showWalls) toggleShowWalls();
                break;
            case 2:
                if (axis !== 'N') setAxis('N');
                if (!showBlob) toggleShowBlob();
                if (showScale) toggleShowScale();
                if (!showCameras) toggleShowCameras();
                if (showWalls) toggleShowWalls();
                break;
            case 3:
                if (axis !== 'N') setAxis('N');
                if (!showBlob) toggleShowBlob();
                if (showScale) toggleShowScale();
                if (showCameras) toggleShowCameras();
                if (!showWalls) toggleShowWalls();
                break;
            default:
                break;
        };

        setStep(step);
    };

    // handle scale method
    const scaleModel = () => {

        if (!line.start || !line.end) {
            console.error("Line not set");
            return;
        }

        if (actualDistance === 0) {
            console.error("Actual distance not set");
            return;
        }

        const newScale = scale * (actualDistance / distance);
        console.log("scaleModel function called, set scale to: ", newScale.toFixed(2));
        setLine({
            start: line.start.map((num) => num * (actualDistance / distance)),
            end: line.end.map((num) => num * (actualDistance / distance))
        });
        setDistance(actualDistance);
        setActualDistance(0);
        setScale(newScale);
        if (walls.length > 0) {
            const newWalls = walls.map(wall => wall.map(point => point.map(num => num * (actualDistance / distance))));
            setWalls(newWalls);
        }
        if (translation) {
            setTranslation({
                x: translation.x * (actualDistance / distance),
                y: translation.y * (actualDistance / distance),
                z: translation.z * (actualDistance / distance)
            });
        }
        if (cameras.length > 0) {
            const newCameras = cameras.map(camera => {
                return {
                    ...camera,
                    position: {
                        x: camera.position.x * (actualDistance / distance),
                        y: camera.position.y * (actualDistance / distance),
                        z: camera.position.z * (actualDistance / distance)
                    },
                    lookAt: {
                        x: camera.lookAt.x * (actualDistance / distance),
                        y: camera.lookAt.y * (actualDistance / distance),
                        z: camera.lookAt.z * (actualDistance / distance)
                    }
                };
            });
            setCameras(newCameras);
        }
    };

    const clearLine = () => {
        setLine({ start: null, end: null });
        setDistance(0);
        setActualDistance(0);
    };

    const toggleShowScale = () => {
        if (showScale) {
            clearLine();
            setShowScale(false);
            setStartScale(false);
        } else {
            if (showWalls) toggleShowWalls();
            if (showCameras) toggleShowCameras();
            setShowScale(true);
        }
    };

    const toggleStartScale = () => {
        if (startScale) {
            setStartScale(false);
        } else {
            setStartScale(true);
        }
    };

    // handle wall
    const toggleShowWalls = () => {
        if (showWalls) {
            setSelectedWall(null);
            if (addWall) toggleAddWall();
            if (editWall) toggleEditWall();
            setShowWalls(false);
        } else {
            if (showScale) toggleShowScale();
            if (showCameras) toggleShowCameras();
            setShowWalls(true);
        }
    };

    const clearWalls = () => {
        setWalls([]);
        setLine({ start: null, end: null });
    };

    const removePreviousWall = () => {
        if (walls.length > 0) {
            setWalls(walls.slice(0, walls.length - 1));
        }
    };

    const toggleAddWall = () => {
        setLine({ start: null, end: null });
        setAddWall(!addWall);
        setEditWall(false);
        setSelectedWall(null);
    };

    const toggleEditWall = () => {
        setEditWall(!editWall);
        setAddWall(false);
        setSelectedWall(null);
    };

    const removeSelectedWall = () => {
        setWalls(walls.filter((line) => line !== selectedWall));
        setSelectedWall(null);
    };

    // handle camera
    const toggleShowCameras = () => {
        if (showCameras) {
            if (addCamera) toggleAddCamera();
            setShowCameras(false);
        } else {
            if (showScale) toggleShowScale();
            if (showWalls) toggleShowWalls();
            setShowCameras(true);
        }
    };

    const clearCameras = () => {
        setCameras([]);
    };

    const removePreviousCamera = () => {
        if (cameras.length > 0) {
            setCameras(cameras.slice(0, cameras.length - 1));
        }
    };

    const toggleAddCamera = () => {
        setLine({ start: null, end: null });
        setAddCamera(!addCamera);
    };

    // handle reset and save

    const handleReset = () => {
        fetchModelConfig();
    };

    const handleSave = async () => {
        setLoading(true);
        let cameraUpload = [];
        if (cameras.length === 0) {
            cameras.push({
                name: 'Default View',
                thumbnail: '',
                position: { x: 0, y: 0, z: 0 },
                lookAt: { x: 0, y: 0, z: 0 },
            });
        }
        // Use for..of to ensure each camera is processed sequentially
        for (const camera of cameras) {
            if (camera.thumbnail && camera.thumbnail.startsWith('data:image')) {
                try {
                    const thumbnailUrl = await uploadScreenshotToS3(camera.thumbnail);
                    camera.thumbnail = thumbnailUrl;
                } catch (error) {
                    camera.thumbnail = '';
                    console.error('Failed to upload thumbnail to S3:', error);
                    // Handle error (e.g., continue with the next camera, or break out of the loop)
                    // continue;
                }
            }
            cameraUpload.push(camera);
        }

        try {
            const updatedJob = await JobControllerInstance.updateJob(modelConfig.jobId, {
                modelName: info.name,
                // statusMessage: jobStatus,
            });
            const calibratedModel = await ModelControllerInstance.updateModel(modelId, {
                "name": info.name,
                "description": info.description,
                "parameters": {
                    "center": translation,
                    "rotation": rotation,
                    "scale": scale,
                    "heightRange": range,
                    "wall": walls,
                    "cameraList": cameraUpload
                }
            });
            console.log('Update Model:', calibratedModel);
            alert('Model updated successfully!');
            navigate(-1);
        } catch (error) {
            console.error('Failed to calibrate model:', error);
            alert('Failed to calibrate model!');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'black',
            overflow: 'hidden',
            position: 'relative', // Add relative positioning
        }}>
            {loading && ( // Conditional rendering of the spinner and overlay
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000,
                }}>
                    <Spinner />
                </div>
            )}
            <div
                style={{
                    height: '100vh',
                    width: '100vw',
                    background: 'white',
                    overflow: 'hidden',
                }}
            >
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                    }}
                >
                    <Volume
                        axis={axis} range={range}
                        points={reducedPoints} calibration={calibration}
                        splatUrl={modelConfig ? modelConfig.url : null} showBlob={showBlob} setShowBlob={setShowBlob}
                        line={line} setLine={setLine} showScale={showScale} startScale={startScale}
                        walls={walls} showWalls={showWalls} wallHeight={wallHeight}
                        addWall={addWall} editWall={editWall} selectedWall={selectedWall} setSelectedWall={setSelectedWall}
                        cameras={cameras} showCameras={showCameras} addCamera={addCamera}
                        plot={plot}
                    />
                </div>
                <div style={{
                    height: '100vh',
                    width: '100vw'
                }}>
                    <ControlBar
                        info={info} setInfo={setInfo}
                        splatUrl={modelConfig ? modelConfig.url : null}
                        step={step} handleSetStep={handleSetStep}
                        showScale={showScale} toggleShowScale={toggleShowScale}
                        axis={axis} handleSetAxis={handleSetAxis}
                        showBlob={showBlob} toggleShowBlob={toggleShowBlob}
                        range={range} handleSetRange={handleSetRange}
                        percentage={percentage} handleSetPercentage={handleSetPercentage}
                        calibration={calibration}
                        translation={translation} setTranslation={setTranslation}
                        rotation={rotation} setRotation={setRotation}
                        scale={scale} setScale={setScale}
                        distance={distance} setActualDistance={setActualDistance}
                        startScale={startScale} toggleStartScale={toggleStartScale}
                        clearLine={clearLine} scaleModel={scaleModel} setLine={setLine}
                        walls={walls} setWalls={setWalls} clearWalls={clearWalls} removePreviousWall={removePreviousWall}
                        addWall={addWall} toggleAddWall={toggleAddWall} editWall={editWall} toggleEditWall={toggleEditWall}
                        selectedWall={selectedWall} removeSelectedWall={removeSelectedWall}
                        wallHeight={wallHeight} setWallHeight={setWallHeight}
                        cameras={cameras} setCameras={setCameras}
                        addCamera={addCamera} toggleAddCamera={toggleAddCamera}
                        handleReset={handleReset} handleSave={handleSave} disableSave={disableSave}
                    />
                </div>
            </div>
        </div>
    );
};

export default Calibration;