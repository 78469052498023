import api from 'api/axiosConfig';

class ModelModel {

    async getAllModelsByUser() {
        try {
            const response = await api.get('/model');
            return response.data;
        } catch (error) {
            console.error('Failed to get models:', error);
            throw error;
        }
    }

    async getModelById(_id) {
        try {
            const response = await api.get(`/model?modelId=${_id}`);
            return response.data;
        } catch (error) {
            console.error('Failed to get model by id:', error);
            throw error;
        }
    }

    async createModel(data) {
        try {
            const response = await api.post('/model', data);
            return response.data;
        } catch (error) {
            console.error('Failed to create model:', error);
            throw error;
        }
    }

    async updateModel(_id, data) {
        try {
            const response = await api.put(`/model?modelId=${_id}`, data);
            return response.data;
        } catch (error) {
            console.error('Failed to update model:', error);
            throw error;
        }
    }

    async calibrateModel(data) {
        try {
            const response = await api.post("/model/calibration", data);
            return response.data;
        } catch (error) {
            console.error('Failed to calibrate model:', error);
            throw error;
        }
    }

    async deleteModel(_id) {
        try {
            const response = await api.delete(`/model?modelId=${_id}`);
            return response.data;
        } catch (error) {
            console.error('Failed to delete model:', error);
        }
    }

    async generateModel(data) {
        try {
            const response = await api.post('/model/process', data);
            return response.data;
        } catch (error) {
            console.error('Failed to generate model:', error);
            throw error;
        }
    }
}
const modelModelInstance = new ModelModel();
export default modelModelInstance;