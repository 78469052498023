import React, { useEffect, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';

import DehazeIcon from '@mui/icons-material/Dehaze';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
    });
  
    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
        });
      };
  
      window.addEventListener("resize", handleResize);
      handleResize();
  
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return windowSize;
  };

const Mask = () => {
    const [show, setShow] = useState(false);
    const [pointerLockState, setPointerLockState] = useState(false);
    const timer = useRef(null);

    useEffect(() => {
        const handlePointerLockChange = () => {
            if (document.pointerLockElement) {
                setPointerLockState(true);
            } else {
                setPointerLockState(false);
            }
        };

        const handleExit = () => {
            if (document.pointerLockElement) {
                setPointerLockState(false);
                document.exitPointerLock();
            }
            document.removeEventListener('pointerlockchange', handlePointerLockChange);
        };

        document.addEventListener('pointerlockchange', handlePointerLockChange);
        return () => handleExit();
    }, []);

    useEffect(() => {
        const handleSetShowTimer = () => {
            setShow(false);
            timer.current = setTimeout(() => {
                setShow(true);
            }, 1500);
        };

        handleSetShowTimer();
        return () => clearTimeout(timer.current);
    }, [pointerLockState]);

    const maskLayout = () => (
        <div
            style={{
                position: 'absolute',
                background: 'rgba(255, 255, 255, 0.2)',
                height: '100vh',
                width: '100vw',
            }}
        >
            {show && (
                <button
                    onClick={() => { document.body.requestPointerLock(); }}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '20px 40px',
                        borderRadius: '10px',
                        fontSize: '24px',
                        cursor: 'pointer',
                        fontFamily: '"Poppins", sans-serif',
                        background: 'rgba(255, 255, 255, 0.8)',
                    }}
                >
                    <p style={{ fontFamily: '"Poppins", sans-serif', }}>Click here to start</p>
                </button>
            )}
        </div>
    );

    const noMaskLayout = () => (
        <div onClick={() => document.body.requestPointerLock()}></div>
    );

    const layout = () => {
        return (
            <>
                <div style={{
                    position: 'absolute',
                    zIndex: 10,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    top: '10px',
                    width: '450px',
                    padding: '0px 0px',
                    borderRadius: '5px',
                    background: 'rgba(255, 255, 255, 0.4)',
                    textAlign: 'center', // Center align the text
                }}>
                    <p style={{
                        fontFamily: '"Poppins", sans-serif',
                        fontSize: '16px',
                        color: '#000',
                    }}>
                        {pointerLockState ? (
                            'Press Esc to stop interaction'
                        ) : (
                            <>
                                Click to start interaction and press Esc to stop<br />
                                Move: W/A/S/D or Arrow Buttons<br />
                                Move Up: Q<br />
                                Move Down: E<br />
                                Run: Shift<br />
                            </>
                        )}
                    </p>
                </div>
                {pointerLockState ? noMaskLayout() : maskLayout()}
            </>
        );
    };

    return layout();
};

const CameraList = ({ config, currentCamera, setCurrentCamera }) => {

    const useWindowSize = () => {
        const [windowSize, setWindowSize] = useState({
            width: undefined,
        });

        useEffect(() => {
            const handleResize = () => {
                setWindowSize({
                    width: window.innerWidth,
                });
            };

            window.addEventListener("resize", handleResize);
            handleResize();

            return () => window.removeEventListener("resize", handleResize);
        }, []);

        return windowSize;
    };

    const [show, setShow] = useState(false);
    const [pointerLockState, setPointerLockState] = useState(false);
    const timer = useRef(null);

    useEffect(() => {
        const handlePointerLockChange = () => {
            if (document.pointerLockElement) {
                setPointerLockState(true);
            } else {
                setPointerLockState(false);
            }
        };

        const handleExit = () => {
            if (document.pointerLockElement) {
                setPointerLockState(false);
                document.exitPointerLock();
            }
            document.removeEventListener('pointerlockchange', handlePointerLockChange);
        };

        document.addEventListener('pointerlockchange', handlePointerLockChange);
        return () => handleExit();
    }, []);

    useEffect(() => {
        const handleSetShowTimer = () => {
            setShow(false);
            timer.current = setTimeout(() => {
                setShow(true);
            }, 1500);
        };

        handleSetShowTimer();
        return () => clearTimeout(timer.current);
    }, [pointerLockState]);

    const { width } = useWindowSize();
    const isMobile = width < 768;
    if (pointerLockState) return null;
    return (
        <div style={{
            position: 'absolute',
            top: isMobile ? '20px' : '40px', // Adjust top position for mobile
            right: isMobile ? '10px' : '40px', // Adjust right position for mobile
            width: isMobile ? '150px' : '200px', // Adjust width for mobile
            height: 'auto',
            borderRadius: '10px',
            background: 'rgba(0, 0, 0, 0.5)',
            padding: '15px',
        }}>
            <div style={{
                width: '100%',
                maxHeight: isMobile ? '100px' : '200px', // Adjust max height for mobile
                overflowY: 'auto',
                overflowX: 'hidden',
            }}>
                <ul style={{
                    listStyleType: 'none',
                    fontFamily: '"Poppins", sans-serif',
                    fontSize: '16px',
                    width: '100%', // Set to full width
                    padding: '0',
                    margin: '0',
                }}>
                    {config.cameraList.map((camera, index) => (
                        <li
                            key={index}
                            style={{
                                padding: '8px 12px', // Adjust padding for mobile
                                fontSize: isMobile ? '14px' : '16px', // Adjust font size for mobile
                                cursor: 'pointer',
                                backgroundColor: currentCamera === index ? '#555' : '#333',
                                marginBottom: '5px',
                                borderRadius: '5px',
                                textAlign: 'center',
                                transition: 'background-color 0.3s',
                                wordBreak: 'break-word',
                            }}
                            onClick={() => setCurrentCamera(index)}
                        >
                            {camera.name ? camera.name : `${index + 1}`}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

const Info = ({ config, showDescriptionRef, handleShowDescription }) => {
    const { width } = useWindowSize();
    const isMobile = width < 768;

    const [showDescription, setShowDescription] = useState(false);

    const [show, setShow] = useState(false);
    const [pointerLockState, setPointerLockState] = useState(false);
    const timer = useRef(null);

    useEffect(() => {
        const handlePointerLockChange = () => {
            if (document.pointerLockElement) {
                setPointerLockState(true);
            } else {
                setPointerLockState(false);
            }
        };

        const handleExit = () => {
            if (document.pointerLockElement) {
                setPointerLockState(false);
                document.exitPointerLock();
            }
            document.removeEventListener('pointerlockchange', handlePointerLockChange);
        };

        document.addEventListener('pointerlockchange', handlePointerLockChange);
        return () => handleExit();
    }, []);

    useEffect(() => {
        const handleSetShowTimer = () => {
            setShow(false);
            timer.current = setTimeout(() => {
                setShow(true);
            }, 1500);
        };

        handleSetShowTimer();
        return () => clearTimeout(timer.current);
    }, [pointerLockState]);


    useEffect(() => {
        // Setting up an observer to detect changes in the `ref` value
        const checkRefChange = () => {
            if (showDescriptionRef.current !== showDescription) {
                setShowDescription(showDescriptionRef.current);
            }
        };

        // You can use setInterval or a more sophisticated way like MutationObserver
        const interval = setInterval(checkRefChange, 100); // Check every 100ms

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, [showDescriptionRef, showDescription]);

    const getInfo = (config) => {
        let info = {
            name: config.name,
            username: config.username,
            description: config.description.trim(),
        };

        if (!info.description) {
            info.description = 'visit kaleida.space for more information';
        }

        return info;
    };

    const info = getInfo(config);

    const Icon = () => (
        <a
            href="https://kaleida.space"
            target="_blank"
            style={{
                float: 'right',
                textDecoration: 'none'
            }}
            rel="noreferrer"
        >
            <div style={{
                color: '#fff',
                padding: '5px 10px',
                borderRadius: '3px',
                display: 'flex',
                alignItems: 'center',
                fontFamily: '"Poppins", sans-serif',
                fontSize: '18px',
            }}>
                <img src="https://s3.amazonaws.com/kaleida.space/assets/kaleida_icon.png" alt="Kaleida Icon" style={{ width: '30px', height: '30px', marginRight: '5px' }} />
                Kaleida
            </div>
        </a>
    );

    if (pointerLockState) return null;
    return (
        <div
            style={{
                position: 'absolute',
                // bottom: showDescription ? '10px' : isMobile ? '-50px' : '10px', // Move out of view on mobile when not expanded
                bottom: '10px',
                right: '10px',
                width: isMobile ? '90%' : '450px',
                padding: isMobile ? '5px 10px' : '10px',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                flexDirection: 'column',
                transition: 'bottom 0.3s', // Smooth transition for slide in/out
            }}
        >
            {/* Swipe Line Indicator */}
            {/* {isMobile && !showDescription && (
                <div
                    style={{
                        width: '40px',
                        height: '4px',
                        backgroundColor: '#fff',
                        borderRadius: '2px',
                        margin: '0 auto',
                        marginBottom: '5px',
                    }}
                ></div>
            )} */}

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <h3
                    style={{
                        color: '#fff',
                        fontFamily: '"Poppins", sans-serif',
                        fontSize: isMobile ? '16px' : '20px',
                        maxWidth: '70%',
                        margin: 0,
                        padding: 0,
                        userSelect: 'none',
                        transition: 'color 0.3s',
                        display: 'flex',
                        alignItems: 'center',
                        wordBreak: 'break-word',
                    }}
                >
                    {info.name}
                    {!showDescription && <ArrowDropDownIcon
                        style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            borderRadius: '50%',
                            padding: '0px',
                            marginLeft: '10px',
                            cursor: 'pointer',
                        }}
                        onClick={handleShowDescription}
                    />}
                    {showDescription && <ArrowDropUpIcon
                        style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            borderRadius: '50%',
                            padding: '0px',
                            marginLeft: '10px',
                            cursor: 'pointer',
                        }}
                        onClick={handleShowDescription}
                    />}
                </h3>

                {!showDescription && Icon()}
            </div>

            {showDescription &&
                <div>
                    <p
                        style={{
                            color: '#fff',
                            fontFamily: '"Poppins", sans-serif',
                            fontSize: '16px',
                            margin: '5px 0',
                            padding: 0,
                            lineHeight: '1.5',
                            wordBreak: 'break-word',
                        }}
                    >
                        {info.username && <p> @{info.username} </p>}
                        {info.description.split('\n').map((line, index) => (
                            <span key={index}>{line}<br /></span>
                        ))}
                    </p>
                    {Icon()}
                </div>
            }
        </div>
    );
};

export {
    Mask,
    CameraList,
    Info
};
