import AWS from 'aws-sdk';
import { updateModel } from '../models/ModelModel';
import { v4 as uuidv4 } from 'uuid';

const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
});

const captureScreenshot = async (renderer, scene, camera, modelId) => {
    renderer.render(scene, camera);
    const screenshot = renderer.domElement.toDataURL('image/png');
    // const screenshotUrl = await uploadScreenshotToS3(screenshot);
    // return screenshotUrl

    return screenshot;
};

const uploadScreenshotToS3 = async (dataUrl) => {
    const blob = await (await fetch(dataUrl)).blob();
    const fileName = `models/thumbnail/${uuidv4()}.png`;
    
    const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: fileName,
        Body: blob,
        ContentType: 'image/png',
        // ACL: 'public-read',
    };

    return new Promise((resolve, reject) => {
        s3.upload(params, (error, data) => {
            if (error) {
                console.error('Failed to upload screenshot to S3:', error);
                reject(error);
            } else {
                resolve(data.Location);
            }
        });
    });
};

export {
    captureScreenshot,
    uploadScreenshotToS3
};