import React from 'react';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import hawaii from 'asset/hawaii.png';
import airbnb from 'asset/airbnb.png';
import community from 'asset/community.png';
import campsite from 'asset/campsite.png';

const Interactive3DModel = () => {

  const isMobile = window.innerWidth <= 768;
  const navigate = useNavigate();

  const styles = {
    sectionBackground: {
      position: 'relative',
      backgroundColor: '#000',
      padding: '100px 20px',
    },
    contentWrapper: {
      position: 'relative',
      zIndex: 1,
      maxWidth: '1200px',
      margin: '0 auto',
    },
    title: {
      textAlign: 'center',
      fontSize: isMobile ? '24px' : '36px', // Responsive font size for mobile
      fontFamily: '"Poppins", sans-serif',
      marginBottom: '20px',
      color: '#FFFFFF',
    },
    description: {
      textAlign: 'center',
      fontSize: isMobile ? '16px' : '18px', // Responsive font size for mobile
      color: '#CCCCCC',
      marginBottom: '50px',
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', // 1 model per row on mobile, 2 per row on larger screens
      gap: '40px',
      padding: isMobile ? '0 20px' : '0', 
    },
    card: {
      position: 'relative',
      cursor: 'pointer',
      overflow: 'hidden',
      borderRadius: '10px',
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease-in-out',
    },
    cardHover: {
      transform: 'scale(1.02)',
    },
    imageContainer: {
      width: '100%', // Ensure images take full width of their container
      height: isMobile ? '200px' : '300px', // Smaller height for mobile
      overflow: 'hidden', // Ensure images don’t overflow outside their container
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover', // Ensure images maintain aspect ratio while filling container
    },
    overlay: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      background: 'rgba(0,0,0,0.7)',
      color: '#fff',
      padding: '20px',
      opacity: 0,
      transition: 'opacity 0.3s ease-in-out',
    },
    overlayVisible: {
      opacity: 1,
    },
    cardTitle: {
      fontSize: isMobile ? '18px' : '24px', // Responsive font size for mobile
      marginBottom: '10px',
    },
    cardDescription: {
      fontSize: isMobile ? '14px' : '16px', // Responsive font size for mobile
      opacity: 0.8,
    },
    discoverMoreButton: {
      marginTop: '50px',
      textAlign: 'center',
    },
    button: {
      padding: '10px 20px',
      backgroundColor: 'transparent',
      border: '1px solid #fff',
      borderRadius: '5px',
      color: '#fff',
      cursor: 'pointer',
      fontSize: '16px',
    },
  };


  const handleImageClick = (modelId) => {
    navigate(`/models/${modelId}`);
  };
  const handleDiscoverMoreClick = () => {
    navigate('/library');
  };

  const WorkCard = ({ icon, title, description, modelId }) => {
    const [isHovered, setIsHovered] = React.useState(false);

    return (
      <div
        style={{ ...styles.card, ...(isHovered ? styles.cardHover : {}) }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => handleImageClick(modelId)}
      >
        <img src={icon} alt={title} style={styles.image} />
        <div style={{ ...styles.overlay, ...((isHovered || isMobile) ? styles.overlayVisible : {}) }}>
          <div style={styles.cardTitle}>{title}</div>
          <div style={styles.cardDescription}>{description}</div>
        </div>
      </div>
    );
  };

  return (
    <div style={styles.sectionBackground}>
      <div style={styles.contentWrapper}>
        <h2 style={styles.title}>Interactive Virtual Tours</h2>
        <p style={styles.description}>Click to explore the immersive spaces.</p>
        <div style={styles.gridContainer}>
          <WorkCard
            icon={airbnb}
            title="Airbnb in Catskill"
            description="Multi-area interior scene showcasing a cozy Airbnb."
            modelId="8274712d-b22c-493d-933b-ae367884cf39"
          />
          <WorkCard
            icon={campsite}
            title="Glamping in Big Sur"
            description="Outdoor experience at a luxury campsite."
            modelId="ad04619b-52fc-4c5d-8114-4d19eec75167"
          />
          <WorkCard
            icon={hawaii}
            title="Resort on Island of Hawaii"
            description="A hotel view and property showcasing a tropical paradise."
            modelId="hawaii_v2"
          />
          <WorkCard
            icon={community}
            title="Community in New Jersey"
            description="Detailed large-scale community visualization."
            modelId="c410e731-c13b-4730-8fd8-6cbc361bda3d"
          />
        </div>
        <div style={styles.discoverMoreButton}>
          <button style={styles.button} onClick={handleDiscoverMoreClick}>
            Discover More in Library
          </button>
        </div>
      </div>
    </div>
  );
};

export default Interactive3DModel;
