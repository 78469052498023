import React from 'react';
import { Link } from 'react-router-dom';

import icon from 'asset/kaleida_icon.png';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { maxHeight, maxWidth } from '@mui/system';

const styles = {
    jobCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '1px solid #ddd',
        borderRadius: '12px',
        padding: '20px',
        backgroundColor: '#1a1a1a',
        color: '#fff',
        // width: '320px',
        // height: '400px',
        width: '100%',
        maxWidth: '320px',
        height: '100%',
        maxHeight: '420px',
        boxSizing: 'border-box',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
    },
    jobCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        flexGrow: 1,
    },
    jobCardHover: {
        transform: 'scale(1.02)',
    },
    jobImage: {
        width: '100%',
        height: 'auto',
        maxHeight: '300px',
        objectFit: 'cover',
        borderRadius: '8px',
        marginBottom: '15px',
        cursor: 'pointer',
        backgroundColor: '#fff',
    },
    jobStatus: {
        fontSize: '16px',
        fontWeight: '500',
        color: '#e0e0e0',
        marginTop: '5px',
    },
    jobTitle: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#fff',
        margin: '10px 0',
        textAlign: 'center',
        wordBreak: 'break-word',
    },
    jobStatusMessage: {
        fontSize: '14px',
        fontWeight: '400',
        color: '#b3b3b3',
        textAlign: 'center',
        marginTop: '5px',
    },
    buttonPrimary: {
        background: '#3366FF',
        color: '#fff',
        border: 'none',
        padding: '10px 24px',
        borderRadius: '8px',
        cursor: 'pointer',
        fontFamily: '"Poppins", sans-serif',
        fontSize: '14px',
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: '#1a4dcc', 
        },
    },
    buttonSecondary: {
        background: 'transparent',
        color: '#fff',
        border: '1px solid #4a90e2',
        padding: '10px 24px',
        borderRadius: '8px',
        cursor: 'pointer',
        fontFamily: '"Poppins", sans-serif',
        fontSize: '14px',
        transition: 'border-color 0.3s ease',
        '&:hover': {
            borderColor: '#ffffff'
        },
    },
    buttonDisabled: {
        background: '#4a90e2',
        color: '#fff',
        border: 'none',
        padding: '10px 24px',
        borderRadius: '8px',
        cursor: 'not-allowed',
        fontFamily: '"Poppins", sans-serif',
        fontSize: '14px',
        opacity: 0.6,
    },
    placeholder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        borderRadius: '8px',
        height: '200px',
        backgroundColor: '#333',
    },
    placeholderImage: {
        width: '40%',
        height: 'auto',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        gap: '15px',
        marginTop: 'auto',
        paddingBottom: '0',
    },
    shareIcon: {
        cursor: 'pointer',
        fontSize: '28px',
        color: '#fff',
        transition: 'color 0.3s ease',
    },
    shareIconHover: {
        color: '#4a90e2',
    },
};

const Card = ({ job, isMobile, handleDelete, handleContinue, handleShare }) => {

    const deleteButton = () => {
        return (
            <button style={styles.buttonSecondary} onClick={() => handleDelete(job._id)}>
                Delete
            </button>
        );
    };

    const continueButton = () => {
        return (
            <button style={styles.buttonPrimary} onClick={() => handleContinue(job)}>
                Continue
            </button>
        );
    }

    const retryButton = () => {
        return (
            <button style={styles.buttonPrimary} onClick={() => handleContinue(job)}>
                Retry
            </button>
        );
    }

    const editButton = () => {
        return (
            <Link to={`/calibration/${job.model._id}`}>
                <button style={isMobile ? styles.buttonDisabled : styles.buttonPrimary} disabled={isMobile}>
                    Edit
                </button>
            </Link>
        );
    }

    const shareButton = () => {
        return (
            <ShareIcon style={styles.shareIcon} onClick={() => handleShare(job, job.model._id)} />
        );
    }

    return (
        <div 
            key={job._id} 
            style={styles.jobCard} 
            onMouseEnter={(e) => e.currentTarget.style.transform = styles.jobCardHover.transform}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'none'}
        >
            {job.status !== 'Completed' ? (
                <div style={styles.jobCardContainer}>
                    <div style={styles.placeholder}>
                        <img src={icon} alt="Placeholder" style={styles.placeholderImage} />
                    </div>
                    <p style={styles.jobTitle}>{job.modelName || ''}</p>
                    <p style={styles.jobStatus}>{job.status}</p>
                    {job.statusMessage && <p style={styles.jobStatusMessage}>{job.statusMessage}</p>}
                    {job.status === 'Pending' ? (
                        <div style={styles.buttonContainer}>
                            {deleteButton()}
                            {continueButton()}
                        </div>
                    ) : job.status === 'Failed' && (
                        <div style={styles.buttonContainer}>
                            {deleteButton()}
                            {retryButton()}
                        </div>
                    )}
                </div>
            ) : (
                <div style={styles.jobCardContainer}>
                    <Link to={`/models/${job.model._id}`}>
                        {job.model.parameters.cameraList.length !== 0 && job.model.parameters.cameraList[0].thumbnail ? (
                            <img src={job.model.parameters.cameraList[0].thumbnail} alt="Model Thumbnail" style={styles.jobImage} />
                        ) : (
                            <div style={styles.placeholder}>
                                <img src={icon} alt="Placeholder" style={styles.placeholderImage} />
                            </div>
                        )}
                    </Link>
                    <p style={styles.jobTitle}>{job.modelName || ''}</p>
                    <p style={styles.jobStatus}>{job.status}</p>
                    {job.statusMessage && <p style={styles.jobStatusMessage}>{job.statusMessage}</p>}
                    
                    <div style={styles.buttonContainer}>
                        {deleteButton()}
                        {editButton()}
                        {shareButton()}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Card;
