// src/models/UserModel.js
import api from 'api/axiosConfig';

class UserModel {
  async createUser(_id, email, name) {
    try {
      const response = await api.post('/user/', { _id, email, name });
      return response.data;
    } catch (error) {
      console.error('Failed to create user:', error);
      throw error;
    }
  }

  async getUser(_id) {
    try {
      const response = await api.get(`/user?userId=${_id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to get user:', error);
    }
  }
  async getUserByEmail(email) {
    try {
      const response = await api.get(`/user/email?email=${email}`);
      return response.data;
    } catch (error) {
      console.error('Failed to get user:', error);
    }
  }

  async updateUser(_id, data) {
    try {
      const response = await api.put(`/user?userId=${_id}`, data);
      return response.data;
    } catch (error) {
      console.error('Failed to update user:', error);
      throw error;
    }
  }

  async deleteUserByEmail(email) {
    try {
      const response = await api.delete(`/user/email?email=${email}`);
      return response.data;
    } catch (error) {
      console.error('Failed to delete user:', error);
    }
  }

  async addToEmailList(email) {
    try {
      const response = await api.post(`/emailList?email=${email}`);
      return response;
    } catch (error) {
      return error;
    }
  }
}

const userModelInstance = new UserModel();
export default userModelInstance;
