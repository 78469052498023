import React, { useState, useRef, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import JobController from '../../controllers/JobController';
import modelController from '../../controllers/ModelController';
import jobModelInstance from 'models/JobModel';
import Spinner from '../Spinner';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
};

const modalStyles = (isMobile) => ({
  modal: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#222222',
    padding: isMobile ? '20px' : '40px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    width: isMobile ? '90%' : '800px', // Adjust width for mobile
    maxWidth: '90%', 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: isMobile ? '20px' : '26px',
    color: '#fff',
    margin: 0,
  },
  subtitle: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: isMobile ? '16px' : '20px',
    color: 'rgba(255, 255, 255, 0.5)',
    margin: '10px 0',
    textAlign: 'left',
    width: '100%',
  },
  closeButton: {
    fontFamily: '"Poppins", sans-serif',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#fff',
    fontSize: isMobile ? '20px' : '24px',
  },
  divider: {
    width: '100%',
    height: '3px',
    color: '#fff',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    margin: '20px 0',
  },
  dropzoneContainer: {
    width: '90%',
    border: '4px dotted rgba(255, 255, 255, 0.5)',
    borderRadius: '26px',
    padding: isMobile ? '16px' : '32px',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
  },
  dropzoneText: {
    fontFamily: '"Poppins", sans-serif',
    color: '#fff',
    fontSize: isMobile ? '20px' : '26px',
  },
  faqContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
    marginBottom: '33px',
  },
  faqIcon: {
    marginRight: '8px',
    color: '#3366FF',
  },
  faqText: {
    fontFamily: '"Poppins", sans-serif',
    color: '#3366FF',
    fontSize: isMobile ? '16px' : '20px',
  },
  primaryButton: {
    width: isMobile? '120px':'171px',
    height: isMobile? '30px':'54px',
    backgroundColor: '#3366FF',
    fontSize: isMobile? '14px':'18px',
    fontFamily: '"Poppins", sans-serif',
    color: '#fff',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  primaryButtonDisabled: {
    width: isMobile? '120px':'171px',
    height: isMobile? '30px':'54px',
    backgroundColor: '#555',
    fontSize: isMobile? '14px':'18px',
    fontFamily: '"Poppins", sans-serif',
    color: '#aaa',
    border: 'none',
    borderRadius: '10px',
    cursor: 'not-allowed',
    textAlign: 'center',
  },
  secondaryButton: {
    width: isMobile? '120px':'171px',
    height: isMobile? '30px':'54px',
    backgroundColor: 'transparent',
    fontSize: isMobile? '14px':'18px',
    fontFamily: '"Poppins", sans-serif',
    color: '#CBD0DC',
    border: '2px solid #CBD0DC',
    borderRadius: '10px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  secondaryButtonDisabled: {
    width: isMobile? '120px':'171px',
    height: isMobile? '30px':'54px',
    backgroundColor: 'transparent',
    fontSize: isMobile? '14px':'18px',
    fontFamily: '"Poppins", sans-serif',
    color: '#555',
    border: '2px solid #555',
    borderRadius: '10px',
    cursor: 'not-allowed',
    textAlign: 'center',
  },
  progressBarContainer: {
    height: '20px',
    width: '100%',
    backgroundColor: '#ddd',
    borderRadius: '4px',
    overflow: 'hidden',
    marginBottom: '20px',
    position: 'relative',
  },
  progressBar: (percentage) => ({
    height: '100%',
    width: `${percentage}%`,
    backgroundColor: '#3366FF',
  }),
  progressText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#000',
    fontSize: isMobile? '10px' : '14px',
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 'bold',
  },
  message: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: isMobile ? '16px' : '18px',
    color: '#fff',
    textAlign: 'center',
  },
  totalSizeText: {
    fontSize: isMobile ? '18px' : '20px',
    fontFamily: '"Poppins", sans-serif',
    color: '#fff',
    marginTop: '10px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '24px',
    marginTop: '24px',
    flexDirection: 'row',
  },
  inputContainer: {
    alignSelf: 'stretch',
    height: isMobile? '60px' : '122px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '21px',
  },
  inputLabel: {
    color: 'white',
    fontSize: isMobile ? '16px' : '26px',
    fontFamily: 'Poppins',
    fontWeight: '300',
    wordWrap: 'break-word',
  },
  inputField: {
    width: isMobile? '100px': '287px',
    padding: isMobile? '16px' : '20px',
    borderRadius: '4px',
    border: '1px solid #949494',
    color: '#fff',
    backgroundColor: 'transparent',
    fontSize: isMobile? '14px' : '18px',
    fontFamily: 'Poppins',
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const UploadModal = ({ user, onClose, job }) => {
  const [width] = useWindowSize();
  const isMobile = width < 768;
  const styles = modalStyles(isMobile);

  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [modelName, setModelName] = useState('');
  const [jobId, setJobId] = useState(null);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [generationStep, setGenerationStep] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationMessage, setGenerationMessage] = useState('');
  const [disableButtons, setDisableButtons] = useState(false);
  const [inputType, setInputType] = useState('Photos');
  const [selectedVersion, setSelectedVersion] = useState('v1');
  const [featureChange, setFeatureChange] = useState("generate");
  const uploadRefs = useRef([]);
  
  useEffect( () => {
    if (job && job._id) {
      setUploadCompleted(true);
      setJobId(job._id);
      setInputType(job.inputType);
      if (job.modelName) {
        setModelName(job.modelName);
      }
    }
  }, [job]);

  const handleDrop = (acceptedFiles) => {
    setErrorMessage('');

    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/JPEG', 'image/PNG', 'image/JPG'];
    const validVideoTypes = ['video/mp4', 'video/avi', 'video/mov', 'video/wmv', 'video/flv', 'video/3gp', 'video/mkv', 'video/quicktime',
      'video/MP4', 'video/AVI', 'video/MOV', 'video/WMV', 'video/FLV', 'video/3GP', 'video/MKV'
    ];

    // Check if the files contain both images and videos
    const containsImages = acceptedFiles.some(file => validImageTypes.includes(file.type));
    const containsVideos = acceptedFiles.some(file => validVideoTypes.includes(file.type));

    if (containsImages && containsVideos) {
        setErrorMessage('You cannot upload a mix of images and videos. Please upload either images or videos.');
        return;
    }

    const invalidFiles = acceptedFiles.filter(
      (file) => !validImageTypes.includes(file.type) && !validVideoTypes.includes(file.type)
    );

    if (invalidFiles.length > 0) {
      setErrorMessage('Some files are not supported image or video types.');
      return;
    }
    if (acceptedFiles.length > 1000) {
      setErrorMessage('You can only upload up to 1000 files.');
      return;
    }

    const totalSize = acceptedFiles.reduce((acc, file) => acc + file.size, 0);
    const isVideo = containsVideos;
    if (isVideo) {setInputType('video');} else {setInputType('Photos');}
    const maxSize = 1 * 1024 * 1024 * 1024;

    if (totalSize > maxSize) {
      setErrorMessage("The total file size exceeds 1GB");
      return;
    }

    if (isVideo && acceptedFiles.length > 1) {
      setErrorMessage('You can only upload one video at a time.');
      return;
    }

    setFiles(acceptedFiles);
  };


  const handleUpload = async () => {
    if (!user) {
      alert('User not authenticated');
      return;
    }
  
    const newJobId = uuidv4();
    setJobId(newJobId);
  
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });
  
    const totalFiles = files.length;
    let uploadedFiles = 0;
    let totalBytesUploaded = 0;
    let totalSize = files.reduce((acc, file) => acc + file.size, 0);
    setStartTime(Date.now());
  
    for (const file of files) {
      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: `jobs/${user.uid}/${newJobId}/${file.name}`,
        Expires: 3600,
        Body: file,
        ContentType: file.type,
      };
  
      const upload = s3.upload(params);
      uploadRefs.current.push(upload);
  
      upload.on('httpUploadProgress', (evt) => {
        totalBytesUploaded += evt.loaded;
        const progress = ((uploadedFiles + evt.loaded / evt.total) / totalFiles) * 100;
        setUploadProgress(progress);
      });
  
      try {
        await upload.promise();
        uploadedFiles += 1;
      } catch (error) {
        if (error.code === 'RequestAbortedError') {
          console.log('Upload cancelled');
          return;
        } else {
          setUploadProgress(0);
          console.error('Error uploading files:', error);
          setErrorMessage('Error uploading files. Please try again.');
          uploadRefs.current.forEach(upload => upload.abort());
          jobModelInstance.deleteS3Folder(`jobs/${user.uid}/${jobId}`);
          return;
        }
      }
    }
  
    setUploadProgress(100);
  
    const jobData = {
      _id: newJobId,
      userId: user.uid,
      inputType: inputType,
      inputURL: `https://s3.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/jobs/${user.uid}/${newJobId}/`,
      status: 'Pending',
    };
  
    try {
      await JobController.createJob(jobData);
      setUploadCompleted(true);
      setGenerationStep(true);
      setFiles([]);
      setUploadProgress(0);
    } catch (error) {
      setUploadProgress(0);
      console.error('Error creating job:', error);
      alert('Failed to upload.');
    }
  };
  
  const handleSoftClose = () => {
    // Reset the relevant states
    setGenerationStep(false);
    setJobId(null);
    setFiles([]); // Clear any uploaded files
    setUploadCompleted(false);
    setModelName(''); // Clear the model name
    setGenerationMessage(''); // Clear generation message
    setIsGenerating(false); // Reset generating state
    setDisableButtons(false); // Re-enable buttons
    onClose();
  };

  const handleClose = () => {
    uploadRefs.current.forEach(upload => upload.abort());
    if (uploadCompleted && jobId) {
      jobModelInstance.deleteJob(jobId);
    } 
    jobModelInstance.deleteS3Folder(`jobs/${user.uid}/${jobId}`);
    setJobId(null);
    setUploadCompleted(false);
    setGenerationMessage("");
    setGenerationStep(false);
    setIsGenerating(false);
  };

  const handleBack = () => {
    uploadRefs.current.forEach(upload => upload.abort());
    jobModelInstance.deleteS3Folder(`jobs/${user.uid}/${jobId}`);
    setJobId(null);
    setFiles([]);
    setUploadProgress(0);
    setGenerationStep(false);
    setGenerationMessage("");
    setIsGenerating(false);
  };

  const handleGenerate = async () => {
    if (!modelName.trim()) return;

    setIsGenerating(true);
    setDisableButtons(true);

    try {
      const generateConfig = selectedVersion === 'v2' ? { engine: "normal" } : { engine: "v1" };
      await jobModelInstance.updateJob(jobId, { "modelName": modelName });
      await modelController.generateModel(user.uid, jobId, modelName, inputType, generateConfig);
      await jobModelInstance.updateJob(jobId, { status: 'Processing', statusMessage: 'Model is pending preprocess.' });
      setGenerationMessage('Your model is currently generating. Please refresh the dashboard for updates.');
  
    } catch (error) {
      console.error('Error generating model:', error);
      if (error.response && error.response.status === 503) {
        await jobModelInstance.updateJob(jobId, { statusMessage: 'Please wait for a couple of minutes and click on continue to try again.' });
        setGenerationMessage('Service is busy. Please try again.');
      } else {
        await jobModelInstance.updateJob(jobId, { statusMessage: 'Please wait for a couple of minutes and click on continue to try again.' });
        setGenerationMessage('Server is busy. Please try again.');
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const renderUploadContent = () => {
    const showDropzoneMessage = !errorMessage && files.length === 0;

    const calculateTimeRemaining = () => {
      if (uploadProgress === 0) return 'Calculating...';
      const elapsedTime = (Date.now() - startTime) / 1000; // seconds
      const estimatedTotalTime = (elapsedTime / (uploadProgress / 100)); // seconds
      const timeRemaining = estimatedTotalTime - elapsedTime; // seconds
      return `${Math.round(timeRemaining)} seconds left`;
    };

    return (
      <div>
        <Dropzone onDrop={handleDrop} disabled={(!uploadCompleted && uploadProgress > 0) || uploadCompleted}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} style={styles.dropzoneContainer}>
              <input {...getInputProps()} />
              {showDropzoneMessage && (
                <p style={styles.dropzoneText}>{featureChange === 'upload' ? "Choose your model or drag & drop it here" : "Choose a file or drag & drop it here"}</p>
              )}
              {files.length > 0 && (
                <>
                  <p style={styles.dropzoneText}>{files.length} file(s) selected</p>
                  <p style={styles.totalSizeText}>
                    Total size: {(files.reduce((acc, file) => acc + file.size, 0) / 1024 / 1024).toFixed(2)} MB
                  </p>
                </>
              )}
              {errorMessage && <p style={styles.dropzoneText}>{errorMessage}</p>}
              <div style={styles.faqContainer}>
                <HelpOutlineIcon style={styles.faqIcon} onClick={(e) => e.stopPropagation()} />
                <a href="/capture-guide" style={styles.faqText} onClick={(e) => e.stopPropagation()}>Upload Guidance</a>
              </div>
              {files.length === 0 && uploadProgress === 0 && (
                <button style={styles.secondaryButton}>
                  Browse File
                </button>
              )}
              {uploadProgress > 0 && (
                <div style={styles.progressBarContainer}>
                  <div style={styles.progressBar(uploadProgress)}></div>
                  <div style={styles.progressText}>{Math.round(uploadProgress)}% - {calculateTimeRemaining()}</div>
                </div>
              )}
            </div>
          )}
        </Dropzone>
        {files.length > 0 && (
          <div style={styles.buttonContainer}>
            <button onClick={handleBack} style={styles.secondaryButton}>
              Back
            </button>
            {!errorMessage && (uploadCompleted || uploadProgress === 0 ?
              <button onClick={handleUpload} style={styles.primaryButton}>Upload</button> :
              <button style={styles.primaryButtonDisabled} disabled> Uploading... </button>)
            }
          </div>
        )}
      </div>
    );
  };


  const renderGenerateModelContent = () => {
    const handleVersionChange = (event, newVersion) => {
      // Prevent deselecting the current option by ignoring 'null' values
      if (newVersion !== null) {
        setSelectedVersion(newVersion);
      }
    };
    
    return (
      <div>
        {/* Check for generation message or generation state */}
        {isGenerating ? (
          <div style={styles.spinnerContainer}>
            <Spinner />
          </div>
        ) : generationMessage !== '' ? (
          <div style={styles.message}>{generationMessage}</div>
        ) : (
          <div style={{ display: 'flex', marginTop: '20px' }}>
            {/* Left column: Model name input, Delete, and Generate buttons */}
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '20px' }}>
              <div style={styles.inputContainer}>
                <div style={styles.inputLabel}>Enter a Model Name</div>
              </div>
              <input
                type="text"
                value={modelName}
                maxLength={40}
                onChange={(e) => setModelName(e.target.value)}
                style={styles.inputField}
              />
              <div style={{ display: 'flex', alignItems: 'center', gap: '16px', flexDirection: isMobile? 'column' : 'row'}}>
                {!disableButtons && (
                  <button
                    onClick={handleClose}
                    style={!disableButtons ? styles.secondaryButton : styles.secondaryButtonDisabled}
                    disabled={disableButtons}
                  >
                    Delete
                  </button>
                )}
                {!isGenerating && !disableButtons && (
                  <button
                    onClick={handleGenerate}
                    style={
                      modelName.trim() && !isGenerating && !disableButtons
                        ? styles.primaryButton
                        : styles.primaryButtonDisabled
                    }
                    disabled={!modelName.trim() || isGenerating || disableButtons}
                  >
                    Generate
                  </button>
                )}
              </div>
            </div>
  
            {/* Vertical separator */}
            <div style={{ width: '1px', backgroundColor: '#CBD0DC', margin: '0 20px' }}></div>
  
            {/* Right column: Information about generation engine and toggle */}
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '20px' }}>
              <div style={{ fontSize: '16px', fontFamily: 'Poppins', color: '#fff' }}>
                Select Generation Engine
              </div>
              <div style={{ fontSize: '14px', fontFamily: 'Poppins', color: '#CBD0DC', maxWidth: '400px' }}>
                <p>
                  <strong>v2 Beta</strong> is the latest engine. It offers more details and cleaner scenes but may take longer to generate.
                </p>
              </div>
              <ToggleButtonGroup
                value={selectedVersion}
                exclusive
                onChange={handleVersionChange}
                aria-label="version selection"
                style={{ height: '40px' }}
              >
                <ToggleButton
                  value="v1"
                  aria-label="v1"
                  style={{
                    width: '80px',
                    height: '20px',
                    backgroundColor: selectedVersion === 'v1' ? '#3366FF' : 'transparent',
                    color: selectedVersion === 'v1' ? '#fff' : '#CBD0DC',
                    border: '1px solid #CBD0DC',
                    fontSize: '12px',
                    fontFamily: 'Poppins',
                  }}
                >
                  v1
                </ToggleButton>
                <ToggleButton
                  value="v2"
                  aria-label="v2"
                  style={{
                    width: '80px',
                    height: '20px',
                    backgroundColor: selectedVersion === 'v2' ? '#3366FF' : 'transparent',
                    color: selectedVersion === 'v2' ? '#fff' : '#CBD0DC',
                    border: '1px solid #CBD0DC',
                    fontSize: '12px',
                    fontFamily: 'Poppins',
                  }}
                >
                  v2 Beta
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        )}
      </div>
    );
  };
  
  const handleFeatureChange = (event, newFeature) => {
    // Prevent deselecting the current option by ignoring 'null' values
    if (newFeature !== null) {
      setFeatureChange(newFeature);
    }
  };

  return (
    <>
      <div style={styles.overlay}></div>
      <div style={styles.modal}>
        <div style={{ ...styles.header, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h2 style={styles.title}>
            {!generationStep && !job ? 'Upload files' : 'Model Generation'}
          </h2>
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginLeft: 'auto' }}>
            {/* <ToggleButtonGroup
              value={featureChange}
              exclusive
              onChange={handleFeatureChange}
              aria-label="feature selection"
              style={{ height: '30px' }}
            >
              <ToggleButton
                value="generate"
                aria-label="generate"
                style={{
                  width: '120px',
                  height: '30px',
                  backgroundColor: featureChange === 'generate' ? '#fff' : 'transparent',
                  color: featureChange === 'generate' ? 'Black' : '#CBD0DC',
                  border: '1px solid #CBD0DC',
                  fontSize: '10px',
                  fontFamily: 'Poppins',
                }}
              >
                Create Model
              </ToggleButton>
              <ToggleButton
                value="upload"
                aria-label="upload"
                style={{
                  width: '120px',
                  height: '30px',
                  backgroundColor: featureChange === 'upload' ? '#fff' : 'transparent',
                  color: featureChange === 'upload' ? 'Black' : '#CBD0DC',
                  border: '1px solid #CBD0DC',
                  fontSize: '10px',
                  fontFamily: 'Poppins',
                }}
              >
                Upload Model
              </ToggleButton>
            </ToggleButtonGroup> */}
            <button onClick={handleSoftClose} style={styles.closeButton}>
              ×
            </button>
          </div>
        </div>
        {!isGenerating && (
          <p style={styles.subtitle}>
            {featureChange === 'upload'
            ? 'Already have your own model? Upload here and share. (.ply, .splat)'
            : !generationStep && !job
              ? 'Select and upload the files of your choice (jpg, jpeg, png, mp4, avi, mov, mkv), up to 1000 files, 1GB size limit.'
              : 'Please input a name for your model.'}
          </p>
        )}
        <div style={styles.divider}></div>
        {uploadCompleted ? renderGenerateModelContent() : renderUploadContent()}
      </div>
    </>
  );
  
};

export default UploadModal;