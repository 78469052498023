import React, { useState, useEffect } from 'react';
import vrInteractionIcon from 'asset/avp.svg';
import webInteractionIcon from 'asset/window-cursor.svg';
import reconstructionIcon from 'asset/ar-scan-cube-2.svg';
import multisourceIcon from 'asset/inputIcon.svg';
import freeCustomize from 'asset/free-customize.png';
import effortlessShare from 'asset/effortless-share.png';
import embeddedDemo from 'asset/embedded-demo.png';
import videoThumbnail from 'asset/video-thumbnail.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
};

const styles = {
  gradientText: {
    background: 'linear-gradient(90deg, #81B6FB 0%, #A0BFFB 15%, #B9ADE3 30%, #FFFFFF 50%, #CCA3BC 70%, #A0BFFB 85%, #81B6FB 100%)',
    backgroundSize: '200% auto',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: 'gradientAnimation 4s ease infinite',
  },
};

const appStyles = (isMobile) => ({
  container: {
    width: '100%',
    background: '#040507',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  howItWorksSection: {
    width: '100%', // Full width
    maxWidth: '1200px',
    padding: isMobile ? '50px 10px' : '80px 20px', // Add horizontal padding on mobile
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  title: {
    textAlign: 'center',
    fontSize: isMobile ? '24px' : '36px', // Slightly smaller font size on mobile
    fontFamily: '"Poppins", sans-serif',
    fontWeight: '600',
    marginBottom: '40px', // Reduce margin on mobile
    padding: isMobile ? '0 10px' : '0', // Add horizontal padding
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: isMobile ? '1fr' : 'repeat(4, 1fr)', // One column on mobile
    gap: '20px', // Smaller gap on mobile
    width: '100%',
    padding: isMobile ? '0 10px' : '0', // Add horizontal padding on mobile
  },
  card: {
    backgroundColor: '#1A1A1A',
    padding: '20px', // Reduced padding
    borderRadius: '10px',
    textAlign: 'center',
    transition: 'transform 0.3s ease',
    // cursor: 'pointer',
    maxWidth: '300px', // Limit the width of the cards
    margin: '0 auto', // Center the card horizontally
  },
  cardHover: {
    transform: 'translateY(-10px)',
  },
  icon: {
    height: isMobile ? '40px' : '80px', // Smaller icons on mobile
    marginBottom: '20px', // Reduced margin
  },
  cardTitle: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '18px', // Slightly smaller font size
    fontWeight: '600',
    marginBottom: '10px', // Reduced margin
  },
  cardDescription: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: '400',
    fontSize: '14px', // Slightly smaller font size
    color: '#CCCCCC',
  },
  videoSection: {
    width: '100%', // Full width
    maxWidth: '800px',
    margin: '40px 0', // Reduced margin
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  video: {
    width: '80%', // Full width
    boxShadow: '0 10px 30px rgba(0,0,0,0.5)',
  },
  section: {
    width: '100%', // Full width
    maxWidth: '1200px',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: isMobile ? '50px 10px' : '80px 20px', // Add horizontal padding on mobile
    gap: '30px', // Reduced gap
  },
  textBlock: {
    width: '100%', // Full width
    maxWidth: isMobile ? '80%' : '600px', // Add a max width on larger screens
    fontSize: isMobile ? '5vw' : '2.5vw', // Use vw for better responsiveness
    fontFamily: 'Poppins',
    fontWeight: '700',
    lineHeight: isMobile ? '6vw' : '3vw',
    background: 'linear-gradient(180deg, #81B6FB 0%, #B9ADE3 70%, #FFFFFF 85%, #CCA3BC 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    color: 'transparent',
    textAlign: 'left',
  },
  imageContainer: {
    width: '100%',
    maxWidth: isMobile ? '90%' : '800px', // Limit max width
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: isMobile ? '0 10px' : '0', // Add horizontal padding on mobile
  },
  image: {
    width: '90%',
    height: 'auto',
    transition: 'transform 0.3s ease',
  },
  imageHover: {
    transform: 'scale(1.05)',
  },
});


const WorkCard = ({ icon, title, description }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = (hovered) => {
    setIsHovered(hovered);
  };

  return (
    <div
      style={{ ...appStyles().card, ...(isHovered ? appStyles().cardHover : {}) }}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <img src={icon} alt={title} style={appStyles().icon} />
      <div style={appStyles().cardTitle}>{title}</div>
      <div style={appStyles().cardDescription}>{description}</div>
    </div>
  );
};

const HowItWorksTop = ({ isMobile }) => {
  
  useEffect(() => {
    AOS.init({ duration: 1000,  });
  }, []);

  return (
  <div style={appStyles(isMobile).howItWorksSection}>
    <h2 style={{ ...appStyles(isMobile).title, ...styles.gradientText }} data-aos="fade-down">How it works</h2>
    <div style={appStyles(isMobile).cardsContainer} data-aos="fade-down">
      <WorkCard
        icon={multisourceIcon}
        title="Easy Capture"
        description="Capture your space with any cellphones or cameras."
      />
      <WorkCard
        icon={reconstructionIcon}
        title="HQ 3D Reconstruction"
        description="Powered by Kaleida Engine V1 and V2 Beta."
      />
      <WorkCard
        icon={webInteractionIcon}
        title="Model Customization"
        description="Edit and customize your 3D model with Kaleida Editor."
      />
      <WorkCard
        icon={vrInteractionIcon}
        title="Immersive Interaction"
        description="Experience and share virtual tours on all platforms."
      />
    </div>
  </div>
  );
};

const SectionWithImage = ({ isMobile, text, imageSrc, reverse }) => {
  const [isHovered, setIsHovered] = useState(false);
  const appliedStyles = appStyles(isMobile);

  return (
    <div
      style={{
        ...appliedStyles.section,
        flexDirection: isMobile
          ? 'column'
          : reverse
          ? 'row-reverse'
          : 'row',
      }}
      data-aos= { reverse? "fade-left" : "fade-right"}
    >
      <div style={{ ...appliedStyles.textBlock, ...styles.gradientText }}>{text}</div>
      <div
        style={appliedStyles.imageContainer}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          src={imageSrc}
          alt="Illustration"
          style={{
            ...appliedStyles.image,
            ...(isHovered ? appliedStyles.imageHover : {}),
          }}
        />
      </div>
    </div>
  );
};

const HowItWorksSection = () => {
  const [width] = useWindowSize();
  const isMobile = width < 768;

  useEffect(() => {
    AOS.init({ duration: 2000,  });
  }, []);

  return (
    <div style={appStyles(isMobile).container}>
      <style>{`
        @keyframes gradientAnimation {
          0% {
            background-position: 0% center;
          }
          100% {
            background-position: -200% center;
          }
        }
      `}</style>
      <HowItWorksTop isMobile={isMobile} />

      <div style={appStyles(isMobile).videoSection}>
        <video
          style={appStyles(isMobile).video}
          controls
          poster={videoThumbnail}
        >
          <source
            src="https://s3.amazonaws.com/kaleida.space/assets/kaleida-demo-v2.mov"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <SectionWithImage
        isMobile={isMobile}
        text="Freely customize to match your needs."
        imageSrc={freeCustomize}
      />
      <SectionWithImage
        isMobile={isMobile}
        text="Effortlessly share the model with others."
        imageSrc={effortlessShare}
        reverse
      />
      <SectionWithImage
        isMobile={isMobile}
        text="Seamlessly integrate the model into your site."
        imageSrc={embeddedDemo}
      />
    </div>
  );
};

export default HowItWorksSection;
