import React, { useEffect, useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { XR, createXRStore, Controllers, Hands } from '@react-three/xr';
import { isMobile, isIPad13 } from 'react-device-detect';
import { ClipLoader } from 'react-spinners';
import { CameraControls, Controller } from './Movement';
import { Mask, CameraList, Info } from './Mask';
import { SplatRenderer } from './SplatRenderer';
import { Perf } from 'r3f-perf';

import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useNavigate } from 'react-router-dom';

const store = createXRStore();

const FPSCounter = ({ onFPSUpdate }) => {
    const lastFrameTime = useRef(performance.now());
    const frameCount = useRef(0);
    const fpsInterval = 1000; // Update FPS every 1000ms
    const lastFpsUpdate = useRef(performance.now());

    useFrame(() => {
        const now = performance.now();
        frameCount.current += 1;

        if (now - lastFpsUpdate.current >= fpsInterval) {
            const delta = now - lastFpsUpdate.current;
            const currentFps = (frameCount.current / delta) * 1000;
            onFPSUpdate(currentFps);
            frameCount.current = 0;
            lastFpsUpdate.current = now;
        }

        lastFrameTime.current = now;
    });

    return null;
};

const InteractiveModel = ({ config }) => {
    const pointerLockRef = useRef(false);
    const [currentCamera, setCurrentCamera] = useState(0);
    const [loading, setLoading] = useState(true);
    const controllerMove = useRef(false);
    const controllerRotate = useRef(false);

    const [showDescription, setShowDescription] = useState(false);

    const navigate = useNavigate();

    // State to control renderer resolution
    const [pixelRatio, setPixelRatio] = useState(window.devicePixelRatio);

    const handleFPSUpdate = (fps) => {
        // Adjust pixel ratio based on FPS
        if (fps < 30) {
            setPixelRatio(0.5); // Reduce resolution
        } else if (fps > 50) {
            setPixelRatio(window.devicePixelRatio); // Reset to default
        }
    };

    useEffect(() => {
        const handlePointerLockChange = () => {
            if (document.pointerLockElement) {
                pointerLockRef.current = true;
            } else {
                pointerLockRef.current = false;
            }
        };

        const handleExitPointerLock = () => {
            if (document.pointerLockElement) {
                document.exitPointerLock();
                pointerLockRef.current = false;
            }
            document.removeEventListener('pointerlockchange', handlePointerLockChange);
        };

        document.addEventListener('pointerlockchange', handlePointerLockChange);
        return () => handleExitPointerLock();
    }, []);

    // const handleEnterXR = async () => {
    //     try {
    //         await store.enterVR();
    //     } catch (error) {
    //         console.error('Failed to enter XR:', error);
    //     }
    // };

    return (
        <div style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
            margin: '0',
            top: '0',
            left: '0',
            backgroundColor: '#fff',
            color: '#fff'
        }}>
            {loading && (
                <div style={{ position: 'absolute', zIndex: 20 }}>
                    <ClipLoader size={50} color={"#123abc"} loading={loading} />
                </div>
            )}

            <div style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
            }}>
                <Canvas
                    style={{ background: 'white' }}
                    onCreated={({ gl }) => { gl.setAnimationLoop(() => { if (loading) setLoading(false); }); }}
                    pixelRatio={pixelRatio}
                >
                    <XR store={store}>
                        <SplatRenderer config={config} />
                        {/* <SplatRenderer config={config} pixelRatio={pixelRatio} /> */}

                        <CameraControls
                            config={config}
                            currentCamera={currentCamera}
                            pointerLockRef={(!isMobile && !isIPad13) ? pointerLockRef : { current: true }}
                            controllerMove={controllerMove}
                            controllerRotate={controllerRotate}
                        />

                        {/* <FPSCounter onFPSUpdate={handleFPSUpdate} /> */}
                        {/* <Perf position='bottom-left' /> */}
                    </XR>
                </Canvas>
                {/* <button onClick={handleEnterXR} style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
                    Enter VR
                </button> */}
                {(!isMobile && !isIPad13) && <Mask />}
                {(isMobile || isIPad13) && !showDescription && <Controller controllerMove={controllerMove} controllerRotate={controllerRotate} />}
            </div>

            {(!isMobile && !isIPad13) && <IconButton
                color="primary"
                style={{
                    position: 'absolute',
                    top: '20px',
                    left: '20px',
                    width: '48px',
                    height: '48px',
                    color: 'black',
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    zIndex: 1000
                }}
                onClick={() => {
                    navigate(-1);
                }}
            >
                <ArrowBackIcon />
            </IconButton>}

            <CameraList
                config={config}
                currentCamera={currentCamera}
                setCurrentCamera={setCurrentCamera}
            />

            <Info config={config} showDescription={showDescription} setShowDescription={setShowDescription} />
        </div>
    );
};

export default InteractiveModel;
