import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TermsOfService = () => {


    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div style={styles.pageContainer}>
            <div style={styles.policyContainer}>
                <h1 style={styles.heading}>Kaleida Terms of Service</h1>
                <p style={styles.effectiveDate}>Last Updated: August 1, 2024</p>

                <div style={styles.policySection}>
                    <p style={styles.policyText}>
                        These Terms of Service, including our Privacy Policy which is incorporated herein by reference, constitute a legally binding contract between you and Semicolon Technology LLC. ("Kaleida," "we," "us," or "our") regarding your use of our services and website. By accessing or using our website, you agree to be bound by the terms and conditions of this Agreement.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>1. Overview</h2>
                    <p style={styles.policyText}>
                        Kaleida operates this website and provides all information, tools, and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies, and notices stated here. By visiting our site and/or using our services, you engage in our "Service" and agree to be bound by the following terms and conditions.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>2. Acceptance of Terms</h2>
                    <p style={styles.policyText}>
                        By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. Any new features or tools added to the current site shall also be subject to the Terms of Service.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>3. Modifications to Terms of Service</h2>
                    <p style={styles.policyText}>
                        We reserve the right to update, change, or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>4. Access and Use of Services</h2>
                    <p style={styles.policyText}>
                        By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you have given us your consent to allow any of your minor dependents to use this site. You may not use our services for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>5. General Conditions</h2>
                    <p style={styles.policyText}>
                        We reserve the right to refuse service to anyone for any reason at any time. You understand that your content (not including credit card information) may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
                        <br /><br />
                        You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us. The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>6. Accuracy, Completeness, and Timeliness of Information</h2>
                    <p style={styles.policyText}>
                        We are not responsible if information made available on this site is not accurate, complete, or current. The material on this site is provided for general information only and should not be relied upon without consulting primary, more accurate sources of information.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>7. Modifications to the Service</h2>
                    <p style={styles.policyText}>
                        We reserve the right to modify or discontinue the Service (or any part or content thereof) without notice at any time.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>8. Accuracy of Billing and Account Information</h2>
                    <p style={styles.policyText}>
                        We reserve the right to refuse any transaction placed with us. You agree to provide current, complete, and accurate purchase and account information for all purchases made on our site.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>9. Optional Tools</h2>
                    <p style={styles.policyText}>
                        We may provide you with access to third-party tools over which we neither monitor nor have any control. You acknowledge and agree that we provide access to such tools "as is" and "as available" without any warranties, representations, or conditions of any kind.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>10. Third-Party Links</h2>
                    <p style={styles.policyText}>
                        Certain content, products, and services available via our Service may include materials from third parties. We are not responsible for examining or evaluating the content or accuracy and do not warrant and will not have any liability for any third-party materials or websites.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>11. User Comments, Feedback, and Other Submissions</h2>
                    <p style={styles.policyText}>
                        If you send certain specific submissions or without a request from us you send creative ideas, suggestions, or other materials, you agree that we may, at any time, without restriction, edit, copy, publish, distribute, and otherwise use in any medium any comments that you forward to us.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>12. Personal Information</h2>
                    <p style={styles.policyText}>
                        Your submission of personal information through the site is governed by our Privacy Policy.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>13. Errors, Inaccuracies, and Omissions</h2>
                    <p style={styles.policyText}>
                        Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies, or omissions. We reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information if any information in the Service or on any related website is inaccurate at any time without prior notice.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>14. Prohibited Uses</h2>
                    <p style={styles.policyText}>
                        In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content for any unlawful purpose, to solicit others to perform unlawful acts, to violate any international, federal, provincial, or state regulations, rules, laws, or local ordinances, and other prohibitions as listed in the original terms.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>15. Disclaimer of Warranties; Limitation of Liability</h2>
                    <p style={styles.policyText}>
                        We do not guarantee that your use of our service will be uninterrupted, timely, secure, or error-free. We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>16. Indemnification</h2>
                    <p style={styles.policyText}>
                        You agree to indemnify, defend and hold harmless Kaleida and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns, and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>17. Severability</h2>
                    <p style={styles.policyText}>
                        In the event that any provision of these Terms of Service is determined to be unlawful, void, or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>18. Termination</h2>
                    <p style={styles.policyText}>
                        The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes. These Terms of Service are effective unless and until terminated by either you or us.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>19. Entire Agreement</h2>
                    <p style={styles.policyText}>
                        These Terms of Service and any policies or operating rules posted by us on this site or in respect to the Service constitute the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications, and proposals.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>20. Governing Law</h2>
                    <p style={styles.policyText}>
                        These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of [Your Country/State].
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>21. Changes to Terms of Service</h2>
                    <p style={styles.policyText}>
                        You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change, or replace any part of these Terms of Service by posting updates and changes to our website.
                    </p>
                </div>
            </div>
        </div>
    );
};

const styles = {
    pageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
    },
    policyContainer: {
        backgroundColor: '#040507',
        padding: '40px',
        maxWidth: '800px',
        width: '100%',
        borderRadius: '10px',
        boxSizing: 'border-box',
    },
    heading: {
        textAlign: 'center',
        color: '#fff',
    },
    effectiveDate: {
        textAlign: 'center',
        color: '#ccc',
        marginBottom: '40px',
        fontSize: '18px',
    },
    policySection: {
        marginBottom: '30px',
    },
    policySubheading: {
        fontWeight: 'bold',
        fontFamily: '"Poppins", sans-serif',
        color: '#fff',
        fontSize: '22px',
    },
    policyText: {
        marginTop: '10px',
        fontFamily: '"Poppins", sans-serif',
        color: '#fff',
        fontSize: '18px',
        lineHeight: '1.6',
    },
};

export default TermsOfService;
