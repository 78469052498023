import React from 'react';
import vr from 'asset/cross-platform.jpg';
import automate from 'asset/automated-scalability.jpg';
import custom from 'asset/customized-solutions.jpg';
import rendering from 'asset/advanced-3d-rendering.jpg';

const styles = {
  featuresSection: {
    fontFamily: 'sans-serif',
    color: 'black',
    padding: '5%',
    width: '100%',
    boxSizing: 'border-box',
    flexGrow: 1,
  },
  header: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '2em',
    fontWeight: '400',
    textAlign: 'center',
    marginBottom: '5%',
    color: 'black',
  },
  featuresGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    gap: '2%',
    width: '100%',
    boxSizing: 'border-box',
  },
  featureCard: {
    borderRadius: '10px',
    textAlign: 'left',
    backgroundColor: '#fff',
    padding: '2%',
    boxSizing: 'border-box',
  },
  icon: {
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
    marginBottom: '2%',
  },
  cardTitle: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '1.5em',
    fontWeight: '600',
    marginBottom: '1em',
  },
  cardDescription: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: '400',
    fontSize: '1.2em',
    opacity: 0.8,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5%',
    backgroundColor: '#fff',
    color: 'black',
    width: '100%',
    boxSizing: 'border-box',
    minHeight: '100vh',
  },
  mediaStyles: `
    @media (max-width: 768px) {
      .featuresSection {
        padding: 2%;
      }
      .icon {
        max-width: 70%;
        margin-bottom: 5%;
      }
      .header {
        font-size: 1.5em;
        margin-bottom: 3%;
      }
      .cardTitle {
        font-size: 1.2em;
        margin-bottom: 0.5em;
      }
      .cardDescription {
        font-size: 0.5em;
      }
      .featuresGrid {
        grid-template-columns: 1fr;
      }
    }
  `,
};

const FeatureCard = ({ icon, title, description }) => (
  <div style={styles.featureCard}>
    <div>
      <img src={icon} alt={title} style={styles.icon} />
    </div>
    <h3 style={styles.cardTitle}>{title}</h3>
    <p style={styles.cardDescription}>{description}</p>
  </div>
);

const Features = () => (
  <section
    style={styles.section}
  >
    <div style={styles.featuresSection}>
      <h2 style={styles.header}>Features</h2>
      <div style={styles.featuresGrid}>
        <FeatureCard
          icon={vr}
          title="Cross-Platform Immersion"
          description="Experience unparalleled immersion as our revolutionary technology replicates real-world environments with stunning 1:1 accuracy. Seamlessly interact across web, mobile, and various VR headsets."
        />
        <FeatureCard
          icon={automate}
          title="Automated Scalability"
          description="Embrace a seamless journey with our intuitive platform, leveraging automated generation capabilities to effortlessly scale your operations."
        />
        <FeatureCard
          icon={rendering}
          title="Advanced 3D Scene Rendering"
          description="Utilize cutting-edge 3D space reconstruction and real-time scene rendering technology from Kaleida to ensure precise representation and an immersive experience."
        />
        <FeatureCard
          icon={custom}
          title="Customized Solutions"
          description="Unlock tailor-made solutions designed to elevate your unique business needs. We offer end-to-end customization, including seamless website embedding, to provide your customers with an unforgettable experience."
        />
      </div>
    </div>
  </section>
);

export default Features;
