import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import icon from 'asset/kaleida_icon.png';
import ContactUs from './demo/ContactUs';
import LoginPage from './authentication/LoginPage';
import { useNavigate } from 'react-router-dom';
import UploadModal from '../components/dashboard/UploadModal';
import WarningModal from './dashboard/WarningModal';
import HelperModal from './dashboard/HelperModal';
import userControllerInstance from '../controllers/UserController';

const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
};

const styles = (isMobile) => ({
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: isMobile ? '10px 40px' : '20px 40px',
    backgroundColor: '#040507',
    color: '#fff',
    width: '100%',
    boxSizing: 'border-box',
    zIndex: 1000,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  logo: {
    height: isMobile ? '30px' : '50px',
    width: isMobile ? '30px' : '50px',
    marginRight: isMobile ? '0px' : "10px",
    marginLeft: isMobile ? '10px' : "0px",
  },
  title: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: isMobile ? '16px' : '24px',
    fontWeight: 'bold',
    marginRight: isMobile ? '10px' : '0',
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    background: '#3366FF',
    color: '#fff',
    border: 'none',
    marginRight: '10px',
    padding: isMobile? '8px 10px' : '8px 20px',
    borderRadius: '10px',
    cursor: 'pointer',
    fontFamily: '"Poppins", sans-serif',
    fontSize: isMobile ? '10px' : '14px',
  },
  buttonSecondary: {
    background: 'transparent',
    color: '#fff',
    border: '0px solid #fff',
    padding: isMobile ? '0px 10px' : '8px 20px',
    borderRadius: '10px',
    cursor: 'pointer',
    fontFamily: '"Poppins", sans-serif',
    fontSize: isMobile ? '10px' : '14px',
  },
  userInfoContainer: {
    position: 'relative',
    fontFamily: '"Poppins", sans-serif',
    fontSize: isMobile ? '10px' : '14px',
    cursor: 'pointer',
    marginRight: isMobile ? '5px' : '10px',
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  dropdownWrapper: {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: isMobile ? '150px' : '200px',
    borderRadius: '4px',
    backgroundColor: '#040507',
    paddingTop: '10px',
  },
  dropdown: {
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '4px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    flexDirection: 'column',
    width: '100%',
    zIndex: 1100,
  },
  dropdownItem: {
    padding: isMobile ? '8px 15px' : '10px 20px',
    cursor: 'pointer',
    borderBottom: '1px solid #ddd',
    textAlign: 'left',
    transition: 'background-color 0.3s',
  },
  dropdownItemLast: {
    padding: isMobile ? '8px 15px' : '10px 20px',
    cursor: 'pointer',
    textAlign: 'left',
    transition: 'background-color 0.3s',
  },
  dropdownItemHover: {
    backgroundColor: '#3366FF',
    color: '#fff',
  },
});

const Header = ({ user }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupLogin, setShowPopupLogin] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [width] = useWindowSize();
  const isMobile = width < 768;
  const navigate = useNavigate();

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log('User signed out successfully');
        navigate('/');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  const handleLogoClick = () => {
    navigate('/');  // Navigate to the home page
  };

  const handleCreateTourClick = async () => {
    const auth = getAuth();
    if (auth.currentUser) {
      const hasPendingJobs = await userControllerInstance.checkUserJobs(auth.currentUser.uid);
      if (hasPendingJobs) {
        setShowWarningModal(true);
      } else {
        if (window.location.pathname === '/dashboard') {
          setShowUploadModal(true);
        } else {
          navigate('/dashboard', { state: { showModal: true } });
        }
      }
    } else {
      setShowPopupLogin(true);
    }
  };

  const handleDashboardClick = () => {
    navigate('/dashboard');
  };

  const handleHelp = () => {
    setShowHelpModal(true);
  };

  const appliedStyles = styles(isMobile);

  return (
    <>
      {showPopup && <ContactUs onClose={() => setShowPopup(false)} />}
      {showPopupLogin && <LoginPage onClose={() => setShowPopupLogin(false)} />}
      {showUploadModal && <UploadModal user={user} onClose={() => setShowUploadModal(false)} />}
      {showWarningModal && (
        <WarningModal
          onClose={() => setShowWarningModal(false)}
          message="You have ongoing jobs or have reached the limit of 3 completed jobs. Please wait for the current job to finish or remove some completed jobs from the dashboard. We are currently testing features. As we grow, we will increase the quota."
        />
      )}
      {showHelpModal && <HelperModal onClose={() => setShowHelpModal(false)} />}
      <header id="header" className="header" style={appliedStyles.header}>
        <div style={appliedStyles.logoContainer} onClick={handleLogoClick}>
          <img src={icon} alt="Kaleida Logo" className="header-logo" style={appliedStyles.logo} />
          <div className="header-title" style={appliedStyles.title}>Kaleida</div>
        </div>
        <nav className="header-nav" style={appliedStyles.nav}>
          <button className="header-button" onClick={handleHelp} style={appliedStyles.buttonSecondary}>
            Help
          </button>
          <button className="header-button" onClick={handleCreateTourClick} style={appliedStyles.buttonSecondary}>
            Create Virtual Tour
          </button>
          {user ? (
            <div
              className="userInfoContainer"
              style={appliedStyles.userInfoContainer}
              onMouseEnter={() => setDropdownVisible(true)}
              onMouseLeave={() => setDropdownVisible(false)}
            >
              <div className="userInfo" style={appliedStyles.userInfo}>
                Welcome to Kaleida
              </div>
              {dropdownVisible && (
                <div className="dropdownWrapper" style={appliedStyles.dropdownWrapper}>
                  <div className="dropdown" style={appliedStyles.dropdown}>
                    <div className="dropdownItem" style={appliedStyles.dropdownItem} onClick={handleDashboardClick}>
                      Dashboard
                    </div>
                    <div className="dropdownItemLast" style={appliedStyles.dropdownItemLast} onClick={handleLogout}>
                      Log Out
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <button className="header-button" onClick={() => setShowPopupLogin(true)} style={appliedStyles.button}>
              Login
            </button>
          )}
          <button className="header-button" onClick={() => setShowPopup(true)} style={appliedStyles.button}>
            Contact
          </button>
        </nav>
      </header>
    </>
  );
};

export default Header;
