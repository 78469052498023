import api from 'api/axiosConfig';

class JobModel {
    async createJob(data) {
        try {
        const response = await api.post('/job', data);
        return response.data;
        } catch (error) {
        console.error('Failed to create job:', error);
        throw error;
        }
    }
    
    async getJobById(_id) {
        try {
        const response = await api.get(`/job?jobId=${_id}`);
        return response.data;
        } catch (error) {
        console.error('Failed to get job by id:', error);
        throw error;
        }
    }
    
    async updateJob(_id, data) {
        try {
        const response = await api.put(`/job?jobId=${_id}`, data);
        return response.data;
        } catch (error) {
        console.error('Failed to update job:', error);
        throw error;
        }
    }
    
    async deleteJob(_id) {
        try {
        const response = await api.delete(`/job?jobId=${_id}`);
        return response.data;
        } catch (error) {
        console.error('Failed to delete job:', error);
        }
    }

    async deleteS3Folder(url) {
        try {
            await api.delete(`/job/deleteDirectory?directoryUrl=${url}`);

        } catch (error) {
            console.error('Failed to delete directory', error);
        }
    }

    async getAllPublicJobs() {
        try {
            const response = await api.get('/job/public');
            return response.data;
        } catch (error) {
            console.error('Failed to get all public jobs:', error);
            throw error;
        }
    }
}

const jobModelInstance = new JobModel();
export default jobModelInstance;