import React, { useState, useEffect } from 'react';
import UserController from '../../controllers/UserController';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import HelperModal from '../dashboard/HelperModal';

const styles = {
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  container: {
    maxWidth: '456px',
    width: '100%',
    padding: '40px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  section: {
    width: '100%',
    marginBottom: '24px',
  },
  input: {
    fontFamily: '"Poppins", sans-serif',
    width: '100%',
    padding: '10px',
    margin: '5px 0',
    border: '1px solid #ccc',
    borderRadius: '10px',
    boxSizing: 'border-box',
  },
  label: {
    alignSelf: 'flex-start',
    margin: '10px 0 5px',
    fontFamily: '"Poppins", sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    color: '#666666',
  },
  paragraph: {
    marginBottom: '56px',
    fontFamily: '"Poppins", sans-serif',
    color: '#333',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '20px',
  },
  button: {
    width: '48%',
    fontFamily: '"Poppins", sans-serif',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  submitButton: {
    backgroundColor: '#3366FF',
    fontFamily: '"Poppins", sans-serif',
    color: '#fff',
    borderRadius: '10px',  
  },
  submitButtonDisabled: {
    backgroundColor: '#ccc',
    fontFamily: '"Poppins", sans-serif',
    color: '#fff',
    cursor: 'not-allowed',
    borderRadius: '10px',
  },
  skipButton: {
    backgroundColor: '#fff',
    fontFamily: '"Poppins", sans-serif',
    color: '#000',
    borderRadius: '10px',
    border: '1px solid #ccc',
  },
  otherInput: {
    marginTop: '10px',
    fontFamily: '"Poppins", sans-serif',
  },
  heading: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '40px',
    fontWeight: '600',
    marginBottom: '24px',
    color: '#333',
  },
};

const UserProfileModal = () => {
  const [userData, setUserData] = useState({
    name: '',
    userInfo: { work: '', industry: '', accountType: '', otherWork: '' },
  });

  const [userId, setUserId] = useState(null);
  const [showHelperModal, setShowHelperModal] = useState(false);
  const [showUserProfileModal, setShowUserProfileModal] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      setUserId(user.uid);
      setUserData((prevState) => ({
        ...prevState,
        name: user.displayName || '',
      }));
    } else {
      console.error('No user is currently logged in.');
    }
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (['work', 'industry', 'accountType', 'otherWork'].includes(name)) {
      setUserData((prevState) => ({
        ...prevState,
        userInfo: {
          ...prevState.userInfo,
          [name]: value,
        },
      }));
    } else {
      setUserData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const dataToSend = {
        name: userData.name || undefined,
        userInfo: {
          work: userData.userInfo.work !== 'Other' ? userData.userInfo.work : (userData.userInfo.otherWork || 'Other'),
          industry: userData.userInfo.industry || undefined,
          accountType: userData.userInfo.accountType || undefined,
        },
      };
      
      const updatedUser = await UserController.updateUserById(userId, dataToSend);
      console.log('User updated successfully:');
      setShowHelperModal(true);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleSkip = () => {
    setShowHelperModal(true);
  };

  const isFormComplete = () => {
    const { userInfo: { work, industry, accountType } } = userData;
    return work || industry || accountType;
  };

  const closeHelperModal = () => {
    setShowHelperModal(false);
    navigate('/dashboard');
  };

  return (
    <div style={styles.page}>
      {showHelperModal ? (
        <HelperModal onClose={closeHelperModal} />
      ) : (
        <div style={styles.container}>
          <h1 style={styles.heading}>Tell us about yourself</h1>
          <p style={styles.paragraph}>This information will help us provide more personalized and relevant models</p>
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <div style={styles.section}>
              <label style={styles.label}>Full Name</label>
              <input type="text" name="name" value={userData.name} onChange={handleChange} style={styles.input} maxLength={40} />
            </div>

            <div style={styles.section}>
              <label style={styles.label}>What do you do?</label>
              <select name="work" value={userData.userInfo.work} onChange={handleChange} style={styles.input}>
                <option value="">Select your role</option>
                <option value="Hotel Manager">Hotel Manager</option>
                <option value="Travel Agent">Travel Agent</option>
                <option value="Event Planner">Event Planner</option>
                <option value="Real Estate Agent">Real Estate Agent</option>
                <option value="Real Estate Manager">Real Estate Manager</option>
                <option value="Real Estate Analyst">Real Estate Analyst</option>
                <option value="Sales Manager">Sales Manager</option>
                <option value="Store Manager">Store Manager</option>
                <option value="Marketing Specialist">Marketing Specialist</option>
                <option value="Investment Banker">Investment Banker</option>
                <option value="Project Manager">Project Manager</option>
                <option value="Content Creator">Content Creator</option>
                <option value="Journalist">Journalist</option>
                <option value="Architect">Architect</option>
                <option value="Civil Engineer">Civil Engineer</option>
                <option value="Other">Other</option>
              </select>
              {userData.userInfo.work === 'Other' && (
                <input
                  type="text"
                  name="otherWork"
                  placeholder="Please specify"
                  value={userData.userInfo.otherWork}
                  onChange={handleChange}
                  style={{ ...styles.input, ...styles.otherInput }}
                  maxLength={40}
                />
              )}
            </div>

            <div style={styles.section}>
              <label style={styles.label}>What's your industry?</label>
              <select name="industry" value={userData.userInfo.industry} onChange={handleChange} style={styles.input}>
                <option value="">Select your industry</option>
                <option value="Technology">Technology</option>
                <option value="Finance">Finance</option>
                <option value="Gaming">Gaming</option>
                <option value="Entertainment">Entertainment</option>
                <option value="Education">Education</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Real Estate">Real Estate</option>
                <option value="Retail">Retail</option>
                <option value="Marketing and Advertising">Marketing and Advertising</option>
                <option value="Tourism and Hospitality">Tourism and Hospitality</option>
                <option value="Architecture and Construction">Architecture and Construction</option>
                <option value="Media and Publishing">Media and Publishing</option>
                <option value="Automotive">Automotive</option>
                <option value="Sports and Fitness">Sports and Fitness</option>
                <option value="Events and Exhibitions">Events and Exhibitions</option>
                <option value="Construction">Construction</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div style={styles.section}>
              <label style={styles.label}>Account Type</label>
              <select name="accountType" value={userData.userInfo.accountType} onChange={handleChange} style={styles.input}>
                <option value="">Select account type</option>
                <option value="Individual">Individual</option>
                <option value="Business">Business</option>
                <option value="Educational Institution">Educational Institution</option>
                <option value="Non-Profit Organization">Non-Profit Organization</option>
                <option value="Government Agency">Government Agency</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div style={styles.buttonContainer}>
              <button type="button" onClick={handleSkip} style={{ ...styles.button, ...styles.skipButton }}>
                Skip
              </button>
              <button
                type="submit"
                style={isFormComplete() ? { ...styles.button, ...styles.submitButton } : { ...styles.button, ...styles.submitButtonDisabled }}
                disabled={!isFormComplete()}
              >
                Finish
              </button>    
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default UserProfileModal;
