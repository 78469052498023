// NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px', fontFamily: '"Poppins", sans-serif' }}>
      <h1>404: Page Not Found</h1>
      <p style={{ fontFamily: '"Poppins", sans-serif',}}>We're sorry, but the page you are looking for doesn't exist or has been moved.</p>
      <Link to="/" style={{ textDecoration: 'none', color: 'blue', fontFamily: '"Poppins", sans-serif' }}>
        Go back home
      </Link>
    </div>
  );
};

export default NotFound;
