import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

function RequireAuth({ user, isLoading, children, setShowLogin }) {
  const location = useLocation();

  useEffect(() => {
    if (!isLoading && !user) {
      setShowLogin(true);
    }
  }, [isLoading, user, setShowLogin]);

  if (isLoading) {
    return null; // Optionally render a loading component or nothing
  }

  if (!user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;
