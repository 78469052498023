import React, { useState, useEffect, useRef } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const CollisionController = ({
    setLine,
    axis, handleSetAxis,

    walls,
    addWall, toggleAddWall,
    clearWalls, removePreviousWall,

    selectedWall,
    editWall, toggleEditWall,
    removeSelectedWall,
    wallHeight, setWallHeight,
}) => {
    const rate = 0.01;

    const [lastAction, setLastAction] = useState(null);

    const [showDescription, setShowDescription] = useState(true);
    const handleShowDescription = () => {
        setShowDescription(!showDescription);
    };

    // limit height of wall list
    const ulRef = useRef(null);
    useEffect(() => {
        if (ulRef.current) {
            ulRef.current.scrollTop = ulRef.current.scrollHeight;
        }
    }, [walls]);

    // Handle keydown events
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Backspace' && selectedWall) {
                removeSelectedWall();
                setLine({ start: null, end: null });
            }
            if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
                handleUndo();
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedWall, removeSelectedWall]);

    const handleUndo = () => {
        if (lastAction === 'add') {
            removePreviousWall();
            setLine({ start: null, end: null });
        } else if (lastAction === 'delete' && selectedWall) {
            // Re-add the last deleted wall if possible
            // Assuming you have the logic to store the last deleted wall
        }
    };

    const handleAddWall = () => {
        addWall ? handleSetAxis('N') : handleSetAxis('Y');
        setLastAction('add');
        toggleAddWall();
    };

    const handleEditWall = () => {
        editWall ? handleSetAxis('N') : handleSetAxis('Y');
        setLastAction('delete');
        toggleEditWall();
    };

    const clearAllButtonStyle = {
        padding: '10px 20px',
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: '"Poppins", sans-serif',
        cursor: 'pointer',
        textAlign: 'center',
        borderRadius: '8px',
        backgroundColor: 'transparent',
        color: '#fff',
        border: '1px solid #fff',
        width: '100%',
    };

    const textStyle = {
        color: '#CBD0DC',
        fontSize: '16px',
        fontFamily: '"Poppins", sans-serif',
        textAlign: 'left',
        margin: '10px 0',
    };

    const switchButtonStyle = {
        flex: 1,
        width: '100px',
        padding: '10px 20px',
        fontSize: '16px',
        fontWeight: 'bold',
        cursor: 'pointer',
        textAlign: 'center',
        fontFamily: '"Poppins", sans-serif',
        borderRadius: '10px',
        border: '1px solid #1A1A1A'
    };

    const selectedButtonStyle = {
        ...switchButtonStyle,
        backgroundColor: '#3366FF',
        color: '#fff',
        fontFamily: '"Poppins", sans-serif',
    };

    const unselectedButtonStyle = {
        ...switchButtonStyle,
        backgroundColor: '#fff',
        color: '#000',
        fontFamily: '"Poppins", sans-serif',
    };

    const styles = {
        helpIcon: {
            fontSize: '20px',
            cursor: 'pointer',
        },
        description: {
            fontFamily: '"Poppins", sans-serif',
            fontSize: '16px',
            color: '#FFFFFFB2',
        },
        addDeleteButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            width: '100%',
            margin: '20px 0',
        },
    };

    return (
        <div>
            <label
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: '#fff',
                    margin: '15px 0',
                }}
            >
                <h2
                    style={{
                        fontFamily: '"Poppins", sans-serif',
                        userSelect: 'none',
                        transition: 'color 0.3s',
                        margin: 0,
                    }}
                >
                    Set Collision
                </h2>
                <HelpOutlineIcon
                    style={styles.helpIcon}
                    onClick={handleShowDescription}
                />
            </label>

            {showDescription && <p
                style={{
                    ...styles.description,
                    margin: '10px 0',
                }}
            >
                This step is to place collision boxes around spaces or objects that users cannot pass through, like walls, tables, or beds. <br /><br />
            </p>}

            <div style={styles.addDeleteButtonContainer}>
                <button
                    style={addWall ? selectedButtonStyle : unselectedButtonStyle}
                    onClick={handleAddWall}
                >
                    {addWall ? 'Finish' : 'Add'}
                </button>
                <button
                    style={editWall ? selectedButtonStyle : unselectedButtonStyle}
                    onClick={handleEditWall}
                >
                    {editWall ? 'Finish' : 'Delete'}
                </button>
            </div>

            <button
                style={clearAllButtonStyle}
                onClick={clearWalls}
            >
                Clear All
            </button>

            <p style={textStyle}>
                {addWall && '"Cmd/Ctrl + Z" on keyboard to undo, and "Clear All" to delete all boxes.'}
                {editWall && 'Use backspace on keyboard to delete the selected box, and "Clear All" to delete all boxes.'}
                {!(addWall || editWall) && 'Click "Add" to place collision boxes, "Delete" to remove them, and "Clear All" to delete all boxes.'}
            </p>

            <br />

        </div>
    );
};

export default CollisionController;
