import React, { useState, useEffect } from 'react';
import { Grid, OrbitControls } from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import { InsetArrowHelper } from './AxisHelper';
import * as THREE from 'three';
import { Perf } from 'r3f-perf';
import { addPointToLine, selectWall, selectCamera } from 'Utils/Volume';
import { DotRenderer, LineRenderer, WallsRenderer, CamerasRenderer, PointCloudRenderer, SplatRenderer } from './ShapeRenderer';
import MouseHandler from './MouseHandler';

import Spinner from 'components/Spinner';

import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

import { OrthographicCamera, PerspectiveCamera } from 'three';

const CameraUpdater = ({ axis, point }) => {
    const { camera, gl, size, set } = useThree(); // Added size to get canvas dimensions

    useEffect(() => {
        const setCameraFromAxis = (axis, point) => {
            let position;

            const adjustOrthoCamera = (camera, width, height) => {
                const aspect = width / height;
                const viewSize = 10; // This determines the zoom level
                camera.left = -viewSize * aspect;
                camera.right = viewSize * aspect;
                camera.top = viewSize;
                camera.bottom = -viewSize;
                camera.near = 0.1;
                camera.far = 1000;
                camera.updateProjectionMatrix();
            };

            if (axis === 'X') {
                position = [10, 0, 0];
                if (!(camera instanceof PerspectiveCamera)) {
                    set({ camera: new PerspectiveCamera(75, gl.domElement.width / gl.domElement.height, 0.1, 1000) });
                }
            } else if (axis === 'Y') {
                position = [0, 10, 0];
                if (!(camera instanceof OrthographicCamera)) {
                    const orthoCam = new OrthographicCamera(-10, 10, 10, -10, 0.1, 1000);
                    set({ camera: orthoCam });
                }
                adjustOrthoCamera(camera, size.width, size.height); // Adjust aspect ratio for OrthographicCamera
            } else if (axis === 'Z') {
                position = [0, 0, 10];
                if (!(camera instanceof PerspectiveCamera)) {
                    set({ camera: new PerspectiveCamera(75, gl.domElement.width / gl.domElement.height, 0.1, 1000) });
                }
            } else {
                position = [10, 10, 10];
                if (!(camera instanceof PerspectiveCamera)) {
                    set({ camera: new PerspectiveCamera(75, gl.domElement.width / gl.domElement.height, 0.1, 1000) });
                }
            }

            camera.position.set(...position);
        };

        setCameraFromAxis(axis, point);
    }, [axis, point, camera, set, gl, size]);

    return null;
};



const Volume = ({
    axis, range,
    points, calibration,
    splatUrl, showBlob, setShowBlob,
    line, setLine, showScale, startScale,
    walls, showWalls, wallHeight,
    addWall, editWall, selectedWall, setSelectedWall,
    cameras, showCameras, addCamera,
    plot
}) => {

    const [point, setPoint] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (showWalls && editWall) {
            selectWall(point, walls, setSelectedWall);
        } else {
            addPointToLine(point, line, setLine);
        }
    }, [point]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setLine({ start: null, end: null }); // Reset line to null, null
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [setLine]);

    const range3d = { x: [-999, 999], y: [range.min, range.max], z: [-999, 999] };

    const ToggleButton = () => (
        <button
            style={{
                fontFamily: '"Poppins", sans-serif',
                position: 'absolute',
                top: '6vh',
                right: '370px',
                width: '40px',
                height: '40px',
                alignItems: 'center',
                gap: '10px',
                position: 'absolute', // Popadding: '10px 10px',
                backgroundColor: '#FFF',
                color: '#333',
                borderRadius: '5px',
                cursor: 'pointer',
                marginBottom: '10px',
                fontWeight: 'bold',
                fontFamily: '"Poppins", sans-serif',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                zIndex: 10, // Ensure it's on top of the canvas
            }}
            onClick={() => {
                setShowBlob(!showBlob);
            }}
        >
            {showBlob ? <ViewInArIcon /> : <ScatterPlotIcon />} {/* Toggle icons */}
        </button>
    );

    const [showPlot, setShowPlot] = useState(false);

    const processPlot = (plot) => {
        const maxY = Math.max(...plot.map((point) => point.y));
        const result = plot.map((point) => {
            return [point.x / 100 + 10, 0, -(point.y / maxY * 10)];
        });

        // console.log(result);
        return result;
    };

    return (
        <section
            style={{
                position: 'relative',
                height: '100%',
                width: '100%',
                borderRadius: '10px',
            }}
        >
            {loading && !showBlob &&
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 10,
                }}>
                    <Spinner />
                </div>
            }
            <Canvas
                style={{ background: 'white' }}
                gl={{ localClippingEnabled: true }}
                camera={{ fov: 50 }}
            >
                {/* Adding lights to the scene */}
                <ambientLight intensity={1} />
                <directionalLight position={[5, 5, 5]} intensity={1} />

                {points && !showBlob && <PointCloudRenderer points={points} calibration={calibration} range={range3d} setLoading={setLoading} />}
                {points && showBlob && <SplatRenderer url={splatUrl} calibration={calibration} range={range3d} />}

                {(axis === 'Y') && <MouseHandler setPoint={setPoint} />}

                {showScale && startScale && line.start && <LineRenderer line={line} color={0x7b52ab} thickness={0.1} height={wallHeight} />}
                {showWalls && <>
                    <WallsRenderer walls={walls} color={0x357150} opacity={0.5} thickness={0.2} height={wallHeight} />
                    {line.start && addWall && <LineRenderer line={line} color={0x357150} thickness={0.1} height={wallHeight} />}
                </>}
                {(showCameras || showWalls) && <>
                    <CamerasRenderer cameras={cameras} color={0xf44336} thickness={0.1} />
                    {line.start && addCamera && showCameras && <LineRenderer line={line} color={0xf44336} thickness={0.1} height={wallHeight} />}
                </>}

                {showPlot && <>
                    {processPlot(plot).map((point, index) => (
                        <DotRenderer
                            key={index}
                            position={point}
                            color={0x357150} height={0.01}
                        />
                    ))}
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
                        <DotRenderer
                            key={index}
                            position={[ index + 10, 0, 1 ]}
                            color={0xf44336} height={0.01}
                        />
                    ))}
                </>}

                {showWalls && selectedWall && <LineRenderer line={{
                    start: [selectedWall[0][0], 0, selectedWall[0][1]],
                    end: [selectedWall[1][0], 0, selectedWall[1][1]]
                }} color={0x7b52ab} opacity={0.5} thickness={0.2} height={wallHeight} />}

                <CameraUpdater axis={axis} />

                <OrbitControls
                    enableRotate={axis === 'N'}
                    minPolarAngle={0}
                    maxPolarAngle={Math.PI / 2}
                />

                {!loading && <axesHelper args={[20]} />}
                {!loading && <Grid
                    position={(axis === 'X' || axis === 'Z') ? [0, -0.5, 0] : [0, 0, 0]}
                    cellSize={1}
                    sectionSize={4}
                    args={[100, 100]}
                    fadeDistance={100}
                    sectionColor="#000000"
                    cellColor="#000000"
                />}

                {/* <InsetArrowHelper /> */}

            </Canvas>
            <ToggleButton />
        </section>
    );
};

export default Volume;
