// src/controllers/AuthController.js
import UserModel from '../models/UserModel';
import { getAuth, deleteUser } from 'firebase/auth';

class UserController {
  constructor(userModel) {
    this.userModel = userModel;
  }

  async handleAuthentication(result, provider, navigate, closeModal) {
    const user = result.user;
  
    const userDisplayName = user.displayName;
    const userEmail = user.email;
    try {
      const existingUser = await this.userModel.getUserByEmail(user.email);
      if (existingUser) {
        closeModal();
        navigate('/');
        console.log('Welcome back!')
      } else {
        await this.userModel.createUser(user.uid, userEmail, userDisplayName);
        closeModal();
        navigate('/signup-form');
        console.log('User created successfully!')
      }
    } catch (error) {
      console.error('Error creating user:', error);
      const auth = getAuth();
      deleteUser(auth.currentUser)
        .then(() => {
          console.log('User deleted from Firebase due to database creation failure.');
        })
        .catch(deleteError => {
          console.error('Error deleting user from Firebase:', deleteError);
        });
    }
    
  }

  async getUserById(userId) {
    try {
      const user = await this.userModel.getUser(userId);
      return user;
    } catch (error) {
      console.error('Error getting user:', error);
    }
  }

  async getUserByEmail(email) {
    try {
      const user = await this.userModel.getUserByEmail(email);
      return user;
    } catch (error) {
      console.error('Error getting user:', error);
    }
  } 

  async updateUserById(userId, userData) {
    try {
      const updatedUser = await this.userModel.updateUser(userId, userData);
      return updatedUser;
    } catch (error) {
      console.error('Error updating user:', error);
    }
  }

  async addToEmailList(email) {
    try {
      const response = await this.userModel.addToEmailList(email);
      return response;
    } catch (error) {
      return error;
    }
  }

  async checkUserJobs (userId) {
    try {
      const response = await this.userModel.getUser(userId);
      if (response.role === 'admin') {
        return false;
      }
      const jobs = response.jobs;
      const ongoingJobs = jobs.filter(job => job.status !== 'Completed');
      const completedJobs = jobs.filter(job => job.status === 'Completed');

      if (ongoingJobs.length > 0 || completedJobs.length >= 3) {
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error checking user jobs:', error);
      return false;
    }
  };
}

const userControllerInstance = new UserController(UserModel);
export default userControllerInstance;  // Passing the UserModel instance
