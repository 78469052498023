import { useRef } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';

const InsetArrowHelper = () => {
    const { gl, camera, scene } = useThree();
    const insetRef = useRef();

    useEffect(() => {
        // Create an inset renderer for the ArrowHelper
        const insetRenderer = new THREE.WebGLRenderer({ alpha: true });
        insetRenderer.setSize(200, 200);
        insetRenderer.setClearColor(0x000000, 0);
        insetRenderer.domElement.style.position = 'absolute';
        insetRenderer.domElement.style.bottom = '20px';
        insetRenderer.domElement.style.right = '20px';
        insetRenderer.domElement.style.border = '1px solid black';
        document.body.appendChild(insetRenderer.domElement);
        insetRef.current = insetRenderer;

        // Create an inset scene and camera
        const insetScene = new THREE.Scene();
        const insetCamera = new THREE.PerspectiveCamera(50, 1, 1, 1000);
        insetCamera.up = camera.up;

        const arrowPos = new THREE.Vector3(0, 0, 0);
        insetScene.add(new THREE.ArrowHelper(new THREE.Vector3(1, 0, 0), arrowPos, 60, 0x7F2020, 20, 10));
        insetScene.add(new THREE.ArrowHelper(new THREE.Vector3(0, 1, 0), arrowPos, 60, 0x207F20, 20, 10));
        insetScene.add(new THREE.ArrowHelper(new THREE.Vector3(0, 0, 1), arrowPos, 60, 0x20207F, 20, 10));

        const renderInset = () => {
            if (camera && insetCamera) {
                // Ensure the camera matrix is updated
                insetCamera.position.copy(camera.position);
                insetCamera.position.sub(gl.controls?.target || new THREE.Vector3(0, 0, 0));
                insetCamera.position.setLength(300);

                insetCamera.lookAt(insetScene.position);
                insetRenderer.render(insetScene, insetCamera);
            }
        };

        // Animation loop that updates the main scene and inset scene
        const animationLoop = () => {
            if (scene && camera) {
                gl.render(scene, camera);
                renderInset();
            }
        };

        gl.setAnimationLoop(animationLoop);

        return () => {
            insetRenderer.dispose();  // Clean up renderer when component is unmounted
            insetRenderer.domElement.remove();  // Remove the inset renderer from the DOM
            gl.setAnimationLoop(null);  // Stop the animation loop
        };
    }, [gl, camera, scene]);

    return null;
};

export { InsetArrowHelper };