import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UploadModal from '../dashboard/UploadModal'; 
import LoginPage from '../authentication/LoginPage';
import { getAuth } from 'firebase/auth';
import AOS from 'aos';
import 'aos/dist/aos.css';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};


const BuildCTA = ({ user }) => {
  const { width } = useWindowSize();
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const navigate = useNavigate();
  const isMobile = width < 768;

  useEffect(() => {
    AOS.init({ duration: 1000,  });
  }, []);

  const handleCreateTour = () => {
    const auth = getAuth();
    if (auth.currentUser) {
      navigate('/dashboard', { state: { showModal: true } });
    } else {
      setShowPopup(true);
    }
  };

  const styles = {
    buildCTA: {
      minHeight: isMobile? '80vh': '20vh',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '50px',
      height: 'auto',
      backgroundColor: '#040507',
    },
    title: {
      textAlign: 'center',
      width: '100%',
      marginBottom: '40px',
      fontSize: '80px',
      fontFamily: '"Poppins", sans-serif',
      fontWeight: '600',
      color: 'transparent',
      background: 'linear-gradient(180deg, #81B6FB 10%, #B9ADE3 40%, #FFFFFF 50%, #CCA3BC 70%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundClip: 'text',
      backgroundSize: '100% 200%',
      animation: 'gradientMove 6s ease infinite',
    },
    p: {
      fontSize: isMobile ? '16px' : '20px',
      marginBottom: '40px',
      fontFamily: '"Poppins", sans-serif',
      opacity: 0.5,
      color: '#fff',
    },
    button: {
      background: '#3366FF',
      color: '#fff',
      border: 'none',
      padding: '11px 20px',
      borderRadius: '10px',
      cursor: 'pointer',
      fontFamily: '"Poppins", sans-serif',
    },
    mediaStyles: `
      @media (max-width: 768px) {
        .buildCTA {
          padding: 20px;
        }
        .title {
          font-size: 40px;
          margin-bottom: 20px;
        }
        .p {
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
      @media (max-width: 480px) {
        .buildCTA {
          padding: 10px;
        }
        .title {
          font-size: 30px;
          margin-bottom: 15px;
        }
        .p {
          font-size: 14px;
          margin-bottom: 15px;
        }
        .button {
          padding: 8px 20px;
          font-size: 14px;
        }
      }
    `,
  };

  return (
    <>
    <style>
        {`
          @keyframes gradientMove {
            0% {
              background-position: 50% 0%;
            }
            50% {
              background-position: 50% 100%;
            }
            100% {
              background-position: 50% 0%;
            }
          }
        `}
      </style>
      {showPopup && <LoginPage onClose={() => setShowPopup(false)} />}
      {showUploadModal && <UploadModal user={user} onClose={() => setShowUploadModal(false)} />}
      <div className="buildCTA" style={styles.buildCTA}>
        <style>{styles.mediaStyles}</style>
        <h2 className="title" style={styles.title} data-aos="fade-up">Start building today</h2>
        <p className="p" style={styles.p}>Create an account with us to learn more</p>
        <button
          onClick={handleCreateTour}
          style={styles.button}
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Create a Virtual Tour For Free'}
        </button>
      </div>
    </>
  );
};

export default BuildCTA;
