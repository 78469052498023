import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Modal, ToggleButton, ToggleButtonGroup } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import jobModelInstance from 'models/JobModel';

import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const styles = {
    modalStyle: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#222222',
        padding: '40px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        width: '600px', // Adjust the width as needed
        maxWidth: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFFFFF',
        fontFamily: '"Poppins", sans-serif',
    },
    overlayStyle: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
    },
    titleStyle: {
        fontSize: '26px',
        margin: 0,
    },
    copyButtonStyle: {
        width: '300px',
        color: '#FFFFFF',
        marginTop: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        padding: '10px 20px',
        border: '1px solid #fff',
        borderRadius: '5px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            borderColor: '#3366FF',
        },
    },
    copyButtonIcon: {
        marginLeft: '10px',
    },
    toggleButtonGroup: {
        height: '60px',
        marginTop: '40px',
    },
};

const ShareModal = ({ open, onClose, job, url }) => {
    const [selectedVisibility, setSelectedVisibility] = useState('private');

    useEffect(() => {
        if (open && job) {
            setSelectedVisibility(job.visibility);
        }
    }, [open, job]);


    const iframeCode = `
    <div style="position: relative; width: 100%; height: 0; padding-bottom: 56.25%;">
      <iframe src="${url}" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" frameborder="0" allowfullscreen></iframe>
    </div>`;

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(
            () => {
                alert('Copied to clipboard!');
                onClose();
            },
            (err) => {
                console.error('Failed to copy:', err);
            }
        );
    };

    const handleVisibilityChange = async (event, newVisibility) => {
        if (newVisibility !== null) {
            setSelectedVisibility(newVisibility);
            try {
                await jobModelInstance.updateJob(job._id, { visibility: newVisibility });
                if (newVisibility === 'public') {
                    alert('Your model is published in Kaleida library.');
                } else {
                    alert('Your model is now private to you.');
                }
                onClose();
            } catch (error) {
                console.error('Failed to update visibility:', error);
            }
        }
    };

    return (
        <>
            {open && <div style={styles.overlayStyle}></div>}
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="share-modal-title"
                aria-describedby="share-modal-description"
            >
                <Box sx={styles.modalStyle}>
                    <IconButton
                        color="primary"
                        style={{
                            position: 'absolute',
                            top: '15px',
                            left: '15px',
                            width: '37px',
                            height: '37px',
                            color: 'black',
                            backgroundColor: 'rgba(255, 255, 255, 0.85)',
                        }}
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography id="share-modal-title" variant="h6" component="h2" sx={styles.titleStyle}>
                        Share Your Kaleida Virtual Tour
                    </Typography>

                    <ToggleButtonGroup
                        value={selectedVisibility}
                        exclusive
                        onChange={handleVisibilityChange}
                        aria-label="visibility selection"
                        sx={styles.toggleButtonGroup}
                    >
                        <ToggleButton
                            value="private"
                            aria-label="private"
                            style={{
                                width: '100px',
                                height: '30px',
                                backgroundColor: selectedVisibility === 'private' ? '#3366FF' : 'transparent',
                                color: selectedVisibility === 'private' ? '#fff' : '#CBD0DC',
                                border: '1px solid #CBD0DC',
                                fontSize: '14px',
                                fontFamily: 'Poppins',
                            }}
                        >
                            Private
                        </ToggleButton>
                        <ToggleButton
                            value="public"
                            aria-label="public"
                            style={{
                                width: '100px',
                                height: '30px',
                                backgroundColor: selectedVisibility === 'public' ? '#3366FF' : 'transparent',
                                color: selectedVisibility === 'public' ? '#fff' : '#CBD0DC',
                                border: '1px solid #CBD0DC',
                                fontSize: '14px',
                                fontFamily: 'Poppins',
                            }}
                        >
                            Public
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <Button
                        sx={styles.copyButtonStyle}
                        onClick={() => handleCopy(url)}
                    >
                        Copy URL
                        <ContentCopyIcon sx={styles.copyButtonIcon} />
                    </Button>
                    <Button
                        sx={styles.copyButtonStyle}
                        onClick={() => handleCopy(iframeCode)}
                    >
                        Copy Embed Code
                        <ContentCopyIcon sx={styles.copyButtonIcon} />
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default ShareModal;
