import React, { useRef, useState, useEffect } from 'react';
import ScaleForm from './ScaleForm';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import { width } from '@mui/system';

const CalibrateController = ({
    showBlob, toggleShowBlob,
    translation, setTranslation,
    rotation, setRotation,
    scale, setScale,
    axis, handleSetAxis,
    distance, setActualDistance,
    scaleModel, clearLine,
    startScale, toggleStartScale,
    showScale, toggleShowScale,
    cameras, setCameras,
    walls, setWalls,
}) => {
    const styles = {
        item: {
            position: 'relative',
            background: 'rgba(0, 0, 0, 0)',
            padding: '5px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column'
        },
        itemTitle: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        itemHeader: {
            margin: '5px 0',
            cursor: 'pointer',
            fontFamily: '"Poppins", sans-serif'
        },
        itemPart: {
            margin: '0 10px',
        },
        itemRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
            height: '30px',
        },
        rangeInput: {
            flexGrow: 1,
            accentColor: '#3366FF',
        },
        description: {
            fontFamily: '"Poppins", sans-serif',
            fontSize: '16px',
            color: '#FFFFFFB2',
        },
        startScalingButton: {
            background: '#3366FF',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            padding: '5px 20px',
            margin: '10px 30px',
            fontSize: '14px',
            cursor: 'pointer',
            marginBottom: '20px',
            fontFamily: '"Poppins", sans-serif'
        },
        deleteButton: {
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            color: '#FF0000',
            fontSize: '16px',
            fontFamily: '"Poppins", sans-serif'
        },
        hr: {
            width: '100%',
            border: '0',
            borderTop: '1px solid #444',
            margin: '10px 0',
        },
        itemText: {
            color: 'white',
            fontFamily: '"Poppins", sans-serif'
        },
        helpIcon: {
            fontSize: '20px',
            cursor: 'pointer',
        },
        arrowIcon: {
            fontSize: '25px',
            cursor: 'pointer',
        },
    };

    const rate = 0.05;

    const [showDescription, setShowDescription] = useState(true);
    const handleShowDescription = () => {
        setShowDescription(!showDescription);
    };

    const [showPosition, setShowPosition] = useState(true);
    const [showRotation, setShowRotation] = useState(false);
    const [showScalePart, setShowScalePart] = useState(false);

    const [editingField, setEditingField] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const [previousTranslation, setPreviousTranslation] = useState(translation);
    const [previousRotation, setPreviousRotation] = useState(rotation);

    useEffect(() => {
        setPreviousTranslation(translation);
        setPreviousRotation(rotation);
    }, [translation, rotation]);

    const toggleScaleMethod = () => {
        if (!startScale) {
            handleSetAxis('Y')
            toggleStartScale()
            if (!showBlob) toggleShowBlob();
            if (!showScale) toggleShowScale();
        } else {
            handleSetAxis('N')
            toggleStartScale()
            if (showBlob) toggleShowBlob();
            if (showScale) toggleShowScale();
        }
    };

    const handleEditPositionField = (field) => {
        setEditingField(field);
        setInputValue(translation[field].toFixed(2));
    };

    const handleEditRotationField = (field) => {
        setEditingField(field);
        setInputValue(rotation[field].toFixed(2));
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handlePositionInputBlur = () => {
        const value = parseFloat(inputValue);
        if (!isNaN(value)) {
            const newTranslation = { ...translation, [editingField]: value };
            updateTransition(newTranslation.x, newTranslation.y, newTranslation.z);
        }
        setEditingField(null);
    };

    const handlePositionInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            handlePositionInputBlur();
        }
    };

    const handleRotationInputBlur = () => {
        const value = parseFloat(inputValue);
        if (!isNaN(value)) {
            const newRotation = { ...rotation, [editingField]: value };
            updateRotation(newRotation.x, newRotation.y, newRotation.z);
        }
        setEditingField(null);
    };

    const handleRotationInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleRotationInputBlur();
        }
    };

    const updateTransition = (x, y, z) => {
        const deltaX = x - previousTranslation.x;
        const deltaY = y - previousTranslation.y;
        const deltaZ = z - previousTranslation.z;
        setTranslation({ x, y, z });
        if (cameras.length > 0) {
            setCameras(cameras.map(camera => ({
                ...camera,
                position: {
                    x: camera.position.x + deltaX,
                    y: camera.position.y + deltaY,
                    z: camera.position.z + deltaZ,
                },
                lookAt: {
                    x: camera.lookAt.x + deltaX,
                    y: camera.lookAt.y + deltaY,
                    z: camera.lookAt.z + deltaZ,
                },
            })));
        }
        if (walls.length > 0) {
            setWalls(walls.map(wall => wall.map(point => [
                point[0] + deltaX,
                point[1] + deltaZ,
            ])));
        }
        setPreviousTranslation({ x, y, z });
    };

    const rotateY = (angle, point) => {
        const rad = (angle * Math.PI) / 180;
        const cos = Math.cos(rad);
        const sin = Math.sin(rad);
        const { x, z } = point;
        return {
            ...point,
            x: x * cos + z * sin,
            z: -x * sin + z * cos,
        };
    };

    const updateRotation = (x, y, z) => {
        const deltaY = y - previousRotation.y;

        // This function rotates a point around the Y-axis and considers the translation
        const rotateAroundY = (point) => {
            // Move the point to the origin relative to the current translation
            const translatedPoint = {
                x: point.x - translation.x,
                y: point.y - translation.y,
                z: point.z - translation.z,
            };

            // Apply rotation around the Y-axis
            const rotatedPoint = rotateY(deltaY, translatedPoint);

            // Translate the point back to its original position relative to the translation
            return {
                x: rotatedPoint.x + translation.x,
                y: rotatedPoint.y + translation.y,
                z: rotatedPoint.z + translation.z,
            };
        };

        // Update rotation state
        setRotation({ x, y, z });

        if (cameras.length > 0) {
            setCameras(cameras.map(camera => ({
                ...camera,
                position: rotateAroundY(camera.position),
                lookAt: rotateAroundY(camera.lookAt),
            })));
        }

        if (walls.length > 0) {
            setWalls(walls.map(wall => wall.map(point => {
                const rotatedPoint = rotateAroundY({ x: point[0], y: 0, z: point[1] });
                return [rotatedPoint.x, rotatedPoint.z];
            })));
        }

        // Update previousRotation state
        setPreviousRotation({ ...rotation, y });
    };

    return (
        <div>
            <label
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: '#fff',
                    margin: '15px 0',
                }}
            >
                <h2
                    style={{
                        fontFamily: '"Poppins", sans-serif',
                        userSelect: 'none',
                        transition: 'color 0.3s',
                        margin: 0,
                    }}
                >
                    Calibration & Scale
                </h2>
                <HelpOutlineIcon
                    style={styles.helpIcon}
                    onClick={handleShowDescription}
                />
            </label>

            {showDescription && <p
                style={{
                    ...styles.description,
                    margin: '10px 0',
                }}
            >
                This step is to level the model horizontally on all sides and adjust its height for an optimal top-down view. <br /><br />
                For small scale models and accuracy on VR devices, we highly recommend using the scaling tool. <br /><br />
                (Scaling for very large scale outdoor scenes might cause unexpected results) <br />
            </p>}

            <div style={styles.item}>
                <div
                    style={styles.itemTitle}
                    onClick={() => {
                        setShowPosition(!showPosition);
                        setShowRotation(false);
                        setShowScalePart(false);
                        if (startScale) toggleScaleMethod();
                    }}
                >
                    {!showPosition && <ArrowRightSharpIcon style={styles.arrowIcon} />}
                    {showPosition && <ArrowDropDownSharpIcon style={styles.arrowIcon} />}

                    <h3
                        style={styles.itemHeader}
                    >
                        Adjust Model Position
                    </h3>
                </div>

                {showPosition &&
                    <div style={styles.itemPart}>
                        <p
                            style={{
                                ...styles.description,
                                margin: '5px 20px',
                            }}
                        >
                            - X & Z for horizontal <br />
                            - Y for Vertical <br />
                        </p>

                        <br />

                        <div
                            style={styles.itemRow}
                        >
                            <label> X: </label>
                            <label style={{ width: '60px', }} >
                                {editingField === 'x' ? (
                                    <input
                                        type="number"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        onBlur={handlePositionInputBlur}
                                        onKeyDown={handlePositionInputKeyDown}
                                        step="0.05"
                                        autoFocus
                                        style={{ width: '50px' }}
                                    />
                                ) : (
                                    <span style={styles.itemText} onClick={() => handleEditPositionField('x')}>
                                        {translation.x.toFixed(2)}
                                    </span>
                                )}
                            </label>
                            <input
                                type="range"
                                min="-10"
                                max="10"
                                step="0.05"
                                value={translation.x}
                                onChange={(e) => updateTransition(parseFloat(e.target.value), translation.y, translation.z)}
                                style={styles.rangeInput}
                            />
                        </div>

                        <br />

                        <div
                            style={styles.itemRow}
                        >
                            <label> Y: </label>
                            <label style={{ width: '60px', }} >
                                {editingField === 'y' ? (
                                    <input
                                        type="number"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        onBlur={handlePositionInputBlur}
                                        onKeyDown={handlePositionInputKeyDown}
                                        step="0.05"
                                        autoFocus
                                        style={{ width: '50px', marginLeft: '10px' }}
                                    />
                                ) : (
                                    <span style={styles.itemText} onClick={() => handleEditPositionField('y')}>
                                        {translation.y.toFixed(2)}
                                    </span>
                                )}
                            </label>
                            <input
                                type="range"
                                min="-10"
                                max="10"
                                step="0.05"
                                value={translation.y}
                                onChange={(e) => updateTransition(translation.x, parseFloat(e.target.value), translation.z)}
                                style={styles.rangeInput}
                            />
                        </div>

                        <br />

                        <div
                            style={styles.itemRow}
                        >
                            <label> Z: </label>
                            <label style={{ width: '60px', }} >
                                {editingField === 'z' ? (
                                    <input
                                        type="number"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        onBlur={handlePositionInputBlur}
                                        onKeyDown={handlePositionInputKeyDown}
                                        step="0.05"
                                        autoFocus
                                        style={{ width: '50px', marginLeft: '10px' }}
                                    />
                                ) : (
                                    <span style={styles.itemText} onClick={() => handleEditPositionField('z')}>
                                        {translation.z.toFixed(2)}
                                    </span>
                                )}
                            </label>
                            <input
                                type="range"
                                min="-10"
                                max="10"
                                step="0.05"
                                value={translation.z}
                                onChange={(e) => updateTransition(translation.x, translation.y, parseFloat(e.target.value))}
                                style={styles.rangeInput}
                            />
                        </div>
                    </div>
                }
            </div>

            <hr style={styles.hr} />

            <div style={styles.item}>
                <div
                    style={styles.itemTitle}
                    onClick={() => {
                        setShowRotation(!showRotation);
                        setShowPosition(false);
                        setShowScalePart(false);
                        if (startScale) toggleScaleMethod();
                    }}
                >
                    {!showRotation && <ArrowRightSharpIcon style={styles.arrowIcon} />}
                    {showRotation && <ArrowDropDownSharpIcon style={styles.arrowIcon} />}

                    <h3
                        style={styles.itemHeader}
                    >
                        Rotate Model
                    </h3>
                </div>

                {showRotation &&
                    <div style={styles.itemPart}>
                        <p
                            style={{
                                ...styles.description,
                                margin: '5px 20px',
                            }}
                        >
                            Rotate model around corresponding axis <br />
                        </p>

                        <br />

                        <div
                            style={styles.itemRow}
                        >
                            <label> X: </label>
                            <label style={{ width: '60px', }} >
                                {editingField === 'x' ? (
                                    <input
                                        type="number"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        onBlur={handleRotationInputBlur}
                                        onKeyDown={handleRotationInputKeyDown}
                                        step="0.05"
                                        autoFocus
                                        style={{ width: '50px', marginLeft: '10px' }}
                                    />
                                ) : (
                                    <span style={styles.itemText} onClick={() => handleEditRotationField('x')}>
                                        {rotation.x.toFixed(2)}
                                    </span>
                                )}
                            </label>
                            <input
                                type="range"
                                min="-180"
                                max="180"
                                step="0.05"
                                value={rotation.x}
                                onChange={(e) => updateRotation(parseFloat(e.target.value), rotation.y, rotation.z)}
                                style={styles.rangeInput}
                            />
                        </div>

                        <br />

                        <div
                            style={styles.itemRow}
                        >
                            <label> Y: </label>
                            <label style={{ width: '60px', }} >
                                {editingField === 'y' ? (
                                    <input
                                        type="number"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        onBlur={handleRotationInputBlur}
                                        onKeyDown={handleRotationInputKeyDown}
                                        step="0.05"
                                        autoFocus
                                        style={{ width: '50px', marginLeft: '10px' }}
                                    />
                                ) : (
                                    <span style={styles.itemText} onClick={() => handleEditRotationField('y')}>
                                        {rotation.y.toFixed(2)}
                                    </span>
                                )}
                            </label>
                            <input
                                type="range"
                                min="-180"
                                max="180"
                                step="0.05"
                                value={rotation.y}
                                onChange={(e) => updateRotation(rotation.x, parseFloat(e.target.value), rotation.z)}
                                style={styles.rangeInput}
                            />
                        </div>

                        <br />

                        <div
                            style={styles.itemRow}
                        >
                            <label> Z: </label>
                            <label style={{ width: '60px', }} >
                                {editingField === 'z' ? (
                                    <input
                                        type="number"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        onBlur={handleRotationInputBlur}
                                        onKeyDown={handleRotationInputKeyDown}
                                        step="0.05"
                                        autoFocus
                                        style={{ width: '50px', marginLeft: '10px' }}
                                    />
                                ) : (
                                    <span style={styles.itemText} onClick={() => handleEditRotationField('z')}>
                                        {rotation.z.toFixed(2)}
                                    </span>
                                )}
                            </label>
                            <input
                                type="range"
                                min="-180"
                                max="180"
                                step="0.05"
                                value={rotation.z}
                                onChange={(e) => updateRotation(rotation.x, rotation.y, parseFloat(e.target.value))}
                                style={styles.rangeInput}
                            />
                        </div>
                    </div>
                }
            </div>

            <hr style={styles.hr} />

            <div style={styles.item}>
                <div
                    style={styles.itemTitle}
                    onClick={() => {
                        setShowScalePart(!showScalePart);
                        setShowPosition(false);
                        setShowRotation(false);
                        if (startScale) toggleScaleMethod();
                    }}
                >
                    {!showScalePart && <ArrowRightSharpIcon style={styles.arrowIcon} />}
                    {showScalePart && <ArrowDropDownSharpIcon style={styles.arrowIcon} />}

                    <h3
                        style={styles.itemHeader}
                    >
                        Scale
                    </h3>
                </div>

                {showScalePart &&
                    <div style={styles.itemPart}>
                        <p
                            style={{
                                ...styles.description,
                                margin: '5px 20px',
                            }}
                        >
                            Start Scaling, click twice on the model view to draw a line on an object and input the actual length to scale the model. <br />
                        </p>

                        <button className="startScalingButton" style={styles.startScalingButton} onClick={toggleScaleMethod}>
                            {startScale ? 'Stop Scaling' : 'Start Scaling'}
                        </button>

                        {startScale && <ScaleForm distance={distance} setActualDistance={setActualDistance} scaleModel={scaleModel} />}

                        <br />
                        <div style={{
                            ...styles.description,
                            color: '#CBD0DC',
                            margin: '0 20px',
                        }}>
                            Current Scale Factor : {scale.toFixed(2)}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default CalibrateController;
