import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';
import { OrbitControls } from '@react-three/drei';
import { PointCloudRenderer, CamerasRenderer, SplatRenderer } from 'components/calibration/ShapeRenderer';
import { readFileToBuffer, splatToPoints, loadCameraFromJson } from 'Utils/DataLoader';
import { calculateCameraPosition } from 'Utils/Math';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
    });
  
    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
        });
      };
  
      window.addEventListener("resize", handleResize);
      handleResize();
  
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return windowSize;
  };


const FAQ = (user) => {
    const { width } = useWindowSize();
    const [pointCloudData, setPointCloudData] = useState(null);
    const [showPointCloud, setShowPointCloud] = useState(false);
    const [splatData, setSplatData] = useState(null);
    const [cameraData, setCameraData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const isMobile = width < 768;

    const styles = {
        faqContainer: {
            backgroundColor: '#f9f9f9', // Light background color
            padding: isMobile ? '30px' : '60px',
            maxWidth: '100%',
            margin: '0 auto',
            boxSizing: 'border-box', // Ensure padding is included in width
        },
        heading: {
            textAlign: 'center',
            color: '#333', // Dark text for better contrast on light background
            paddingTop: '50px', // Adjusted to account for header height
            fontSize: isMobile ? '1.5em' : '2em',
            marginBottom: '40px',
            fontFamily: '"Poppins", sans-serif',
        },
        faqItem: {
            marginBottom: '50px',
        },
        faqQuestion: {
            fontWeight: 'bold',
            fontFamily: '"Poppins", sans-serif',
            color: '#333', // Dark text color
            fontSize: isMobile ? '1em' : '1.2em',
        },
        faqAnswer: {
            marginTop: '10px',
            fontFamily: '"Poppins", sans-serif',
            color: '#555', // Slightly lighter text for answers
            fontSize: isMobile ? '0.9em' : '1em',
            lineHeight: '1.6',
        },
        image: {
            width: isMobile ? '100%' : '40%',
            height: 'auto', // Maintain aspect ratio
            marginTop: '20px',
            padding: '10px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        videoTitle: {
            color: '#333', // Dark text for the title
            textAlign: 'left',
            fontSize: isMobile ? '1.2em' : '24px',
            marginBottom: '20px',
            fontFamily: '"Poppins", sans-serif',
        },
        downloadButton: {
            display: 'inline-block',
            padding: isMobile ? '8px 16px' : '10px 20px',
            backgroundColor: '#FFF',
            color: '#333',
            textDecoration: 'none',
            borderRadius: '5px',
            border: '1px solid #333',
            fontFamily: '"Poppins", sans-serif',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '20px',
            cursor: 'pointer',
            fontSize: isMobile ? '0.9em' : '1em',
        },
        canvasContainer: {
            position: 'relative',
            height: isMobile ? '300px' : '500px',
            width: '100%', // Full width on mobile
            marginBottom: '50px',
            boxSizing: 'border-box',
        },
        toggleButton: {
            padding: isMobile ? '8px 8px' : '10px 10px',
            backgroundColor: '#FFF',
            color: '#333',
            borderRadius: '5px',
            cursor: 'pointer',
            marginBottom: '10px',
            fontWeight: 'bold',
            fontFamily: '"Poppins", sans-serif',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            position: 'absolute', // Positioning the button absolutely
            top: isMobile ? '5px' : '10px', // Adjust for smaller screens
            right: isMobile ? '5px' : '10px', // Adjust for smaller screens
            zIndex: 10, // Ensure it's on top of the canvas
            fontSize: isMobile ? '0.8em' : '1em',
        },
        iframe: {
            width: '100%',
            height: 'auto',
            marginBottom: '20px',
        },
    };

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch point cloud data
                setSplatData("https://s3.amazonaws.com/kaleida.space/models/splat/ad04619b-52fc-4c5d-8114-4d19eec75167.splat")
                const buffer = await readFileToBuffer("https://s3.amazonaws.com/kaleida.space/models/splat/ad04619b-52fc-4c5d-8114-4d19eec75167.splat");
                const originPoints = splatToPoints(buffer);
                setPointCloudData(originPoints);

                // Fetch camera data
                const camerasJson = await loadCameraFromJson('https://s3.amazonaws.com/kaleida.space/models/camera/ad04619b-52fc-4c5d-8114-4d19eec75167.json')
                const cameras = camerasJson.frames.map(frame => {
                    let camera = calculateCameraPosition(frame.transformMatrix);
                    return {
                        name: frame.filePath,
                        position: camera.cameraPosition,
                        lookAt: camera.lookAtPoint,
                        up: camera.upDirection
                    };
                });
                setCameraData(cameras);
            } catch (err) {
                setError('Failed to load point cloud or camera data');
                console.error(err);
            }
        };
        fetchData();
    }, []);

    const RotatingOrbitControls = () => {
        const controlsRef = useRef();
    
        useEffect(() => {
            const controls = controlsRef.current;
    
            // Disable auto-rotation when user interacts
            const handleStart = () => {
                if (controls) {
                    controls.autoRotate = false; // Disable auto-rotation on interaction
                }
            };
    
            controls?.addEventListener('start', handleStart);
    
            return () => {
                controls?.removeEventListener('start', handleStart);
            };
        }, []);
    
        return (
            <OrbitControls
                ref={controlsRef}
                autoRotate={true}              // Enable auto-rotation
                autoRotateSpeed={1.0}          // Adjust rotation speed
                enableDamping={true}           // Smooth the movement
                minPolarAngle={0}
                maxPolarAngle={Math.PI / 2}    // Limit polar angle for rotation
            />
        );
    };

    const ToggleButton = () => (
        <button 
            style={styles.toggleButton} 
            onClick={() => setShowPointCloud(prev => !prev)}
        >
            {showPointCloud ? <ViewInArIcon /> : <ScatterPlotIcon />} {/* Toggle icons */}
        </button>
    );

    

    return (
        <div>
            <Header user={user} />
            <div style={styles.faqContainer}>
                <h1 style={styles.heading}>Data Upload and Capture Guide</h1>

                <div style={styles.faqItem}>
                    <div style={styles.faqQuestion}>1. What types of files can I upload?</div>
                    <div style={styles.faqAnswer}>
                        Currently, we support popular image and video formats including .jpg, .jpeg, .png, .mp4, .avi, .mov, .mkv.
                        <br /><br />
                        You can upload up to 1000 images or one video for a maximum size of 1GB.
                    </div>
                    <a href="https://s3.amazonaws.com/kaleida.space/assets/airbnb-livingroom.zip" style={styles.downloadButton} download>Download Sample Dataset</a>
                </div>

                <div style={styles.faqItem}>
                    <div style={styles.faqQuestion}>2. How can I take the best photos for upload?</div>
                    <div style={styles.faqAnswer}>
                        <strong>Slow and Steady:</strong> Move your camera or phone slowly or use a tripod for photos to avoid motion blur, which can affect the quality of your upload. Ensure your photos or video are clear and focused.
                        <br /><br />
                        <strong>Consider lighting:</strong> Capture images in consistent lighting where the textures are visible. Avoid extreme brightness or darkness. Sudden changes in lighting can affect the quality of the upload.
                        <br /><br />
                        <strong>Avoid Moving Objects:</strong> Avoid movement in your scene. Moving objects like tree leaves or people can degrade the quality of your final upload. Make sure your hands and other parts of your body do not appear in the photos.
                    </div>
                </div>

                <div style={styles.faqItem}>
                    <div style={styles.faqQuestion}>3. Camera settings that I use</div>
                    <div style={styles.faqAnswer}>
                        <strong>Aperture:</strong> f/8 (for a wide depth of field to keep everything in focus)
                        <br /><br />
                        <strong>Shutter Speed:</strong> 1/60 sec (faster for handheld shots, slower with a tripod)
                        <br /><br />
                        <strong>ISO:</strong> 800 (adjust higher if the scene is dark)
                        <br /><br />
                        <strong>Focal Length:</strong> 12mm ultra-wide (captures more objects in the scene)
                        <br /><br />
                        <strong>White Balance:</strong> Fixed (can be adjusted later in post-processing)
                        <br /><br />
                        <strong>Photo Format:</strong> RAW (provides more detail; if using JPG, ensure manual mode and disable built-in features like HDR)
                        <br /><br />
                        <strong>Video Format:</strong> 4K 30/60FPS with manual settings (for consistency and quality)
                    </div>
                </div>
                <br /><br />
                <h3 style={styles.videoTitle}>A Good Demonstration on how to 3D scan interior with camera</h3>
                <iframe style={styles.iframe} src="https://www.youtube.com/embed/2ZX_5bOdKjo?si=oV9LsuOGhG_14Ba5" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <div style={styles.faqItem}>
                    <br /><br />
                    <div style={styles.faqQuestion}>4. What’s the best strategy for taking photos?</div>
                    <div style={styles.faqAnswer}>
                        <strong>Capture from Multiple Angles:</strong> Capture your subject from various angles and positions to gather as much detail as possible. DON'T just do panoramic shots.
                        <br /><br />
                        <strong>Keep Images Overlapped:</strong> Ensure 70% overlap between consecutive images to help with detail capture.
                        <br /><br />
                        <strong>Consider Depth Variations:</strong> Capture images from different distances and heights to add depth and detail to your uploads.
                        <br /><br />
                        <strong>Use a Grid:</strong> Use a grid overlay on your camera to help align your shots and maintain consistency. Try to keep the horizon level in your photos, and avoid tilting the camera.
                        <br /><br />
                        <strong>Sample capture guide:</strong> Below is an example of how you can capture images or videos from different angles and positions to gather as much detail of the space as possible.
                        <br /><br />
                        <h3 style={styles.videoTitle}>Sample capture trajectory for a complex scene</h3>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <div style={styles.canvasContainer}>
                            <Canvas camera={{ fov: 50, position: [0, 5, 5] }} style={{ backgroundColor: 'white' }} gl={{ localClippingEnabled: true }}>
                                {showPointCloud && pointCloudData && (
                                    <PointCloudRenderer points={pointCloudData} calibration={{ scale: 1, rotation: [0, 0, 0], translation: [0, 0, 0] }} range={{ x: [-20, 20], y: [-20, 20], z: [-20, 20] }} setLoading={setLoading}/>
                                )}
                                {!showPointCloud && splatData && (
                                    <SplatRenderer url={splatData} calibration={{ scale: 1, rotation: [0, 0, 0], translation: [0, 0, 0] }} range={{ x: [-20, 20], y: [-20, 20], z: [-20, 20] }} />
                                )}
                                {cameraData && (
                                    <CamerasRenderer cameras={cameraData} opacity={0.4} color={0xf44336} thickness={0.1} size={new THREE.Vector3(0.1, 0.1, 0.1)} />
                                )}
                                <axesHelper args={[5]} />
                                <RotatingOrbitControls />
                            </Canvas>
                            <ToggleButton />
                        </div>
                        <br /><br />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
