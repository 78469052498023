import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PrivacyPolicy = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    
    return (
        <div style={styles.pageContainer}>
            <div style={styles.policyContainer}>
                <h1 style={styles.heading}>Privacy Policy for Kaleida</h1>
                <p style={styles.effectiveDate}>Effective Date: Aug 1, 2024</p>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>1. Information We Collect</h2>
                    <p style={styles.policyText}>
                        <strong>Personal Information:</strong> When you create an account, we collect personal information such as your name, email address, and payment information.
                        <br /><br />
                        <strong>Uploaded Content:</strong> We collect images, videos, and other content you upload to generate 3D models.
                        <br /><br />
                        <strong>Usage Data:</strong> We collect information about your interactions with our services, such as your IP address, browser type, access times, and pages viewed.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>2. How We Use Your Information</h2>
                    <p style={styles.policyText}>
                        <strong>To Provide and Improve Our Services:</strong> We use your information to generate 3D models, allow customization, and share them as requested.
                        <br /><br />
                        <strong>Communication:</strong> We may use your email address to send you service-related updates, promotional offers, and customer support communications.
                        <br /><br />
                        <strong>Analytics:</strong> We use analytics tools to understand how our services are used and to improve user experience.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>3. Sharing Your Information</h2>
                    <p style={styles.policyText}>
                        <strong>Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing and data analysis.
                        <br /><br />
                        <strong>Legal Requirements:</strong> We may disclose your information if required by law or in response to valid requests by public authorities.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>4. Security of Your Information</h2>
                    <p style={styles.policyText}>
                        We use various security measures to protect your information. However, no data transmission over the internet or storage system can be guaranteed to be 100% secure.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>5. Your Rights</h2>
                    <p style={styles.policyText}>
                        You have the right to access, update, or delete your personal information. You can do this by contacting us at info@kaleida.space.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>6. Changes to This Privacy Policy</h2>
                    <p style={styles.policyText}>
                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.
                    </p>
                </div>

                <div style={styles.policySection}>
                    <h2 style={styles.policySubheading}>7. Contact Us</h2>
                    <p style={styles.policyText}>
                        If you have any questions about this Privacy Policy, please contact us at: info@kaleida.space
                    </p>
                </div>
            </div>
        </div>
    );
};

const styles = {
    pageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
    },
    policyContainer: {
        backgroundColor: '#040507',
        padding: '40px',
        maxWidth: '800px',
        width: '100%',
        borderRadius: '10px',
        boxSizing: 'border-box',
    },
    heading: {
        textAlign: 'center',
        color: '#fff',
    },
    effectiveDate: {
        textAlign: 'center',
        color: '#ccc',
        marginBottom: '40px',
        fontSize: '18px',
    },
    policySection: {
        marginBottom: '30px',
    },
    policySubheading: {
        fontWeight: 'bold',
        fontFamily: '"Poppins", sans-serif',
        color: '#fff',
        fontSize: '22px',
    },
    policyText: {
        marginTop: '10px',
        fontFamily: '"Poppins", sans-serif',
        color: '#fff',
        fontSize: '18px',
        lineHeight: '1.6',
    },
};

export default PrivacyPolicy;
