import { isPointTouchingLines } from "./Math";

const addPointToLine = (point, line, setLine) => {
    // console.log(line);
    if (point) {
        if ((!line.start && !line.end) || (line.start && line.end)) {
            setLine({ start: point, end: null });
        } else if (line.start && !line.end) {
            setLine({ start: line.start, end: point });
        } else {
            throw new Error('Invalid line state');
        }
    }
};

const selectWall = (point, walls, setSelectedWall) => {
    if (point && walls.length > 0) {
        const selectedWall = isPointTouchingLines([point[0], point[2]], walls);
        setSelectedWall(selectedWall);
    }
};


export {
    addPointToLine,
    selectWall,
};