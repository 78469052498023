import { React, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import cactus from 'asset/cactus.JPG';
import x6 from 'asset/x6.jpg';
import antler from 'asset/antler-logo-red.svg';
import aws from 'asset/aws_startup.png';
import ms from 'asset/MS_Startups.png';
import beta from 'asset/beta.png';

const styles = {
  trustedBySection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '100px',
    backgroundColor: '#EFEFEF',
    color: 'Black',
  },
  title: {
    textAlign: 'center',
    width: '100%',
    marginBottom: '84px',
    fontFamily: '"Poppins", sans-serif',
    fontSize: '28px',
    fontWeight: '400',
  },
  cardsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    flexWrap: 'wrap', // Allows wrapping of cards on smaller screens
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: 'calc(100% / 5)', // Divide the space evenly among logos
    minWidth: '120px', // Minimum width for icons
    flexGrow: 1, // Allow cards to grow
  },
  icon: {
    width: '100%', // Set width to 100% of the card
    maxWidth: '120px', // Ensure a max width
    height: 'auto', // Maintain aspect ratio
    marginBottom: '10px',
  },
  cardTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  cardDescription: {
    fontSize: '14px',
    color: '#555',
  },
  mediaStyles: `
    @media (max-width: 768px) {
      .cardsContainer {
        flex-direction: row;
        flex-wrap: wrap;
      }
      .card {
        width: 50%; // Each card takes up half of the available space on mobile
        max-width: 150px; // Set max width for cards
        margin-bottom: 20px;
      }
      .icon {
        width: 100%; // Make sure the icons resize consistently on mobile
        max-width: 100px; // Set a max width for consistency
      }
    }
  `,
};


const WorkCard = ({ icon, title, description, aosEffect }) => (
  <div className="card" style={styles.card} data-aos={aosEffect}>
    <img src={icon} alt={title} style={styles.icon} />
  </div>
);

const TrustedBySection = () => {
  useEffect(() => {
    AOS.init({ duration: 1500, once: false,  });
    
  }, []);

  return (
    <div style={styles.trustedBySection}>
      <style>{styles.mediaStyles}</style>
      <h2 style={styles.title} data-aos="fade-down">Trusted By</h2>
      <div className="cardsContainer" style={styles.cardsContainer}>
        <WorkCard icon={antler} aosEffect="fade-left"/>
        <WorkCard icon={beta} aosEffect="fade-left"/>
        <WorkCard icon={aws} aosEffect="fade-left"/>
        <WorkCard icon={ms} aosEffect="fade-left"/>
      </div>
    </div>
  );
};

export default TrustedBySection;
