import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

const ContactUsModal = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    userType: '',
    message: '',
  });
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    overlay: {
      position: 'fixed',
      top: 40,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    modal: {
      position: 'relative',
      padding: isMobile ? '20px' : '40px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: isMobile ? '90%' : '600px',
      minHeight: isMobile ? '400px' : '500px',
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      color: '#aaa',
      fontSize: '24px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    inputGroup: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row', // Stack inputs vertically on mobile
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    input: {
      flex: '1 1 auto',
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '10px',
      margin: isMobile ? '5px 0' : '5px',
      fontFamily: '"Poppins", sans-serif',
    },
    inputError: {
      flex: '1 1 auto',
      padding: '10px',
      border: '3px solid red',
      borderRadius: '10px',
      margin: isMobile ? '5px 0' : '5px',
    },
    textarea: {
      width: 'calc(100% - 10px)',
      height: '80px', // Reduced height for the textarea
      padding: '8px', // Reduced padding
      marginBottom: '20px',
      border: '1px solid #ccc',
      borderRadius: '10px',
      resize: 'none',
      fontFamily: '"Poppins", sans-serif',
    },
    title: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: isMobile ? '28px' : '48px', // Smaller font size on mobile
      color: '#111111',
      textAlign: 'center',
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: isMobile ? 'center' : 'flex-end', // Center buttons on mobile
      flexDirection: isMobile ? 'column' : 'row',
    },
    cancelButton: {
      marginRight: isMobile ? '0' : '10px', // Remove margin on mobile
      marginBottom: isMobile ? '10px' : '0', // Add margin on mobile
      padding: '10px 20px',
      border: 'none',
      borderRadius: '10px',
      backgroundColor: '#f0f0f0',
      cursor: 'pointer',
      fontFamily: '"Poppins", sans-serif',
    },
    submitButton: {
      padding: '10px 20px',
      border: 'none',
      borderRadius: '10px',
      backgroundColor: '#007bff',
      color: '#fff',
      cursor: 'pointer',
      fontFamily: '"Poppins", sans-serif',
    },
    submitButtonDisabled: {
      padding: '10px 20px',
      border: 'none',
      borderRadius: '10px',
      backgroundColor: '#ccc',
      color: '#fff',
      cursor: 'not-allowed',
      fontFamily: '"Poppins", sans-serif',
    },
    messageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      height: '100%',
    },
    message: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: '18px',
      color: '#666',
      textAlign: 'center',
    },
  };

  const isFormValid = formData.name && formData.email && formData.userType && formData.message;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'message' && value.length > 1000) return;
    if (name === 'email') {
      setIsEmailValid(validateEmail(value));
    }
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid && isEmailValid) {
      emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, 
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
        formData,
        process.env.REACT_APP_EMAILJS_USER_ID
      ).then((response) => {
        setIsFormSubmitted(true);
      }).catch((error) => {
        console.error('Failed to send message:', error);
      });
    }
  };

  return (
    <div style={styles.overlay} onClick={onClose}>
      <div style={styles.modal} onClick={e => e.stopPropagation()}>
        <button onClick={onClose} style={styles.closeButton}>×</button>
        {!isFormSubmitted ? ( 
          <>
            <h1 style={styles.title}>Contact Us</h1>
            <p style={{ marginBottom: '30px', color: '#666', fontSize: '18px' }}>Please let us know how we can help</p>
            <form style={styles.form} onSubmit={handleSubmit}>
              <div style={styles.inputGroup}>
                <input 
                  type="text" 
                  name="name"
                  placeholder="Name" 
                  style={styles.input} 
                  value={formData.name}
                  onChange={handleChange}
                  maxLength={40}
                />
                <input 
                  type="email" 
                  name="email"
                  placeholder="Email" 
                  style={isEmailValid ? styles.input : styles.inputError}
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div style={styles.inputGroup}>
                <input 
                  type="text" 
                  name="phoneNumber"
                  placeholder="Phone Number (optional)" 
                  style={styles.input} 
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  maxLength={20}
                />
                <select 
                  name="userType" 
                  style={styles.input} 
                  value={formData.userType}
                  onChange={handleChange}
                >
                  <option value="">What's your industry?</option>
                  <option value="Technology">Technology</option>
                  <option value="Finance">Finance</option>
                  <option value="Gaming">Gaming</option>
                  <option value="Entertainment">Entertainment</option>
                  <option value="Education">Education</option>
                  <option value="Healthcare">Healthcare</option>
                  <option value="Real Estate">Real Estate</option>
                  <option value="Retail">Retail</option>
                  <option value="Marketing and Advertising">Marketing and Advertising</option>
                  <option value="Tourism and Hospitality">Tourism and Hospitality</option>
                  <option value="Architecture and Construction">Architecture and Construction</option>
                  <option value="Media and Publishing">Media and Publishing</option>
                  <option value="Automotive">Automotive</option>
                  <option value="Sports and Fitness">Sports and Fitness</option>
                  <option value="Events and Exhibitions">Events and Exhibitions</option>
                  <option value="Construction">Construction</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <textarea 
                name="message"
                placeholder="Message" 
                style={styles.textarea}
                value={formData.message}
                onChange={handleChange}
              />
              <div style={styles.buttonGroup}>
                <button type="button" onClick={onClose} style={styles.cancelButton}>Cancel</button>
                <button 
                  type="submit" 
                  disabled={!isFormValid || !isEmailValid}
                  style={isFormValid && isEmailValid ? styles.submitButton : styles.submitButtonDisabled}
                >
                  Submit
                </button>
              </div>
            </form>
          </>
        ) : (
          <div style={styles.messageContainer}>
            <div style={styles.message}>
              <p>Thank you for contacting us! We will get back to you shortly.</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUsModal;
