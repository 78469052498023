import React, { useEffect, useRef, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { isMobile, isIPad13 } from 'react-device-detect';
import { ClipLoader } from 'react-spinners';
import { CameraControls, Controller } from './Movement';
import { Mask, CameraList, Info } from './Mask';
import { SplatRenderer } from './SplatRenderer';
import { createXRStore, XR } from '@react-three/xr';
import { Perf } from 'r3f-perf';
import { useDeviceInfo } from './UseDeviceInfo';

import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useNavigate } from 'react-router-dom';

const store = createXRStore();

const InteractiveModel = ({ config }) => {
    const [currentCamera, setCurrentCamera] = useState(0);
    const [loading, setLoading] = useState(true);
    const controllerMove = useRef(false);
    const controllerRotate = useRef(false);
    const navigate = useNavigate();

    // Description
    const showDescriptionRef = useRef(false);

    const handleShowDescription = () => {
        console.log('showDescriptionRef.current', showDescriptionRef.current);
        showDescriptionRef.current = !showDescriptionRef.current;
    };

    // dynamic pixel ratio

    const glRef = useRef(null);
    const pixelRatioRef = useRef(1.0);

    const isIframe = window.self !== window.top;

    // console.log(pixelRatioRef.current, advicePixelRatio);
    // console.log(deviceInfo, gpuInfo, advicePixelRatio);

    const AutoAdjustPixelRatio = () => {
        const { gl } = useThree(); // Access the WebGL renderer
        const pixelRatioRef = useRef(1.0); // Start with default pixel ratio of 1.0
        const smoothedFPS = useRef(30); // Start with an assumption of 60 FPS
        const lastTimeRef = useRef(performance.now());
        const targetFPS = 30;
        const adjustmentFactor = 0.001; // Controls the sensitivity of adjustments
        const pixelRatioRange = { min: 0.5, max: 2.0 };
        const smoothingFactor = 0.05; // For smoothing pixel ratio changes
      
        // Remove the use of advicePixelRatio
        // const { advicePixelRatio } = useDeviceInfo();
      
        useEffect(() => {
          // Set initial pixel ratio to default value of 1.0
          pixelRatioRef.current = 1.0;
          gl.setPixelRatio(pixelRatioRef.current);
        }, [gl]);
      
        useFrame(() => {
          const currentTime = performance.now();
          const deltaTime = (currentTime - lastTimeRef.current) / 1000; // Convert to seconds
          lastTimeRef.current = currentTime;
      
          const currentFPS = 1 / deltaTime;
      
          // Update the smoothed FPS using exponential moving average
          smoothedFPS.current += (currentFPS - smoothedFPS.current) * 0.1; // 0.1 is the smoothing factor for FPS
      
          // Calculate the FPS error
          const fpsError = smoothedFPS.current - targetFPS;
      
          // Adjust the pixel ratio proportionally to the FPS error
          let newPixelRatio = pixelRatioRef.current + adjustmentFactor * fpsError;
      
          // Apply smoothing to pixel ratio adjustments
          newPixelRatio = pixelRatioRef.current + (newPixelRatio - pixelRatioRef.current) * smoothingFactor;
      
          // Clamp the new pixel ratio within the specified range
          newPixelRatio = Math.max(pixelRatioRange.min, Math.min(pixelRatioRange.max, newPixelRatio));
      
          // Update the pixel ratio if it has changed significantly
          if (Math.abs(newPixelRatio - pixelRatioRef.current) > 0.01) {
            pixelRatioRef.current = newPixelRatio;
            gl.setPixelRatio(pixelRatioRef.current);
            console.log(
              `Adjusted pixel ratio to: ${pixelRatioRef.current.toFixed(2)}, Smoothed FPS: ${smoothedFPS.current.toFixed(2)}`
            );
          }
        });
      
        return null;
      };

    // Pointer lock
    const pointerLockRef = useRef(false);

    useEffect(() => {
        const handlePointerLockChange = () => {
            if (document.pointerLockElement) {
                pointerLockRef.current = true;
            } else {
                pointerLockRef.current = false;
            }
        };

        const handleExitPointerLock = () => {
            if (document.pointerLockElement) {
                document.exitPointerLock();
                pointerLockRef.current = false;
            }
            document.removeEventListener('pointerlockchange', handlePointerLockChange);
        };

        document.addEventListener('pointerlockchange', handlePointerLockChange);
        return () => handleExitPointerLock();
    }, []);

    const handleEnterXR = async () => {
        try {
            await store.enterVR();
        } catch (error) {
            console.error('Failed to enter XR:', error);
        }
    };

    return (
        <div
            style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100dvh',
                width: '100vw',
                margin: '0',
                top: '0',
                left: '0',
                backgroundColor: '#fff',
                color: '#fff',
            }}
        >
            {loading && (
                <div style={{ position: 'absolute', zIndex: 20 }}>
                    <ClipLoader size={50} color={"#123abc"} loading={loading} />
                </div>
            )}

            <div style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
            }}>
                {/* <VRButton style={{ position: 'absolute', bottom: '20px', right: '20px' }} /> */}
                <Canvas
                    style={{ background: 'white' }}
                    // style={{ background: 'white' }}
                    onCreated={({ gl }) => {
                        glRef.current = gl;
                        gl.setPixelRatio(pixelRatioRef.current);
                        gl.setAnimationLoop(() => { if (loading) setLoading(false); });
                    }}
                >
                    <XR store={store} >
                    <SplatRenderer config={config} pixelRatio={pixelRatioRef.current} />

                    <CameraControls
                        config={config}
                        currentCamera={currentCamera}
                        pointerLockRef={(!isMobile && !isIPad13) ? pointerLockRef : { current: true }}
                        controllerMove={controllerMove}
                        controllerRotate={controllerRotate}
                    />

                    {/* <AutoAdjustPixelRatio /> */}
                    {/* <Perf position='bottom-left' /> */}
                    {/* <XRButton /> */}
                    </XR>
                </Canvas>
                {/* <button onClick={() => store.enterVR()} style={{ position: 'absolute', bottom: '60px', left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
                    Enter VR
                </button> */}
            </div>

            {(isMobile || isIPad13) && <Controller controllerMove={controllerMove} controllerRotate={controllerRotate} showDescriptionRef={showDescriptionRef} />}

            {(!isMobile && !isIPad13 && !isIframe) && <IconButton
                color="primary"
                style={{
                    position: 'absolute',
                    top: '20px',
                    left: '20px',
                    width: '48px',
                    height: '48px',
                    color: 'black',
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    zIndex: 1000
                }}
                onClick={() => {
                    navigate(-1);
                }}
            >
                <ArrowBackIcon />
            </IconButton>}

            {(!isMobile && !isIPad13) && <Mask />}

            <CameraList
                config={config}
                currentCamera={currentCamera}
                setCurrentCamera={setCurrentCamera}
            />

            <Info config={config} showDescriptionRef={showDescriptionRef} handleShowDescription={handleShowDescription} />
        </div>
    );
};

export default InteractiveModel;