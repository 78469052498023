import zIndex from '@mui/material/styles/zIndex';
import React from 'react';

const Spinner = () => (
  <div>
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
    <div style={spinnerStyles.spinner}></div>
  </div>
);

const spinnerStyles = {
  spinner: {
    border: "16px solid #f3f3f3", // Light grey
    borderTop: "16px solid #3498db", // Blue
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    animation: "spin 2s linear infinite",
  }
};

export default Spinner;
