import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import JobControllerInstance from 'controllers/JobController';
import Card from '../components/library/Card';
import Footer from 'components/demo/Footer';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
    },
    scrollContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        bottom: 0,
        height: 'calc(100vh - 90px)', // Adjust height for header height
        width: '100%',
    },
    scrollContainerMobile: {
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        bottom: 0,
        height: 'calc(100vh - 65px)', // Adjust height for header height
        width: '100%',
    },
    cardContainer: {
        display: 'grid',
        boxSizing: 'border-box',
        gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 320px))',  // Use auto-fill here
        gap: '30px',  // Keep the gap as you want between items
        padding: '30px 5%',
        justifyContent: 'start',  // Align items to the left
        alignContent: 'start',  // Ensure grid starts at the top
    },
    cardContainerMobile: {
        display: 'grid',
        boxSizing: 'border-box',
        gridTemplateColumns: '320px',
        gap: '10px',  // Keep the gap as you want between items
        padding: '10px 5%',
        justifyContent: 'center',  // Align items to the left
        alignContent: 'start',  // Ensure grid starts at the top
    },
    buttonPrimary: {
        background: '#3366FF',
        color: '#fff',
        border: 'none',
        marginRight: '10px',
        padding: '8px 20px',
        borderRadius: '10px',
        cursor: 'pointer',
        fontFamily: '"Poppins", sans-serif',
        fontSize: '14px',
    },
    refreshIcon: {
        cursor: 'pointer',
        color: '#fff',
        fontSize: '30px',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    loadMoreButton: {
        padding: '10px 20px',
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: '"Poppins", sans-serif',
        cursor: 'pointer',
        textAlign: 'center',
        borderRadius: '8px',
        backgroundColor: 'transparent',
        color: '#fff',
        border: '1px solid #fff',
        width: '300px',
        margin: '20px 0',
    },
};

const ModelLibrary = ({ user }) => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true); // Track if there are more jobs to load
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const scrollContainerRef = useRef(null);

    useEffect(() => {
        const scrollPosition = sessionStorage.getItem('libraryContainerScroll') || 0;
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollPosition;
        }

        const handleScroll = () => {
            if (scrollContainerRef.current && !loading) {
                sessionStorage.setItem(
                    'libraryContainerScroll',
                    scrollContainerRef.current.scrollTop
                );
            }
        };

        const cardContainerNode = scrollContainerRef.current;
        if (cardContainerNode) {
            cardContainerNode.addEventListener('scroll', handleScroll);
        }

        // Cleanup on unmount
        return () => {
            if (cardContainerNode) {
                cardContainerNode.removeEventListener('scroll', handleScroll);
            }
        };
    }, [loading]);

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const fetchJobs = async (skip = 0, limit = 10) => {
        try {
            const jobsDB = await JobControllerInstance.getAllPublicJobs(skip, limit);
            return shuffleArray(jobsDB.data);;
        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const jobsData = await fetchJobs(0, 20);
            setJobs(jobsData);
            setLoading(false);
            if (jobsData.length < 20) setHasMore(false); // Check if there's more data
        };

        fetchData();
    }, []);

    const handleLoadMore = async () => {
        const skip = jobs.length;
        const limit = 10;

        // First API call to fetch the current batch of jobs
        const newJobsData = await fetchJobs(skip, limit);
        setJobs([...jobs, ...newJobsData]);

        // If the number of jobs fetched equals the limit, check for more data
        if (newJobsData.length === limit) {
            // Second API call to check if more data exists beyond the current batch
            const moreJobsData = await fetchJobs(skip + limit, 1); // Just fetch 1 job to check
            if (moreJobsData.length === 0) {
                // If no more jobs, hide the Load More button
                setHasMore(false);
            }
        } else {
            // If fewer than limit jobs were fetched, there are no more jobs
            setHasMore(false);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div style={styles.container}>
            <Header user={user} />
            <div
                style={isMobile ? styles.scrollContainerMobile : styles.scrollContainer}
                ref={scrollContainerRef}
            >
                <div
                    style={isMobile ? styles.cardContainerMobile : styles.cardContainer}
                >
                    {loading ? (
                        <p style={{ fontFamily: '"Poppins", sans-serif', }}>Loading...</p>
                    ) : (
                        jobs.map((job) => (
                            <Card key={job._id} job={job} />
                        ))
                    )}
                </div>

                <div style={styles.buttonContainer}>
                    {hasMore && (
                        <button
                            style={styles.loadMoreButton}
                            onClick={handleLoadMore}
                        >
                            Load More
                        </button>
                    )}
                </div>

                {/* <Footer /> */}
            </div>
        </div>
    );
};

export default ModelLibrary;
