import React from 'react';
import { isMobile } from 'react-device-detect';

const styles = {
  helperModal: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#222222',
    padding: isMobile ? '20px' : '60px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    width: isMobile ? '90%' : '50%', // Adjust the width for mobile
    maxWidth: '500px', // Set a max width for larger screens
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center the modal contents
    justifyContent: 'flex-start', // Align items to the top
    borderRadius: '10px',
  },
  helperOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
  helperTitleContainer: {
    width: '100%',
    textAlign: 'left',
  },
  helperTitle: {
    fontSize: '26px',
    fontFamily: '"Poppins", sans-serif',
    color: '#fff',
    marginTop: '20px',
  },
  helperCloseButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#fff',
    fontSize: '24px',
    fontFamily: '"Poppins", sans-serif',
  },
  helperMessage: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '18px',
    color: '#fff',
    textAlign: 'left',
    marginTop: '20px',
    wordWrap: 'break-word',
  },
  helperNote: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '18px',
    color: '#fff',
    textAlign: 'left',
    marginTop: '20px',
    wordWrap: 'break-word',
  },
  link: {
    color: '#3366FF',
    textDecoration: 'none',
    marginTop: '10px',
    fontSize: '16px',
  },
};

const HelperModal = ({ onClose }) => {
  return (
    <>
      <div style={styles.helperOverlay}></div>
      <div style={styles.helperModal}>
        <button style={styles.helperCloseButton} onClick={onClose}>
          &times;
        </button>
        <div style={styles.helperTitleContainer}>
          <h2 style={styles.helperTitle}>Need Help?</h2>
          <p style={styles.helperMessage}>
            If you need assistance on data capture and upload, please refer to the following guide:
          </p>
          <a href="/capture-guide" style={styles.link}>
            Data Capture Guide
          </a>

          {!isMobile && <div>
            <p style={styles.helperMessage}>
              If you want to try out Kaleida model editor, please refer to the following link:
            </p>
            <a href="/calibration/21f93a66-5a2d-433e-9084-5b2e7fd7a6ff" style={styles.link}>
              Editor Play Ground
            </a>
          </div>}

          <p style={styles.helperMessage}>
            If you need some quick dataset to get started, please download the following file and unzip before uploading:
          </p>
          <a href="https://s3.amazonaws.com/kaleida.space/assets/airbnb-livingroom.zip" style={styles.link}>
            Download
          </a>
        </div>
      </div>
    </>
  );
};

export default HelperModal;
