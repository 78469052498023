import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ModelControllerInstance from '../controllers/ModelController';
import JobControllerInstance from 'controllers/JobController';
import InteractiveModel from '../components/immersive/InteractiveModel';

const ModelViewer = () => {
    const { modelId } = useParams();
    const [modelConfig, setModelConfig] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchModelConfig = async () => {
            try {
                const config = await ModelControllerInstance.getModelById(modelId);
                const job = await JobControllerInstance.getJobById(config.jobId);
                config.username = job.userName;

                setModelConfig(config);
            } catch (error) {
                setError('Failed to fetch model configuration');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchModelConfig();
    }, [modelId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!modelConfig) {
        return <div>Model not found</div>;
    }
    return <InteractiveModel config={modelConfig} />;
};

export default ModelViewer;
